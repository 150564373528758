import React, { useEffect, useState } from 'react';
import clsx from 'classnames';

type Props = {
  value: string;
  className?: string;
};

export const PasswordValidator: React.FC<Props> = (props) => {
  const [passwordTip, setPasswordTip] = useState('');
  const [passwordOk, setPasswordOk] = useState(false);

  useEffect(() => {
    const passRes = getPasswordValidity();
    setPasswordTip(passRes.passwordTip);
    setPasswordOk(passRes.passwordOk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const getPasswordValidity = () => {
    let tip = '';

    if (!props.value.match(/[a-z]/) && tip === '') {
      tip = 'Heslo musí obsahovat malé písmeno';
    }

    if (!props.value.match(/[A-Z]/) && tip === '') {
      tip = 'Heslo musí obsahovat velké písmeno';
    }

    if (!props.value.match(/\d+/) && tip === '') {
      tip = 'Heslo musí obsahovat číslo';
    }

    if (!props.value.match(/[^a-zA-Z0-9]/) && tip === '') {
      tip = 'Heslo musí obsahovat jeden speciální znak';
    }

    if (props.value.length < 13 && tip === '') {
      tip = 'Heslo musí mít minimálně 12 znaků';
    }

    return {
      passwordTip: tip,
      passwordOk: tip === '',
    };
  };

  if (!props.value) {
    return null;
  }

  return (
    <div className={clsx(['component-password-validator', props.className])}>
      <div className="component-items">
        <div
          className={clsx([
            'component-item',
            {
              'item-warning': !passwordOk,
            },
          ])}
        >
          {passwordOk ? (
            <span>Silné heslo</span>
          ) : (
            <span>{passwordTip}</span>
          )}
        </div>
      </div>
    </div>
  );
};
