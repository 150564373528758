import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import { usePageStore } from '@stores';
import * as routes from '@routes';
import { BasePreloader, FormGroup, Button } from '@components';
import { useTextBlockApi } from '@api/textBlock';

interface Props {
  id?: number;
}

const TextBlockForm: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const pageState = usePageStore();
  const textBlockApi = useTextBlockApi();
  const [loaded, setLoaded] = React.useState(true);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      machineName: '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    if (props.id) {
      loadTextBlock(props.id);
    }
    return () => {
      textBlockApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const loadTextBlock = (id: number) => {
    setLoaded(false);
    textBlockApi
      .detailTextBlock(id)
      .then((res) => {
        const data = res.data.data;
        formik.setValues({ name: data.name, machineName: data.machineName });
        setLoaded(true);
      })
      .catch((err) => {
        if (textBlockApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleSubmit = (values: FormikValues) => {
    (!!props.id
      ? textBlockApi.updateTextBlock(props.id as number, formik.values)
      : textBlockApi.createTextBlock(formik.values)
    )
      .then(() => {
        formik.setSubmitting(false);
        navigate(pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK));
      })
      .catch((err) => {
        if (textBlockApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  return (
    <div>
      {loaded ? (
        <>
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-2 mt-md-3">
            <div className="responsive-table-content">
              <div>
                <FormGroup
                  type="text"
                  name="name"
                  label="Název textového bloku"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.errors.name}
                  required
                />
                <FormGroup
                  type="text"
                  name="machineName"
                  label="Strojový název"
                  value={formik.values.machineName}
                  onChange={formik.handleChange}
                  error={formik.errors.machineName}
                  required
                />
              </div>
            </div>
            <Row>
              <Col xs={12} className="mt-4 text-right">
                {!formik.isSubmitting ? (
                  <Button variant="btn-outline-primary" type="submit" disabled={formik.isSubmitting}>
                    Uložit
                  </Button>
                ) : (
                  <BasePreloader size={29} className="d-inline-block" />
                )}
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};

export default TextBlockForm;
