import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useBuyerApi } from '@api/buyer';
import { AuctionBuyerListResponse } from '@types';
import { getDateTimeFormat } from '@helpers/datetime';
import DocumentsFiltersForm from './DocumentsFiltersForm';
import { AdminBoxContent, BasePreloader, Pagination } from '@components';
import TableColumn, { TableColumnSortProps } from '@components/Table/TableColumn';
import { Panels as UserDocumentsPanel } from '../../../UserDocumentsPage/UserDocumentsPage';

import colorIcoConf from '@assets/images/color-ico-conf.svg';

export const SectionDocuments: React.FC = () => {
  const buyersApi = useBuyerApi();
  const location = useLocation();
  const pageState = usePageStore();
  const [dataLen, setDataLen] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState<AuctionBuyerListResponse[]>([]);
  const defaultQuery = React.useMemo(
    () => ({
      s: withDefault(NumberParam, 1),
      sort: withDefault(ObjectParam, {
        property: 'userAuction.joinedTime',
        direction: 'DESC',
      }),
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 15),
      startDtFrom: withDefault(StringParam, undefined),
      startDtTo: withDefault(StringParam, undefined),
      endDtFrom: withDefault(StringParam, undefined),
      endDtTo: withDefault(StringParam, undefined),
      status: withDefault(ArrayParam, undefined),
      name: withDefault(StringParam, undefined),
      number: withDefault(StringParam, undefined),
      buyerType: withDefault(StringParam, undefined),
      mainCategory: withDefault(StringParam, undefined),
      subCategory: withDefault(StringParam, undefined),
      variableSymbol: withDefault(StringParam, undefined),
      specificSymbol: withDefault(StringParam, undefined),
      auctionSecurity: withDefault(StringParam, undefined),
      authorizationState: withDefault(StringParam, undefined),
      cautionState: withDefault(StringParam, undefined),
      auctioneer: withDefault(StringParam, undefined),
      admitted: withDefault(BooleanParam, undefined),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  useEffect(() => {
    if (!location.search || location.search === '?s=1') {
      setQuery({ status: ['prepared'], admitted: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoaded(false);
    loadItems().then();
    return () => buyersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const loadItems = async () => {
    try {
      const res = await buyersApi.auctionsBuyers({ ...query });
      setItems(res.data.data);
      setDataLen(res.data.total as number);
      setIsLoaded(true);
    } catch (err) {
      if (buyersApi.isCancel(err)) {
        return;
      }
    }
  };

  const sortChange = (val: any) => {
    setQuery({ ...query, sort: val }, 'push');
  };

  const getAuctionSecurity = (item: AuctionBuyerListResponse) => {
    if (parseFloat(item.auction.cautionDeposit || '0') <= 0) {
      return 'Není vyžadována';
    }
    return item.auctionSecurity === 'none' || item.auctionSecurity === 'rejected' ? 'Ne' : 'Ano';
  };

  const renderContent = () => {
    if (!isLoaded) {
      return (
        <AdminBoxContent>
          <div className="d-flex justify-content-center py-5">
            <BasePreloader />
          </div>
        </AdminBoxContent>
      );
    }

    return (
      <AdminBoxContent>
        <div className="component-admin-auctions-table">
          <Table striped>
            <thead>
              <tr>
                <TableColumn className="text-nowrap" title="Název dražby" />
                <TableColumn
                  sort={query.sort as TableColumnSortProps}
                  className="text-nowrap"
                  sortField="auction.number"
                  title="Číslo jednací"
                  onSortChange={(val) => sortChange(val)}
                />
                <TableColumn className="text-nowrap" title="Jméno dražitele" />
                <TableColumn className="text-nowrap" title="Druh dražitele" />
                <TableColumn
                  className="text-nowrap"
                  title="Var. symbol"
                  sort={query.sort as TableColumnSortProps}
                  sortField="userAuction.variableSymbol"
                  onSortChange={(val) => sortChange(val)}
                />
                <TableColumn
                  className="text-nowrap"
                  title="Specif. symbol"
                  sort={query.sort as TableColumnSortProps}
                  sortField="userAuction.specificSymbol"
                  onSortChange={(val) => sortChange(val)}
                />
                <TableColumn
                  sort={query.sort as TableColumnSortProps}
                  className="text-nowrap"
                  title="Datum přihlášení"
                  sortField="userAuction.joinedTime"
                  onSortChange={(val) => sortChange(val)}
                />
                <TableColumn className="text-nowrap" title="Ověření totožnosti" />
                <TableColumn className="text-nowrap" title="Jistota" />
                <TableColumn className="text-nowrap" title="Dokumenty - počet" />
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={`list-item-${index}`}>
                  <td className="auction-column-title">
                    <Link
                      to={`${pageState.getPagePath(routes.admin.AUCTIONS_UPDATE, {
                        ':id': item.auction.id,
                      })}?back=admin-buyers`}
                    >
                      {item.auction.title}
                    </Link>
                  </td>
                  <td className="auction-column-title">{item.auction.number}</td>
                  <td>
                    <span className="d-flex">
                      <Link
                        className="d-flex mr-1"
                        to={`${pageState.getPagePath(routes.admin.USER_DETAIL, {
                          ':id': item.user.id.toString(),
                        })}?back=admin-buyers`}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" />
                      </Link>
                      {item.user.subjectName || '-'}
                    </span>
                  </td>
                  <td>{item.user.buyerTypeTranslation || '-'}</td>
                  <td>{item.variableSymbol || '-'}</td>
                  <td>{item.specificSymbol || '-'}</td>
                  <td>{item.joinedTime ? getDateTimeFormat(item.joinedTime) : '-'}</td>
                  <td className="auction-column-title">
                    <span className="d-flex">
                      <Link
                        className="d-flex align-items"
                        to={`${pageState.getPagePath(routes.admin.USER_DOCUMENTS, {
                          ':buyerId': item.user.id.toString(),
                          ':auctionId': item.auction.id.toString(),
                        })}?section=${UserDocumentsPanel.security}&back=admin-buyers`}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" width={18} height={18} />
                      </Link>
                      {item.authorizationStateTranslation}
                    </span>
                  </td>
                  <td>
                    <span className="d-flex">
                      <Link
                        className="d-flex mr-1"
                        to={`${pageState.getPagePath(routes.admin.USER_DOCUMENTS, {
                          ':buyerId': item.user.id.toString(),
                          ':auctionId': item.auction.id.toString(),
                        })}?section=${UserDocumentsPanel.auctionSecurity}&back=admin-buyers`}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" />
                      </Link>
                      {getAuctionSecurity(item)}
                    </span>
                  </td>
                  <td>{item.user.nonDeclinedDocumentCount}</td>
                </tr>
              ))}
              {items.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {dataLen > query.perPage && (
          <div className="mt-2">
            <Pagination
              page={query.page}
              perPage={query.perPage}
              totalRows={dataLen}
              perPageChoiceEnabled={true}
              onPageChange={(page, perPage) => setQuery({ ...query, page: page, perPage: perPage }, 'push')}
            />
          </div>
        )}
      </AdminBoxContent>
    );
  };

  return (
    <>
      <DocumentsFiltersForm values={{ ...query }} onSubmit={(values) => setQuery({ ...values, s: 1 })} />
      {renderContent()}
    </>
  );
};
