import React from 'react';
import { FormikValues } from 'formik';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { useAuctionsApi } from '@api/auctions';
import { AuctionSections } from './AuctionsUpdatePage';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { BaseObjectType } from '@types';

let saveTimeout: NodeJS.Timeout | undefined = undefined;

export const useConcept = (
  values: FormikValues,
  setValues: any,
  validSections: any,
  setValidSections: (val: any) => void,
  setCurrentCategory: (val: string) => void,
  auctionId?: string
) => {
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const auctionsApi = useAuctionsApi(true, true);
  const [conceptData, setConceptData] = React.useState<BaseObjectType | undefined>();
  const [loaded, setLoaded] = React.useState(false);
  const defaultQuery = React.useMemo(
    () => ({
      section: withDefault(NumberParam, AuctionSections.auction),
      copyFrom: withDefault(StringParam, null),
      concept: withDefault(StringParam, undefined),
      type: withDefault(StringParam, 'public'),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  React.useEffect(() => {
    if (!!auctionId || !!query.copyFrom) {
      setLoaded(true);
      return;
    }
    if (!!saveTimeout) {
      clearTimeout(saveTimeout);
    }
    if (!!query.concept) {
      if (loaded) {
        saveTimeout = setTimeout(() => {
          saveConcept({ values, validSections }, query.concept);
        }, 1000);
      } else {
        loadConcept(query.concept);
      }
    } else if (!loaded) {
      saveConcept({ values, validSections });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, validSections, query.type]);

  React.useEffect(() => {
    if (!!auctionId) {
      return;
    }
    return () => {
      auctionsApi.cancelAllRequests();
      if (!!saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveConcept = async (conceptData: any, conceptId?: string | number) => {
    try {
      const response = await auctionsApi.saveConcept(
        conceptData,
        query.type === 'auction' ? 'auction' : 'auction_public',
        conceptId
      );
      setLoaded(true);
      if (!conceptId) {
        setQuery({ ...query, concept: response.data.data.id.toString() });
      }
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadConcept = async (conceptId: string) => {
    try {
      const response = await auctionsApi.getContept(conceptId);
      const data = !!response.data.data.data ? JSON.parse(response.data.data.data) : {};
      setConceptData(data);
      const dataValues = data.values || {};
      const dataSections = data.validSections || {};
      if (dataValues.auctionRootCategory && dataValues.auctionCategory) {
        const categories = await auctionCategoriesApi.getCategories(dataValues.auctionRootCategory);
        setCurrentCategory(
          categories.data.data.find((v) => v.id.toString() === dataValues.auctionCategory.toString())
            ?.auctionParameterType || ''
        );
      }
      setQuery({ ...query, type: response.data.data.auctionType === 'auction' ? 'auction' : 'public' });
      setValues({ ...values, ...dataValues });
      setValidSections({ ...validSections, ...dataSections });
      setLoaded(true);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  return { conceptData, isConceptLoaded: loaded };
};
