import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import * as routes from '@routes';
import { useAuthApi } from '@api/auth';
import { PageType, User } from '@types';
import { useAuthFrontStore } from '@stores/authFront';
import { usePageContext, usePageStore } from '@stores';

import logoutIcon from '@assets/images/front/header/logout.svg';
import lockColor16 from '@assets/images/front/icons/lock-color-16.svg';
import arrowTopColor6 from '@assets/images/front/icons/arrow-top-color-6.svg';
import arrowBottomWhite6 from '@assets/images/front/icons/arrow-bottom-white-6.svg';
import { getName } from '@helpers/string';

interface Props {
  user?: User;
}

const HeaderLoggedUser: React.FC<Props> = (props) => {
  const authApi = useAuthApi();
  const navigate = useNavigate();
  const authFrontState = useAuthFrontStore();
  const pageState = usePageStore();
  const { activePage } = usePageContext();

  const items = [
    pageState.getPage(routes.front.ACCOUNT) as PageType,
    ...pageState.getPagesByParentPageId(pageState.getPage(routes.front.ACCOUNT)?.id || 0, false),
  ]
    .filter((page) => page)
    .map((page) => ({
      label: page.name,
      testId: `header-logged-user-${page.routeName}`,
      active: page.routeName === activePage?.routeName,
      link: pageState.getPagePath(page.routeName),
    }));

  const handleContentClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(pageState.getPagePath(routes.front.ACCOUNT));
  };

  const handleLogoutClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    await authApi.logout(false);
    authFrontState.unsetUser();
    await pageState.reload();
    navigate('/');
  };

  return (
    <div className="component-header-logged-user" data-test-id="header-logged-user">
      <div className="menu-content" style={{ cursor: 'pointer' }} onClick={handleContentClick}>
        <img src={lockColor16} alt="icon" className="hover-hide" />
        <img src={lockColor16} alt="icon" className="hover-show" />
        <span>{getName(props.user) || props.user?.email}</span>
        <img src={arrowBottomWhite6} alt="icon" className="menu-arrow hover-hide" />
        <img src={arrowTopColor6} alt="icon" className="menu-arrow hover-show" />
      </div>
      <div className="hover-block">
        <div className="hover-block-content">
          <ul>
            {items.map((item, index) => (
              <li key={`menu-item-${index}`} className={cx({ 'item-active': item.active })}>
                <Link to={item.link} data-test-id={item.testId}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
          <a href="/" onClick={handleLogoutClick} className="logout" data-test-id="header-logged-user-logout">
            <img src={logoutIcon} alt="icon" />
            Odhlásit se
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderLoggedUser;
