import React from 'react';

import plusIco from '@assets/images/plus-ico.svg';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface TreeNode {
  id: string | number;
  title: {
    props: {
      to: string;
      children: string;
    };
  };
  children?: TreeNode[];
}

interface Props {
  data: TreeNode[];
  onDataChange: (data: TreeNode[]) => void;
}

const TreeNodeComponent: React.FC<{
  node: TreeNode;
  parentId: string | number | null;
}> = ({ node, parentId }) => {
  // Generate a unique eventKey by combining parentId and nodeId
  console.log(node);
  const eventKey = node.title.props.children;
  console.log(eventKey);
  return (
    <div className="tree-node">
      <div className="node-content">
        <Link to={node.title.props.to}>{node.title.props.children}</Link>

        {node.children && node.children.length > 0 && (
          <Accordion.Toggle as="div" eventKey={eventKey} className="toggle-btn">
            <img src={plusIco} alt="ico" className="mr-2 show-more" />
          </Accordion.Toggle>
        )}
      </div>

      {node.children && node.children.length > 0 && (
        <Accordion.Collapse eventKey={eventKey}>
          <div className="node-children">
            <Accordion>
              {node.children.map((child) => (
                <TreeNodeComponent
                  key={child.id}
                  node={child}
                  parentId={eventKey} // Pass current node's eventKey as parentId
                />
              ))}
            </Accordion>
          </div>
        </Accordion.Collapse>
      )}
    </div>
  );
};

const PagesTree: React.FC<Props> = ({ data }) => {
  return (
    <div className="pages-tree">
      <Accordion>
        {data.map((node) => (
          <TreeNodeComponent key={node.id} node={node} parentId={null} />
        ))}
      </Accordion>
    </div>
  );
};

export default PagesTree;
