import React from 'react';
import { useNavigate } from 'react-router-dom';

import gavelIco from '@assets/images/front/account/gavel.svg';
import gavelColorIco from '@assets/images/front/account/gavel-color.svg';
import dogIco from '@assets/images/front/account/dog.svg';
import dogColorIco from '@assets/images/front/account/dog-color.svg';
import bellIco from '@assets/images/front/account/bell.svg';
import bellColorIco from '@assets/images/front/account/bell-color.svg';

import { FrontBannerNavigation } from '@components';
import * as routes from '@routes';
import { usePageStore } from '@stores';

const AccountLinks: React.FC = () => {
  const navigate = useNavigate();
  const pageState = usePageStore();

  return (
    <>
      <div className="account-page-title">Důležité odkazy</div>
      <FrontBannerNavigation
        xs={7}
        sm={4}
        md={4}
        lg={4}
        xl={4}
        items={[
          {
            icon: gavelColorIco,
            iconActive: gavelIco,
            btnClassName: 'btn-small',
            style: { flex: 1 },
            btnText: 'Zobrazit',
            text: (
              <span className="item-heading">
                Moje <br />
                dražby
              </span>
            ),
            onClick: () => navigate(pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS)),
          },
          {
            icon: dogColorIco,
            iconActive: dogIco,
            btnClassName: 'btn-small',
            style: { flex: 1 },
            btnText: 'Nastavit',
            text: (
              <span className="item-heading">
                Hlídací <br />
                pes
              </span>
            ),
            onClick: () => navigate(pageState.getPagePath(routes.front.ACCOUNT_DOG)),
          },
          {
            icon: bellColorIco,
            iconActive: bellIco,
            btnClassName: 'btn-small',
            style: { flex: 1 },
            btnText: 'Zobrazit',
            text: (
              <span className="item-heading">
                Doručené <br />
                e-maily
              </span>
            ),
            onClick: () => navigate(pageState.getPagePath(routes.front.ACCOUNT_EMAILS)),
          },
        ]}
      />
    </>
  );
};

export default AccountLinks;
