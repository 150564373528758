import L from 'leaflet';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import config from '@config';
import { AddressType, LatLng } from '@types';
import { useAddressApi } from '@api/address';
import { BasePreloader, Button, FormGroup, Modal } from '@components';

import mapPointImage from '@assets/images/map-point.svg';
import { AutoCompleteInput } from '@components/AutoCompleteInput';

const getAddressLabel = (gpsData: AddressType, lat: number, long: number) => {
  let newAddress = '';
  if (gpsData.addressStreet) {
    newAddress += gpsData.addressStreet;
  }
  if (gpsData.addressHouseNumber) {
    if (gpsData.addressStreet) {
      newAddress += ' ';
    }
    newAddress += gpsData.addressHouseNumber;
  }
  if (gpsData.addressCity) {
    if (newAddress) {
      newAddress += ', ';
    }
    newAddress += gpsData.addressCity;
  }
  if (gpsData.addressCity && gpsData.addressZipCode) {
    newAddress += ` ${gpsData.addressZipCode}`;
  }
  if (newAddress) {
    newAddress += ' ';
  }
  newAddress += `(${lat},${long})`;
  return newAddress;
};

const markerIcon = L.icon({
  iconUrl: mapPointImage,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
});

type Props = {
  isOpen: boolean;
  gps?: string;
  onClose: () => void;
  onSelect: (address: AddressType) => void;
};

let markerLayer: L.LayerGroup | undefined = undefined;

export const MapAddressModal: React.FC<Props> = (props) => {
  const addressApi = useAddressApi();

  const [loaded, setLoaded] = useState(false);
  const mapInstanceRef = useRef<L.Map | null>(null);
  const [addressGps, setAddressGps] = useState<LatLng | undefined>();
  const [fullAddress, setFullAddress] = useState<AddressType | undefined>();
  const [address, setAddress] = useState('');

  const mapCallback = useCallback(async (node: HTMLDivElement) => {
    if (!node) return;
    const center: L.LatLngExpression = [49.8037633, 15.4749126];

    if (!mapInstanceRef.current) {
      const _mapInstance = L.map(node).setView(center, 7);
      L.tileLayer(`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${config.mapyApiKey}`, {
        attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
        maxZoom: 19,
      }).addTo(_mapInstance);

      const LogoControl = L.Control.extend({
        options: {
          position: 'bottomleft',
        },

        onAdd: function () {
          const container = L.DomUtil.create('div');
          const link = L.DomUtil.create('a', '', container);

          link.setAttribute('href', 'http://mapy.cz/');
          link.setAttribute('target', '_blank');
          link.innerHTML = '<img alt="logo" src="https://api.mapy.cz/img/api/logo.svg" />';
          L.DomEvent.disableClickPropagation(link);

          return container;
        },
      });

      markerLayer = L.layerGroup().addTo(_mapInstance);
      mapInstanceRef.current = _mapInstance;

      new LogoControl().addTo(_mapInstance);

      if (props.gps) {
        const [lat, lng] = props.gps.split(',').map(parseFloat);
        const markerLatLng: L.LatLngExpression = [lat, lng];

        L.marker(markerLatLng, { icon: markerIcon }).addTo(markerLayer);
        _mapInstance.setView(markerLatLng, 18);

        try {
          const res = await addressApi.gpsAddress(lat, lng);
          setAddress(getAddressLabel(res.data.data, markerLatLng[0], markerLatLng[1]));
          setAddressGps({ lat: markerLatLng[0], lng: markerLatLng[1] });
          setFullAddress(res.data.data);
          setLoaded(true);
        } catch (err: any) {
          if (addressApi.isCancel(err)) {
            return;
          }
          setLoaded(true);
        }
      } else {
        setLoaded(true);
      }

      _mapInstance.on('click', handleMapClick);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMapClick = async (e: L.LeafletMouseEvent) => {
    const { lat, lng } = e.latlng;

    setLoaded(false);

    if (markerLayer) {
      markerLayer.clearLayers();
    }

    const markerLatLng: L.LatLngExpression = [lat, lng];
    if (markerLayer) {
      L.marker(markerLatLng, { icon: markerIcon }).addTo(markerLayer);
    }
    mapInstanceRef.current?.setView(markerLatLng, 18);

    try {
      const res = await addressApi.gpsAddress(lat, lng);
      setFullAddress(res.data.data);
      setAddressGps({ lat, lng });
      setAddress(getAddressLabel(res.data.data, lat, lng));

      setLoaded(true);
    } catch (err: any) {
      if (addressApi.isCancel(err)) {
        return;
      }
      setLoaded(true);
    }
  };

  const handleSelectClick = async () => {
    if (fullAddress) {
      props.onSelect(fullAddress);
    }
  };

  return (
    <Modal
      title="Mapa"
      className="component-admin-map-modal"
      isOpen={props.isOpen}
      onRequestClose={() => props.onClose()}
    >
      <div>
        <AutoCompleteInput
          inputId="address-input"
          placeHolder="Zvolte adresu..."
          onSelection={async (origData: any) => {
            if (markerLayer) {
              markerLayer.clearLayers();
            }

            try {
              setLoaded(false);
              const res = await addressApi.gpsAddress(origData.position.lat, origData.position.lon);
              setFullAddress(res.data.data);
              setAddressGps({ lat: origData.position.lat, lng: origData.position.lon });
              setAddress(getAddressLabel(res.data.data, origData.position.lat, origData.position.lon));
              const markerLatLng: L.LatLngExpression = [origData.position.lat, origData.position.lon];
              if (markerLayer) {
                L.marker(markerLatLng, { icon: markerIcon }).addTo(markerLayer);
              }
              mapInstanceRef.current?.setView(markerLatLng, 18);
              setLoaded(true);
            } catch (err: any) {
              if (addressApi.isCancel(err)) {
                return;
              }
              setLoaded(true);
            }
          }}
        >
          <div className="address-input-wrapper">
            <FormGroup
              readOnly={!loaded}
              name="address"
              value={address}
              label="Adresa:"
              id="address-input"
              labelClassName="address-label"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
            />
          </div>
        </AutoCompleteInput>
        <div>
          <div className="map" ref={mapCallback as any} style={{ height: 500 }} />
        </div>
        {!!addressGps && (
          <div className="button-wrapper">
            {loaded ? (
              <Button type="button" onClick={() => handleSelectClick()}>
                Vyplnit adresu
              </Button>
            ) : (
              <BasePreloader />
            )}
          </div>
        )}
        {!addressGps && !loaded && (
          <div className="button-wrapper">
            <BasePreloader />
          </div>
        )}
      </div>
    </Modal>
  );
};
