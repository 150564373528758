import React from 'react';
import { Form } from 'react-bootstrap';
import { OnChangeValue } from 'react-select';
import { FormikErrors, FormikValues } from 'formik';

import { AddressType, AuctioneerAddress } from '@types';
import { useAuctionsApi } from '@api/auctions';
import { useLocationApi } from '@api/location';
import { getZipFormat } from '@helpers/formats';
import { useAuctioneersApi } from '@api/auctioneers';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { BasePreloader, Button, ControlFeedback, FormGroup, MapAddressModal, Select } from '@components';

interface Props {
  id: string;
  values: FormikValues;
  isSubmitting: boolean;
  errors: FormikErrors<FormikValues>;
  getTranslation: (key: string) => string;
  setValues: (values: any) => any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
}

interface SelectOptionType {
  value: string;
  label: string;
}

const LocationSection: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const auctioneersApi = useAuctioneersApi();
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const locationApi = useLocationApi();

  const [showMapModal, setShowMapModal] = React.useState(false);
  const [showAuctioneerAddress, setShowAuctioneerAddress] = React.useState(!props.id);
  const [addressLoaded, setAddressLoaded] = React.useState(false);
  const [auctioneerAddressData, setAuctioneerAddressData] = React.useState<AuctioneerAddress[]>([]);
  const [countryOptions, setCountryOptions] = React.useState<Array<SelectOptionType>>([]);
  const [regionOptions, setRegionOptions] = React.useState<Array<SelectOptionType>>([]);
  const [districtOptions, setDistrictOptions] = React.useState<Array<SelectOptionType>>([]);
  const [isDistrictOptionsLoading, setIsDistrictOptionsLoading] = React.useState(false);
  const [districtPartOptions, setDistrictPartOptions] = React.useState<Array<SelectOptionType>>([]);
  const [isDistrictPartOptionsLoading, setIsDistrictPartOptionsLoading] = React.useState(false);

  React.useEffect(() => {
    loadAuctioneerAddress();
    if (!!props.id) {
      props.setFieldValue('data.auctioneerAddress', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAuctioneerAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toString();
    props.setFieldValue('data.auctioneerAddress', value);
    const currentAddress = auctioneerAddressData.find((address) => address.id.toString() === value);

    if (!!currentAddress) {
      props.setFieldValue('data.addressCountry', currentAddress.country?.id || '');
      props.setFieldValue('data.addressCadastralArea', currentAddress.cadastralArea || '');
      props.setFieldValue('data.addressRegion', currentAddress.region?.id || '');
      props.setFieldValue('data.addressDistrict', currentAddress.district?.id || '');
      props.setFieldValue('data.addressDistrictPart', currentAddress.districtPart?.id || '');
      props.setFieldValue('data.addressCity', currentAddress.city || '');
      props.setFieldValue('data.addressZipCode', currentAddress.zipCode || '');
      props.setFieldValue('data.addressStreet', currentAddress.street || '');
      props.setFieldValue('data.addressHouseNumber', currentAddress.houseNumber || '');
      props.setFieldValue('data.addressRuian', currentAddress.ruian || '');
      props.setFieldValue('data.addressGps', currentAddress.gps);

      if (!!props.id) {
        setShowAuctioneerAddress(false);
      }
    }
  };

  const loadAuctioneerAddress = async () => {
    try {
      const auctioneer = (await auctioneersApi.detail(props.id)).data.data;
      const auctioneerAddressList = (await auctioneersApi.establishmentsList(props.id as string)).data?.data;
      setAddressLoaded(true);
      if (auctioneer.deliveryAddress) {
        auctioneerAddressList?.unshift({
          ...auctioneer.deliveryAddress,
          id: 'delivery',
        });
      }
      if (auctioneer.invoiceAddress) {
        auctioneerAddressList?.unshift({
          ...auctioneer.invoiceAddress,
          id: 'invoice',
        });
      }
      setAuctioneerAddressData(auctioneerAddressList);
    } catch (err) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
      console.error(err);
      setAddressLoaded(true);
    }
  };

  const loadCountryOptions = async () => {
    try {
      const response = await locationApi.getCountries();
      setCountryOptions(
        response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
      if (!props.values.data.addressCountry && response.data.data.length > 0) {
        props.setFieldValue('data.addressCountry', response.data.data[0].id);
      }
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadRegionOptions = async (countryId: string) => {
    try {
      const response = await locationApi.getRegions(countryId);
      setRegionOptions(
        response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {}
  };

  React.useEffect(() => {
    if (props.values.data.addressCountry) {
      loadRegionOptions(props.values.data.addressCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.data.addressCountry]);

  const loadDistrictOptions = async (regionId: string) => {
    setIsDistrictOptionsLoading(true);

    try {
      const respose = await locationApi.getDistricts(regionId);
      setDistrictOptions(
        respose.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {
    } finally {
      setIsDistrictOptionsLoading(false);
    }
  };

  React.useEffect(() => {
    if (props.values.data.addressRegion) {
      loadDistrictOptions(props.values.data.addressRegion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.data.addressRegion]);

  const loadDistrictPartsOptions = async (districtId: string) => {
    if (!districtId) {
      setDistrictPartOptions([]);
    }
    setIsDistrictPartOptionsLoading(true);
    try {
      const respose = await locationApi.getDistrictParts(districtId);
      setDistrictPartOptions(
        respose.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    } finally {
      setIsDistrictPartOptionsLoading(false);
    }
  };

  React.useEffect(() => {
    if (props.values.data.addressDistrict) {
      loadDistrictPartsOptions(props.values.data.addressDistrict);
    } else {
      setDistrictPartOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.data.addressDistrict]);

  React.useEffect(() => {
    loadCountryOptions();

    if (props.values.data.addressCountry) {
      loadRegionOptions(props.values.data.addressCountry);
    }

    if (props.values.data.addressRegion) {
      loadDistrictOptions(props.values.data.addressRegion);
    }

    return () => {
      auctionsApi.cancelAllRequests();
      auctionCategoriesApi.cancelAllRequests();
      locationApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCountryChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('data.addressCountry', itemValue?.value || '');

    if (props.values.data.addressCountry !== itemValue?.value) {
      props.setFieldValue('data.addressRegion', '');
      props.setFieldValue('data.addressDistrict', '');
      props.setFieldValue('data.addressDistrictPart', '');
    }
  };

  const handleRegionChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('data.addressRegion', itemValue?.value || '');

    if (props.values.data.addressRegion !== itemValue?.value) {
      props.setFieldValue('data.addressDistrict', '');
      props.setFieldValue('data.addressDistrictPart', '');
    }
  };

  const handleDistrictChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('data.addressDistrict', itemValue?.value || '');

    if (props.values.data.addressDistrict !== itemValue?.value) {
      props.setFieldValue('data.addressDistrictPart', '');
    }
  };

  const handleDistrictPartChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('data.addressDistrictPart', itemValue?.value || '');
  };

  const handleSelectFromMap = (v: AddressType) => {
    props.setFieldValue('data.addressGps', v.addressGps);
    props.setFieldValue('data.addressCity', v.addressCity);
    props.setFieldValue('data.addressZipCode', v.addressZipCode);
    props.setFieldValue('data.addressStreet', v.addressStreet);
    props.setFieldValue('data.addressHouseNumber', v.addressHouseNumber);
    props.setFieldValue('data.addressRuian', v.addressRuian);
    props.setFieldValue('data.addressCadastralArea', v.addressCadastral);
    props.setFieldValue('data.addressCountry', v.addressCountry ? parseInt(v.addressCountry) : undefined);
    props.setFieldValue('data.addressRegion', v.addressRegion ? parseInt(v.addressRegion) : undefined);
    props.setFieldValue('data.addressDistrict', v.addressDistrict ? parseInt(v.addressDistrict) : undefined);
    props.setFieldValue(
      'data.addressDistrictPart',
      v.addressDistrictPart ? parseInt(v.addressDistrictPart) : undefined
    );
    setShowMapModal(false);
  };

  const renderAuctioneerAddress = () => {
    if (!showAuctioneerAddress && !!props.id) {
      return (
        <div className="mt-35 mb-4">
          <Button type="button" onClick={() => setShowAuctioneerAddress(true)}>
            {props.getTranslation('tab_location_btn_another_address')}
          </Button>
        </div>
      );
    }
    return (
      <>
        <div className="mt-4">
          <Button type="button" onClick={() => setShowMapModal(true)}>
            Vybrat z mapy
          </Button>
        </div>

        <div className="mt-35">
          {addressLoaded ? (
            <>
              {auctioneerAddressData.map((item, index) => (
                <div key={`address-${index}`} className="mb-3">
                  <Form.Check
                    custom
                    type="radio"
                    id={`address-${index}`}
                    name="auctioneerAddress"
                    className="radio-point"
                    value={item.id}
                    label={`${item.street} ${item.houseNumber}, ${item.city}, ${getZipFormat(item.zipCode || '')}`}
                    checked={item.id.toString() === (props.values.data.auctioneerAddress || '').toString()}
                    onChange={handleAuctioneerAddressChange}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="mb-3">
              <BasePreloader size={20} />
            </div>
          )}
          {!props.id && (
            <Form.Check
              custom
              type="radio"
              id="address-other"
              name="auctioneerAddress"
              className="radio-point"
              label="Jiná"
              value={0}
              checked={props.values.data.auctioneerAddress === 0}
              onChange={handleAuctioneerAddressChange}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="pl-2">
        <h2 className="f-weight-300 f-size-25">{props.getTranslation('tab_location_title')}</h2>

        {renderAuctioneerAddress()}

        <div className="responsive-table-content">
          <div className="pt-2">
            <Form.Group className="f-inline-group">
              <Form.Label className="f-inline-label text-left">
                {props.getTranslation('tab_location_label_auction_address_country')}
              </Form.Label>
              <div className="f-inline-control">
                <div className="w-max-500">
                  <Select
                    name="auctionAddress_country"
                    size="md"
                    isInvalid={!!props.errors.addressCountry}
                    options={countryOptions}
                    value={
                      countryOptions.find(
                        (i) => i.value.toString() === (props.values.data.addressCountry || '').toString()
                      ) || null
                    }
                    onChange={handleCountryChange}
                  />
                  {!!props.errors.addressCountry && (
                    <ControlFeedback type="invalid">{props.errors.addressCountry as string}</ControlFeedback>
                  )}
                </div>
              </div>
            </Form.Group>

            <Form.Group className="f-inline-group">
              <Form.Label className="f-inline-label text-left">
                {props.getTranslation('tab_location_label_auction_address_region')}
              </Form.Label>
              <div className="f-inline-control">
                <div className="w-max-500">
                  <Select
                    size="md"
                    name="auctionAddress_region"
                    isInvalid={!!props.errors.auctionAddress_region}
                    options={regionOptions}
                    value={
                      regionOptions.find(
                        (i) => i.value.toString() === (props.values.data.addressRegion || '').toString()
                      ) || null
                    }
                    onChange={handleRegionChange}
                  />
                  {!!props.errors.auctionAddress_region && (
                    <ControlFeedback type="invalid">{props.errors.auctionAddress_region as string}</ControlFeedback>
                  )}
                </div>
              </div>
            </Form.Group>

            <Form.Group className="f-inline-group">
              <Form.Label className="f-inline-label text-left">
                {props.getTranslation('tab_location_label_auction_address_district')}
              </Form.Label>
              <div className="f-inline-control">
                <div className="w-max-500">
                  <Select
                    size="md"
                    name="auctionAddress_district"
                    isInvalid={!!props.errors.auctionAddress_district}
                    options={districtOptions}
                    value={
                      districtOptions.find(
                        (i) => i.value.toString() === (props.values.data.addressDistrict || '').toString()
                      ) || null
                    }
                    onChange={handleDistrictChange}
                    isLoading={isDistrictOptionsLoading}
                  />
                  {!!props.errors.auctionAddress_district && (
                    <ControlFeedback type="invalid">{props.errors.auctionAddress_district as string}</ControlFeedback>
                  )}
                </div>
              </div>
            </Form.Group>

            {districtPartOptions.length > 0 && (
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-left">
                  {props.getTranslation('tab_location_label_auction_address_districtPart')}
                </Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      size="md"
                      isClearable
                      name="auctionAddress_districtPart"
                      isInvalid={!!props.errors.auctionAddress_districtPart}
                      options={districtPartOptions}
                      value={
                        districtPartOptions.find(
                          (i) => i.value.toString() === (props.values.data.addressDistrictPart || '').toString()
                        ) || null
                      }
                      onChange={handleDistrictPartChange}
                      isLoading={isDistrictPartOptionsLoading}
                    />
                    {!!props.errors.auctionAddress_districtPart && (
                      <ControlFeedback type="invalid">
                        {props.errors.auctionAddress_districtPart as string}
                      </ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>
            )}

            <FormGroup
              name="data.addressCity"
              label={props.getTranslation('tab_location_label_address_city')}
              labelClassName="text-left"
              error={props.errors.auctionAddress_city as string}
              value={props.values.data.addressCity}
              onChange={props.handleChange}
            />
            <FormGroup
              name="data.addressZipCode"
              labelClassName="text-left"
              value={props.values.data.addressZipCode}
              error={props.errors.auctionAddress_zipCode as string}
              label={props.getTranslation('tab_location_label_address_zip_code')}
              onChange={props.handleChange}
            />
            <FormGroup
              name="data.addressStreet"
              label={props.getTranslation('tab_location_label_address_street')}
              labelClassName="text-left"
              error={props.errors.auctionAddress_street as string}
              value={props.values.data.addressStreet}
              onChange={props.handleChange}
            />
            <FormGroup
              name="data.addressHouseNumber"
              label={props.getTranslation('tab_location_label_address_house_number')}
              labelClassName="text-left"
              error={props.errors.auctionAddress_houseNumber as string}
              value={props.values.data.addressHouseNumber}
              onChange={props.handleChange}
            />
            <FormGroup
              name="data.addressCadastralArea"
              label={props.getTranslation('tab_location_label_address_cadastral_area')}
              labelClassName="text-left"
              error={props.errors.auctionAddress_cadastralArea as string}
              value={props.values.data.addressCadastralArea}
              onChange={props.handleChange}
            />
            <FormGroup
              name="data.addressRuian"
              label={props.getTranslation('tab_location_label_address_ruian')}
              labelClassName="text-left"
              type="number"
              error={props.errors.auctionAddress_ruian as string}
              value={props.values.data.addressRuian}
              onChange={props.handleChange}
            />
            <FormGroup
              name="data.addressGps"
              label={props.getTranslation('tab_location_label_address_gps')}
              helpText={
                <div>
                  {props.getTranslation('tab_location_address_gps_help_text_title')}
                  <br />
                  {props.getTranslation('tab_location_address_gps_help_text_text')}
                </div>
              }
              labelClassName="text-left"
              error={`${props.errors.auctionAddress_latitude || ''}${
                props.errors.auctionAddress_longitude ? ', ' : ''
              }${props.errors.auctionAddress_longitude || ''}`}
              value={props.values.data.addressGps}
              onChange={props.handleChange}
            />
          </div>
        </div>
      </div>

      <div className="mt-2">
        <button
          type="button"
          className="btn btn-link"
          onClick={() =>
            props.setValues({
              ...(props.values || {}),
              data: {
                ...(props.values.data || {}),
                addressCountry: '',
                addressCadastralArea: '',
                addressRegion: '',
                addressDistrict: '',
                addressCity: '',
                addressZipCode: '',
                addressStreet: '',
                addressHouseNumber: '',
                addressRuian: '',
                addressGps: '',
              },
            })
          }
        >
          Smazat adresu
        </button>
      </div>

      {showMapModal && (
        <MapAddressModal
          isOpen={showMapModal}
          gps={props.values.addressGps}
          onSelect={handleSelectFromMap}
          onClose={() => setShowMapModal(false)}
        />
      )}
    </div>
  );
};

export default LocationSection;
