import React, { FormEvent } from 'react';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useHelpRequestApi } from '@api/helpRequest';
import { BasePreloader, Button, CaptchaWrapper, FormGroup } from '@components';

const initialFormValues = {
  to: 'exdrazby',
  name: '',
  email: '',
  phone: '',
  message: '',
  captcha: '',
};

export const ContactUs = () => {
  const helpRequestApi = useHelpRequestApi();
  const [captcha, setCaptcha] = React.useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);
  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    try {
      let values = {
        ...formik.values,
        captcha,
      };

      await helpRequestApi.sendForWeb(values);
      formik.setSubmitting(false);
      formik.setValues(initialFormValues);
      handleRegenerateCaptcha();
    } catch (err) {
      handleRegenerateCaptcha();
      if (!err.response) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const handleRegenerateCaptcha = () => {
    setRefreshReCaptcha((r) => !r);
  };

  const onVerify = React.useCallback((token: string) => {
    setCaptcha(token);
  }, []);

  return (
    <CaptchaWrapper>
      <div className="contact-us">
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
        <div className="contact-item">
          <Form onSubmit={(e: FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
            <h2 className="heading">Jak Vám můžeme pomoci?</h2>
            <div className="pt-2">
              <FormGroup
                required={true}
                name="name"
                label="Jméno a příjmení"
                labelClassName="text-left"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.errors.name as string}
              />
              <FormGroup
                required={true}
                name="email"
                label="E-mail"
                labelClassName="text-left"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email as string}
              />
              <FormGroup
                required={true}
                name="phone"
                label="Tel.:"
                labelClassName="text-left"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.errors.phone as string}
              />
              <FormGroup
                rows={4}
                as="textarea"
                name="message"
                label="Vaše zpráva"
                className="f-align-start"
                labelClassName="text-left"
                controlClassName="w-max-100p b-radius-20 noresize"
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.errors.message as string}
              />
              <div className="mt-2">
                {!formik.isSubmitting ? (
                  <Button type="submit" variant="btn-outline-primary" disabled={formik.isSubmitting}>
                    Odeslat
                  </Button>
                ) : (
                  <BasePreloader size={29} className="d-inline-block" />
                )}
                <div className="clearfix" />
              </div>
            </div>
          </Form>
        </div>
        <div className="contact-item contact-item--image" />
      </div>
    </CaptchaWrapper>
  );
};
