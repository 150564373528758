import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import clx from 'classnames';
import { FormikErrors } from 'formik';
import { Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import { getName } from '@helpers/string';
import { useAuctionsApi } from '@api/auctions';
import { BasePreloader, Button } from '@components';
import { getBaseNumberFormat } from '@helpers/formats';
import { getDateTimeFormat, getTimestamp } from '@helpers/datetime';
import { useApplicationStore, useAuthStore, userRoleChecks } from '@stores';
import { AuctionExportsHistoryResponse, AuctionExportsResponse, BaseType, User } from '@types';

import { ExportInstagramModal } from './ExportInstagramModal';
import AuctionExportTimeframeModal from './AuctionExportTimeframeModal';

import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoHistory from '@assets/images/color-ico-restore.svg';
import colorIcoGavel from '@assets/images/admin-sidebar/gavel-active.svg';

const localServers = ['exdrazby', 'portaldrazeb'];
const socialSites = ['facebook', 'instagram'];
let exportsTimeout: number | undefined = undefined;

interface Props {
  id: string;
  status: string;
  isEnded: boolean;
  startDate: string;
  setErrors: (errors: FormikErrors<BaseType>) => void;
}

const AuctionExport: React.FC<Props> = ({ id, status, startDate, isEnded, setErrors }) => {
  const auctionsApi = useAuctionsApi();
  const applicationState = useApplicationStore();
  const [auctionExports, setAuctionExports] = React.useState<Array<AuctionExportsResponse>>([]);
  const [auctionExportsHistory, setAuctionExportsHistory] = React.useState<Array<AuctionExportsHistoryResponse>>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showInstagramModal, setShowInstagramModal] = React.useState(false);
  const [showExportTimeframeModal, setShowExportTimeframeModal] = React.useState<number>(0);
  const authState = useAuthStore();
  const { isOfRoleSuperAdmin } = userRoleChecks(authState.user as User);

  const localAuctionExports = auctionExports.filter(
    (item) => ~localServers.indexOf(item.server) && !~socialSites.indexOf(item.server)
  );
  const remoteAuctionExports = auctionExports.filter(
    (item) => !~localServers.indexOf(item.server) && !~socialSites.indexOf(item.server)
  );
  const socialSitesAuctionExports = auctionExports.filter((item) => ~socialSites.indexOf(item.server));

  const [activeRemoteAuctions, setActiveRemoteAuctions] = React.useState<Array<string>>(
    remoteAuctionExports.filter((item) => item.state === 'active').map((item) => item.server)
  );
  const [activeSocialSitesAuctions, setActiveSocialSitesAuctions] = React.useState<Array<string>>(
    socialSitesAuctionExports.filter((item) => item.state === 'active').map((item) => item.server)
  );
  const [remoteAuctions, setRemoteAuctions] = React.useState<Array<string>>([]);
  const [singleRemoteAuction, setSingleRemoteAuction] = React.useState('');

  const serversToActivate = auctionExports
    .filter((item) => !~localServers.indexOf(item.server) && item.state !== 'active')
    .map((item) => item.server)
    .filter((item) => ~remoteAuctions.indexOf(item));

  React.useEffect(() => {
    loadExports(id);

    return () => {
      auctionsApi.cancelAllRequests();
      if (!!exportsTimeout) {
        clearTimeout(exportsTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    setActiveRemoteAuctions(
      auctionExports
        .filter(
          (item) => !~localServers.indexOf(item.server) && !~socialSites.indexOf(item.server) && item.state === 'active'
        )
        .map((item) => item.server)
    );

    setActiveSocialSitesAuctions(
      auctionExports
        .filter((item) => ~socialSites.indexOf(item.server) && item.state === 'active')
        .map((item) => item.server)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionExports]);

  const loadExports = async (auctionId: string, loadingDisabled?: boolean) => {
    if (!loadingDisabled) {
      setIsLoading(true);
    }
    if (!!exportsTimeout) {
      clearTimeout(exportsTimeout);
    }

    try {
      const exportsResponse = await auctionsApi.getAuctionExports(auctionId);
      const exportsHistoryResponse = await auctionsApi.getAuctionExportsHistory(auctionId);
      setAuctionExports(exportsResponse.data.data);
      setAuctionExportsHistory(exportsHistoryResponse.data.data);
      exportsTimeout = window.setTimeout(() => loadExports(auctionId, true), 5000);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleRemote = (server: string) => {
    if (~remoteAuctions.indexOf(server)) {
      setRemoteAuctions(remoteAuctions.filter((item) => item !== server));
      return;
    }
    setRemoteAuctions([...remoteAuctions, server]);
  };

  const handleToggleSingleRemote = (server: string, isLocal: boolean = false, forceExport: boolean = false) => {
    setErrors({});

    let isActive = !!~activeRemoteAuctions.indexOf(server);
    if (isLocal) {
      for (const index in localAuctionExports) {
        if (localAuctionExports[index].server === server && localAuctionExports[index].state === 'active') {
          isActive = true;
          break;
        }
      }
    }

    if (isActive && !forceExport) {
      setIsLoading(true);
      auctionsApi
        .cancelExportAuction(id, server)
        .then(() => loadExports(id))
        .catch((err) => {
          if (auctionsApi.isCancel(err)) {
            return;
          }
          loadExports(id);
        });
      return;
    }

    if (isLocal && !forceExport) {
      setIsLoading(true);
      auctionsApi
        .exportAuction(id, server, moment(applicationState.getTime()).toDate())
        .then(() => loadExports(id))
        .catch((err) => {
          if (auctionsApi.isCancel(err)) {
            return;
          }
          if (err.response?.data?.errors) {
            setErrors(err.response.data.errors);
          }
          loadExports(id);
        });
      return;
    }
    setSingleRemoteAuction(server);
    setShowExportTimeframeModal(remoteAuctionExports.some((v) => v.server === 'tipcars') ? 2 : 1);
  };

  const handleToggleSocialSites = (server: string) => {
    let isActive = !!~activeSocialSitesAuctions.indexOf(server);

    if (exportsTimeout) {
      clearTimeout(exportsTimeout);
    }

    if (isActive) {
      setIsLoading(true);
      auctionsApi
        .cancelExportAuction(id, server)
        .then(() => loadExports(id))
        .catch((err) => {
          if (auctionsApi.isCancel(err)) {
            return;
          }
          loadExports(id);
        });
    } else {
      handleFinishSaveRemote(null, null, undefined, server);
    }
  };

  const exportInstagram = async (server: string) => {
    setShowExportTimeframeModal(0);
    setShowInstagramModal(true);
  };

  const handleFinishSaveRemote = async (
    dateFrom: Date | null,
    dateTo: Date | null,
    weeks?: string,
    server?: string
  ) => {
    if (server === 'instagram') {
      await exportInstagram(server);
      return;
    }

    setShowExportTimeframeModal(0);
    setIsLoading(true);

    try {
      if (singleRemoteAuction || server) {
        const inputs: {
          server: string;
          dateFrom: Date | null;
          dateTo?: Date | null;
          weeks?: string;
        } = {
          server: server || singleRemoteAuction,
          dateFrom,
          dateTo,
          weeks,
        };

        if (inputs.server === 'tipcars') {
          inputs.dateTo = undefined;
        }

        await auctionsApi.exportAuction(
          id,
          inputs.server,
          inputs.dateFrom as Date,
          inputs.dateTo as Date,
          inputs.weeks
        );
        setSingleRemoteAuction('');
      } else
        for (const index in serversToActivate) {
          const inputs: {
            server: string;
            dateFrom: Date | null;
            dateTo?: Date | null;
            weeks?: string;
          } = {
            server: serversToActivate[index],
            dateFrom,
            dateTo,
            weeks,
          };

          if (inputs.server === 'tipcars') {
            inputs.dateTo = undefined;
          }

          await auctionsApi.exportAuction(
            id,
            inputs.server,
            inputs.dateFrom as Date,
            inputs.dateTo as Date,
            inputs.weeks
          );
          setRemoteAuctions([]);
        }
    } catch (err: any) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      if (err.response?.data?.errors) {
        setErrors(err.response.data.errors);
      }
    }

    loadExports(id);
  };

  const handleInitSaveRemote = () => {
    setErrors({});

    if (serversToActivate.length < 1) {
      return;
    }

    if (serversToActivate.length > 0) {
      setShowExportTimeframeModal(remoteAuctionExports.some((v) => v.server === 'tipcars') ? 2 : 1);
      return;
    }

    handleFinishSaveRemote(null, null);
  };

  const renderSection = (title: string, children: React.ReactNode) => {
    return (
      <div className="auction-export-table-wrapper">
        <div className="content-title">{title}</div>
        {isLoading ? (
          <div className="pt-5 pb-4">
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    );
  };

  const renderExportSection = (item: AuctionExportsResponse) => {
    const exportHistory = getExportHistory(item.server);
    if (exportHistory.length < 1 || isLoading) {
      return null;
    }
    return (
      <div id={`export-server-${item.server}`} className="auction-export-table-wrapper">
        <div className="export-content-title">
          <span className="item-title">{item.serverTranslation}</span>
        </div>

        <div className="mt-3">
          <Table className="table table-middle border-bottom-0" striped responsive>
            <thead>
              <tr>
                <th>Kdo</th>
                <th className="text-center">Čas akce</th>
                <th className="text-center">Akce</th>
                <th className="text-center">Inzerce od - do</th>
                <th className="text-center">Stav</th>
              </tr>
            </thead>
            <tbody>
              {exportHistory.map((history, historyIndex) => (
                <tr key={`history-${historyIndex}`} style={{ background: 'white' }}>
                  <td>{getName(history.user)}</td>
                  <td className="text-center">{getDateTimeFormat(history.timeCreated, { withSeconds: true })}</td>
                  <td className="text-center">{history.actionTranslate}</td>
                  <td className="text-center">
                    {getDateTimeFormat(history.timeFrom)}
                    <>
                      {!!history.timeTo && (
                        <>
                          <br />
                          {getDateTimeFormat(history.timeTo)}
                        </>
                      )}
                    </>
                  </td>
                  <td className="text-center">
                    <span className="d-flex align-items-center justify-content-center">
                      {history.messageTranslation ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`history-${item.server}-${historyIndex}-message`}>
                              {history.messageTranslation || '-'}
                            </Tooltip>
                          }
                        >
                          <span>{history.statusTranslation || '-'}</span>
                        </OverlayTrigger>
                      ) : (
                        history.statusTranslation
                      )}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;
    if (checked) {
      const servers = remoteAuctionExports.map(({ server }) => server);
      setRemoteAuctions(servers);
    } else {
      setRemoteAuctions([]);
    }
  };

  const getExportHistory = (server: string) => {
    return auctionExportsHistory
      .filter((h) => h.auctionExport.server === server)
      .sort((x, y) => getTimestamp(y.timeCreated) - getTimestamp(x.timeCreated));
  };

  const canDelete = (item: AuctionExportsResponse) => {
    return item.state === 'active' && item.lastExportStatus !== 'fail';
  };

  const showExportLoader = (item: AuctionExportsResponse) => {
    const exports = getExportHistory(item.server);
    return exports.length > 0 && exports[0].status === 'wait' && exports[0].exportToday;
  };

  const showExportCancel = (item: AuctionExportsResponse) => {
    const exports = getExportHistory(item.server);
    return exports.length > 0 && exports[0].status === 'wait' && !exports[0].exportToday;
  };

  const renderAction = (item: AuctionExportsResponse) => {
    if ((isEnded && !isOfRoleSuperAdmin()) || (item.server === 'portaldrazeb' && item.state === 'active')) {
      return null;
    }

    return (
      <Link
        to="#"
        className="d-inline-flex align-items-center ml-4"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          handleToggleSingleRemote(item.server, true);
        }}
      >
        <img
          src={item.state === 'active' ? colorIcoDel : colorIcoGavel}
          alt="ico"
          className="mr-2"
          width="14px"
          height="14px"
        />
        {item.state === 'active' ? 'Smazat' : 'Inzerovat'}
      </Link>
    );
  };

  return (
    <div className="responsive-table-content">
      {showInstagramModal && (
        <ExportInstagramModal
          auctionId={id}
          isOpen={showInstagramModal}
          onClose={() => setShowInstagramModal(false)}
          onSuccess={() => {
            setShowInstagramModal(false);
            loadExports(id);
          }}
        />
      )}
      <AuctionExportTimeframeModal
        title="Export na inzertní portály"
        isOpen={showExportTimeframeModal > 0}
        exportTimeType={showExportTimeframeModal}
        onRequestClose={() => setShowExportTimeframeModal(0)}
        onSave={(dateFrom, dateTo, weeks?: string) => handleFinishSaveRemote(dateFrom, dateTo, weeks)}
        auctionStartDate={startDate}
      />
      {renderSection(
        'Lokální inzerce',
        <Table className="table-middle border-bottom-0" striped responsive>
          <thead>
            <tr>
              <th className={clx(['nowrap', { 'checkbox-column': !isEnded || isOfRoleSuperAdmin() }])}>
                Název serveru
              </th>
              <th className="text-center nowrap">Datum poslední změny</th>
              <th className="text-center nowrap">Inzerováno od</th>
              <th
                className={clx([
                  'nowrap',
                  {
                    'text-center': !isEnded || isOfRoleSuperAdmin(),
                    'text-right': isEnded && !isOfRoleSuperAdmin(),
                  },
                ])}
              >
                Stav exportu
              </th>
              {(!isEnded || isOfRoleSuperAdmin()) && <th />}
            </tr>
          </thead>
          <tbody>
            {localAuctionExports.length < 1 && (
              <tr>
                <td className="text-left" colSpan={100}>
                  Nebyla nalezena žádná data.
                </td>
              </tr>
            )}
            {localAuctionExports.map((item, index) => (
              <tr key={index}>
                <td className={clx({ 'checkbox-column': !isEnded || isOfRoleSuperAdmin() })}>
                  {item.serverTranslation}
                </td>
                <td className="text-center">
                  {item.timeUpdated ? moment(item.timeUpdated).format('DD.MM.YYYY HH:mm') : '-'}
                </td>
                <td className="text-center">
                  {item.dateFrom ? moment(item.dateFrom).format('DD.MM.YYYY HH:mm') : '-'}
                </td>
                <td
                  className={clx({
                    'text-center': !isEnded || isOfRoleSuperAdmin(),
                    'text-right': isEnded && !isOfRoleSuperAdmin(),
                  })}
                >
                  {item.exportStatus}
                </td>
                {(!isEnded || isOfRoleSuperAdmin()) && <td className="text-right">{renderAction(item)}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {remoteAuctionExports.length > 0 &&
        status !== 'adjourned' &&
        renderSection(
          'Export na inzertní portály',
          <>
            <Table className="table table-middle border-bottom-0" striped responsive>
              <thead>
                <tr>
                  <th className={clx(['nowrap', { 'checkbox-column': !isEnded }])}>Název serveru</th>
                  <th className="nowrap text-center">Datum poslední změny</th>
                  <th className="nowrap text-center">Dní inzerce</th>
                  <th className="text-center">Stav exportu</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {remoteAuctionExports.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td className={clx({ 'checkbox-column': !isEnded })}>
                        <span className="d-inline-flex align-items-center">
                          {!isEnded && (
                            <Form.Check
                              custom
                              type="checkbox"
                              id={item.server}
                              checked={!!~remoteAuctions.indexOf(item.server)}
                              onChange={() => handleToggleRemote(item.server)}
                            />
                          )}
                          {item.serverTranslation}
                        </span>
                      </td>
                      <td className="text-center">{item.timeUpdated ? getDateTimeFormat(item.timeUpdated) : '-'}</td>
                      <td className="text-center">{item.daysToPay ? getBaseNumberFormat(item.daysToPay) : '-'}</td>
                      <td className={clx(['wrap-normal', 'text-center'])}>
                        <span className="d-inline-flex align-items-center">
                          {item.exportStatus}
                          {showExportLoader(item) && (
                            <span className="d-inline-block ml-2">
                              <BasePreloader size={16} />
                            </span>
                          )}
                        </span>
                      </td>
                      {!isEnded ? (
                        <td className="text-right">
                          {getExportHistory(item.server).length > 0 && (
                            <Link
                              to="#"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(event) => {
                                event.preventDefault();
                                document
                                  .getElementById(`export-server-${item.server}`)
                                  ?.scrollIntoView({ behavior: 'smooth' });
                              }}
                            >
                              <img src={colorIcoHistory} alt="ico" className="mr-2" width="14px" height="14px" />
                              Historie
                            </Link>
                          )}
                          {item.state === 'active' && item.lastExportStatus === 'fail' && (
                            <Link
                              to="#"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleToggleSingleRemote(item.server, false);
                              }}
                            >
                              <img src={colorIcoDel} alt="ico" className="mr-2" width="14px" height="14px" />
                              Storno
                            </Link>
                          )}

                          {item.lastExportStatus === 'wait' && showExportCancel(item) && (
                            <Link
                              to="#"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleToggleSingleRemote(item.server, false, false);
                              }}
                            >
                              <img alt="ico" width="14px" height="14px" className="mr-2" src={colorIcoDel} />
                              Storno
                            </Link>
                          )}

                          {item.lastExportStatus !== 'wait' && (
                            <Link
                              to="#"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleToggleSingleRemote(item.server, false, !canDelete(item));
                              }}
                            >
                              <img
                                src={canDelete(item) ? colorIcoDel : colorIcoGavel}
                                alt="ico"
                                className="mr-2"
                                width="14px"
                                height="14px"
                              />
                              {canDelete(item) ? 'Odstranit' : 'Inzerovat'}
                            </Link>
                          )}
                        </td>
                      ) : (
                        <td className="text-right">
                          {getExportHistory(item.server).length > 0 ? (
                            <Link
                              to="#"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(event) => {
                                event.preventDefault();
                                document
                                  .getElementById(`export-server-${item.server}`)
                                  ?.scrollIntoView({ behavior: 'smooth' });
                              }}
                            >
                              <img src={colorIcoHistory} alt="ico" className="mr-2" width="14px" height="14px" />
                              Historie
                            </Link>
                          ) : (
                            'Žádná historie'
                          )}
                        </td>
                      )}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>

            {!isEnded && (
              <div className="action-panel">
                <div className="checkbox-wrapper ml-3">
                  <Form.Check custom type="checkbox" id="selectAll" onChange={handleSelectAll} />
                  <p>Označit vše</p>
                </div>

                <Button type="button" variant="btn-outline-primary" onClick={handleInitSaveRemote}>
                  Exportovat vybrané
                </Button>
              </div>
            )}
          </>
        )}

      {socialSitesAuctionExports.length > 0 &&
        status !== 'adjourned' &&
        renderSection(
          'Sociální sítě',
          <>
            <Table className="table table-middle border-bottom-0" striped responsive>
              <thead>
                <tr>
                  <th className="nowrap">Název serveru</th>
                  <th className="nowrap text-center">Datum poslední změny</th>
                  <th className="text-center">Stav exportu</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {socialSitesAuctionExports.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <span className="d-inline-flex align-items-center">{item.serverTranslation}</span>
                      </td>
                      <td className="text-center">{item.timeUpdated ? getDateTimeFormat(item.timeUpdated) : '-'}</td>
                      <td className={clx(['wrap-normal', 'text-center'])}>
                        <span className="d-inline-flex align-items-center">
                          {item.exportStatus}
                          {showExportLoader(item) && (
                            <span className="d-inline-block ml-2">
                              <BasePreloader size={16} />
                            </span>
                          )}
                        </span>
                      </td>
                      {!isEnded ? (
                        <td className="text-right">
                          {getExportHistory(item.server).length > 0 && (
                            <Link
                              to="#"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(event) => {
                                event.preventDefault();
                                document
                                  .getElementById(`export-server-${item.server}`)
                                  ?.scrollIntoView({ behavior: 'smooth' });
                              }}
                            >
                              <img src={colorIcoHistory} alt="ico" className="mr-2" width="14px" height="14px" />
                              Historie
                            </Link>
                          )}
                          {item.state === 'active' && item.lastExportStatus === 'fail' && (
                            <Link
                              to="#"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleToggleSingleRemote(item.server, false);
                              }}
                            >
                              <img src={colorIcoDel} alt="ico" className="mr-2" width="14px" height="14px" />
                              Storno
                            </Link>
                          )}

                          {item.server === 'instagram' && item.lastExportStatus !== 'wait' && !canDelete(item) && (
                            <Link
                              to="#"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleToggleSocialSites(item.server);
                              }}
                            >
                              <img src={colorIcoGavel} alt="ico" className="mr-2" width="14px" height="14px" />
                              Inzerovat
                            </Link>
                          )}

                          {item.server !== 'instagram' && item.lastExportStatus !== 'wait' && (
                            <Link
                              to="#"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleToggleSocialSites(item.server);
                              }}
                            >
                              <img
                                src={canDelete(item) ? colorIcoDel : colorIcoGavel}
                                alt="ico"
                                className="mr-2"
                                width="14px"
                                height="14px"
                              />
                              {canDelete(item) ? 'Odstranit' : 'Inzerovat'}
                            </Link>
                          )}
                        </td>
                      ) : (
                        <td className="text-right">
                          {getExportHistory(item.server).length > 0 ? (
                            <Link
                              to="#"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(event) => {
                                event.preventDefault();
                                document
                                  .getElementById(`export-server-${item.server}`)
                                  ?.scrollIntoView({ behavior: 'smooth' });
                              }}
                            >
                              <img src={colorIcoHistory} alt="ico" className="mr-2" width="14px" height="14px" />
                              Historie
                            </Link>
                          ) : (
                            'Žádná historie'
                          )}
                        </td>
                      )}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </>
        )}

      {remoteAuctionExports.map((item, index) => (
        <React.Fragment key={`export-${index}`}>{renderExportSection(item)}</React.Fragment>
      ))}

      {socialSitesAuctionExports.map((item, index) => (
        <React.Fragment key={`export-social-sites-${index}`}>{renderExportSection(item)}</React.Fragment>
      ))}
    </div>
  );
};

export default AuctionExport;
