import React, { useState } from 'react';
import _ from 'lodash';
import { Col, Form, Row } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';

import { useAuctionsApi } from '@api/auctions';
import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { BasePreloader, Button, FormGroup } from '@components';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { AuctionFieldsEnabled, AuctionParameterType } from '@types';

import { AuctionParameterForm } from './AuctionParameterForm';

interface Props {
  id?: string;
  values: FormikValues;
  isSubmitting: boolean;
  requiredParametersFields: string[];
  fieldsEnabled?: AuctionFieldsEnabled;
  errors: FormikErrors<FormikValues>;
  auctionType: 'auction' | 'auction_public';
  getTranslation: (key: string) => string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
}

const SubjectSection: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const [loaded, setLoaded] = useState(false);
  const [auctionParameterType, setAuctionParameterType] = useState<AuctionParameterType | null>(null);
  const [category, setCategory] = useState('');

  React.useEffect(() => {
    loadData();
    return () => {
      auctionsApi.cancelAllRequests();
      auctionCategoriesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const categoriesResponse = await auctionCategoriesApi.getCategories(props.values.auctionRootCategory);
      const categoryResponse = await auctionCategoriesApi.getCategory(props.values.auctionRootCategory);
      if (categoryResponse.data.data) {
        setCategory(categoryResponse.data.data.machineName);
      }
      const [activeCategory] = categoriesResponse.data.data.filter(
        (category) => `${category.id}` === props.values.auctionCategory
      );
      if (activeCategory) {
        setAuctionParameterType(activeCategory.auctionParameterType);
      }
      setLoaded(true);
    } catch (err: any) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  return (
    <div>
      {loaded ? (
        <div className="pl-2">
          <h2 className="f-weight-300 f-size-25">{props.getTranslation('tab_subject_title')}</h2>
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => props.handleSubmit(e)} className="mt-40">
            <div className="responsive-table-content">
              <div className="pt-2">
                <Form.Group className="f-inline-group">
                  <div className="f-inline-control d-flex align-items-center input-with-checkbox w-100">
                    <FormGroup
                      required={!props.values.generateTitle}
                      name="title"
                      label={props.getTranslation('tab_subject_label_title')}
                      className="mb-0"
                      labelClassName="text-left"
                      error={props.errors.title as string}
                      value={props.values.title}
                      readOnly={props.values.generateTitle || !_.get(props.fieldsEnabled, 'title', true)}
                      onChange={props.handleChange}
                    />
                    {_.get(props.fieldsEnabled, 'title', true) && (
                      <Form.Check
                        custom
                        type="checkbox"
                        label={props.getTranslation('tab_subject_label_generated_title')}
                        className=" mr-4"
                        id="post"
                        value="post"
                        name="generateTitle"
                        checked={props.values.generateTitle}
                        onChange={props.handleChange}
                      />
                    )}
                  </div>
                </Form.Group>

                {category === 'real_estate' && (
                  <FormGroup
                    name="part"
                    label={props.getTranslation('tab_subject_label_part')}
                    labelClassName="text-left"
                    error={props.errors.part as string}
                    value={props.values.part}
                    readOnly={!_.get(props.fieldsEnabled, 'part', true)}
                    onChange={props.handleChange}
                  />
                )}

                <FormGroup
                  name="placeAuctionDescription"
                  label={props.getTranslation('tab_subject_label_place_auction_description')}
                  labelClassName="text-left"
                  error={props.errors.placeAuctionDescription as string}
                  value={props.values.placeAuctionDescription}
                  readOnly={!_.get(props.fieldsEnabled, 'placeAuctionDescription', true)}
                  onChange={props.handleChange}
                />

                <Wysiwyg
                  required
                  name="description"
                  askBeforePasteHTML={false}
                  value={props.values.description}
                  defaultActionOnPaste="insert_only_text"
                  error={props.errors.description as string}
                  label={props.getTranslation('tab_subject_label_description')}
                  disabled={!_.get(props.fieldsEnabled, 'description', true)}
                  onChange={(val) => props.setFieldValue('description', val)}
                />

                <Wysiwyg
                  name="printDescription"
                  askBeforePasteHTML={false}
                  value={props.values.printDescription}
                  defaultActionOnPaste="insert_only_text"
                  error={props.errors.printDescription as string}
                  label={props.getTranslation('tab_subject_label_print_description')}
                  disabled={!_.get(props.fieldsEnabled, 'printDescription', true)}
                  onChange={(val) => props.setFieldValue('printDescription', val)}
                />

                {[
                  'real_estate_house',
                  'real_estate_flat',
                  'real_estate_land',
                  'real_estate_commercial',
                  'real_estate_other',
                ].includes(auctionParameterType ?? '') && (
                  <FormGroup
                    rows={10}
                    as="textarea"
                    name="descriptionForRealEstatePortal"
                    className="f-align-start"
                    labelClassName="text-left mb-0"
                    controlClassName="w-max-100p b-radius-20 noresize"
                    value={props.values.descriptionForRealEstatePortal}
                    error={props.errors.descriptionForRealEstatePortal as string}
                    readOnly={!_.get(props.fieldsEnabled, 'descriptionForRealEstatePortal', true)}
                    label={props.getTranslation('tab_subject_label_descriptionForRealEstatePortal')}
                    onChange={props.handleChange}
                  />
                )}

                <FormGroup
                  rows={4}
                  as="textarea"
                  name="visitationDescription"
                  label={props.getTranslation('tab_subject_label_visitation_description')}
                  className="f-align-start"
                  labelClassName="text-left mb-0"
                  controlClassName="w-max-100p b-radius-20 noresize"
                  error={props.errors.visitationDescription as string}
                  value={props.values.visitationDescription}
                  readOnly={!_.get(props.fieldsEnabled, 'visitationDescription', true)}
                  onChange={props.handleChange}
                />

                <FormGroup
                  rows={4}
                  as="textarea"
                  label={props.getTranslation('tab_subject_label_internal_description')}
                  name="internalDescription"
                  className="f-align-start"
                  labelClassName="text-left mb-0"
                  controlClassName="w-max-100p b-radius-20 noresize"
                  error={props.errors.internalDescription as string}
                  value={props.values.internalDescription}
                  readOnly={!_.get(props.fieldsEnabled, 'internalDescription', true)}
                  onChange={props.handleChange}
                />

                {auctionParameterType && (
                  <AuctionParameterForm
                    values={props.values}
                    isSubmitting={props.isSubmitting}
                    errors={props.errors}
                    setFieldValue={props.setFieldValue}
                    handleChange={props.handleChange}
                    handleSubmit={props.handleSubmit}
                    fieldsEnabled={props.fieldsEnabled}
                    auctionParameterType={auctionParameterType}
                    requiredParametersFields={props.requiredParametersFields}
                  />
                )}
              </div>
            </div>
            <div>
              <p className="f-size-12 w-weight-400 text-color-gray-2">
                {props.getTranslation('tab_subject_help_text')}
              </p>
            </div>
            {!props.id && (
              <Row>
                <Col xs={12} className="mt-4 text-right">
                  {!props.isSubmitting ? (
                    <Button type="submit">
                      {!!props.id
                        ? props.getTranslation('tab_subject_btn_save')
                        : props.getTranslation('tab_subject_btn_next')}
                    </Button>
                  ) : (
                    <BasePreloader size={29} className="d-inline-block" />
                  )}
                </Col>
              </Row>
            )}
          </Form>
        </div>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};

export default SubjectSection;
