import React, { useState } from 'react';
import _ from 'lodash';
import { OnChangeValue } from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';

import { useEnumApi } from '@api/enum';
import { getName } from '@helpers/string';
import { useAuctionsApi } from '@api/auctions';
import { useAuctioneersApi } from '@api/auctioneers';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { useAuthStore, useCurrentUserRoleChecks } from '@stores';
import { BasePreloader, Button, ControlFeedback, FormGroup, Select } from '@components';
import {
  AuctionStatusResponse,
  AuctionTypeResponse,
  AuctionCategoryReponse,
  Auctioneer,
  AuctionStatusGroupResponse,
  AuctioneerUserContactResponse,
  AuctioneerTemplate,
  BaseObjectType,
  AuctionFieldsEnabled,
} from '@types';

interface Props {
  id?: string;
  fieldsEnabled?: AuctionFieldsEnabled;
  copyFrom: string | null;
  values: FormikValues;
  isSubmitting: boolean;
  conceptData?: BaseObjectType;
  auctionType: 'auction' | 'auction_public';
  errors: FormikErrors<FormikValues>;
  templateId: string | null;
  setTemplateId: React.Dispatch<React.SetStateAction<string | null>>;
  getTranslation: (key: string) => string;
  onSubcategoryChange: (type: string) => void;
  setMainCategory: (value: boolean) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
}

interface SelectOptionType {
  value: string;
  label: string;
}

const AuctionSection: React.FC<Props> = (props) => {
  const enumApi = useEnumApi();
  const authState = useAuthStore();
  const auctionsApi = useAuctionsApi();
  const auctioneersApi = useAuctioneersApi();
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const currentUserRoleChecks = useCurrentUserRoleChecks();
  const [loaded, setLoaded] = React.useState(false);
  const [initAuctioneer, setInitAuctioneer] = React.useState(!props.id);
  const [auctioneers, setAuctioneers] = React.useState<Array<Auctioneer>>([]);
  const [auctionStatus, setAuctionStatus] = React.useState<Array<AuctionStatusResponse>>([]);
  const [auctionStatusGroups, setAuctionStatusGroups] = React.useState<AuctionStatusGroupResponse[]>([]);
  const [auctionTypes, setAuctionTypes] = React.useState<Array<AuctionTypeResponse>>([]);
  const [users, setUsers] = React.useState<Array<AuctioneerUserContactResponse>>([]);
  const [categories, setCategories] = React.useState<Array<AuctionCategoryReponse>>([]);
  const [subCategories, setSubCategories] = React.useState<Array<AuctionCategoryReponse>>([]);
  const [templatesData, setTemplatesData] = React.useState([] as Array<AuctioneerTemplate>);
  const [category, setCategory] = useState('');

  const [protestTimeOptions, setProtectTimeOptions] = React.useState<SelectOptionType[]>([]);

  const booleanOptions = [
    { value: 'false', label: props.getTranslation('tab_auction_label_no') },
    { value: 'true', label: props.getTranslation('tab_auction_label_yes') },
  ];

  const auctioneersOptions = auctioneers.map((i) => ({
    value: i.id.toString(),
    label: i.auctioneerName,
  }));
  const templatesOptions = templatesData.map((i) => {
    return {
      value: i.id.toString(),
      label: i.name,
    };
  });
  const usersOptions = users.map((user) => ({
    value: user.id.toString(),
    label: getName(user) || '-',
  }));

  const statusValues = auctionStatus.map((status) => ({
    value: status.type,
    label: status.translated,
  }));
  const statusOptions = auctionStatusGroups.map((g) => ({
    label: g.statusGroupTranslation,
    options: g.children.map((s) => ({
      isDisabled: s.disabled,
      value: s.status,
      label: s.statusTranslation,
    })),
  }));
  const typesOptions = auctionTypes.map((item) => ({
    value: item.type,
    label: item.translatedName,
  }));
  const categoriesOptions = categories.map((i) => ({
    label: i.title,
    value: i.id.toString(),
  }));
  const subCategoriesOptions = subCategories.map((i) => ({
    label: i.title,
    value: i.id.toString(),
    type: i.auctionParameterType,
    mandatoryHouseNumber: i.mandatoryHouseNumber,
  }));

  React.useEffect(() => {
    loadEnums();
    loadData();
    return () => {
      auctionsApi.cancelAllRequests();
      auctionCategoriesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!!props.values.auctionRootCategory) {
      loadCategories(props.values.auctionRootCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.auctionRootCategory]);

  React.useEffect(() => {
    if (props.values.auctioneer) {
      loadAuctionTypes(props.values.auctioneer);
      loadUsers(props.values.auctioneer);
      loadTemplates(props.values.auctioneer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.auctioneer]);

  React.useEffect(() => {
    if (props.values.auctionType) {
      loadAuctionStatus(props.values.auctionType);
    } else if (props.auctionType === 'auction') {
      loadAuctionStatus('auction');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.auctionType]);

  React.useEffect(() => {
    if (props.values.auctioneer && initAuctioneer) {
      const choosedAuctioneer = auctioneers.find((auctioneer) => `${auctioneer.id}` === props.values.auctioneer);
      if (choosedAuctioneer) {
        props.setFieldValue('numberPrefix', choosedAuctioneer.referenceNumber);
      }
    }
    if (auctioneers.length > 0 && !!props.values.auctioneer) {
      setInitAuctioneer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.auctioneer, auctioneers]);

  const loadEnums = async () => {
    try {
      const res = await enumApi.getProtestTime();
      setProtectTimeOptions(
        res.data.data.map((i) => ({
          label: i.translated,
          value: i.type.toString(),
        }))
      );
    } catch (err) {
      if (enumApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadData = async () => {
    try {
      const auctioneersResponse = await auctioneersApi.listByAuctionType(props.auctionType, props.id);
      setAuctioneers(auctioneersResponse.data.data);

      // Use first auctioneer if logged user is ROLE_AUCTIONEER
      if (
        !props.values.auctioneer &&
        auctioneersResponse.data.data.length > 0 &&
        (authState.user?.role || '').startsWith('ROLE_AUCTIONEER')
      ) {
        const item = auctioneersResponse.data.data[0];
        handleAuctioneerChange({ value: item.id.toString(), label: item.auctioneerName });
      }

      await loadCategories();
      if (!!props.values.auctionRootCategory) {
        await loadCategories(props.values.auctionRootCategory);
      }
      setLoaded(true);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const loadAuctionTypes = async (auctioneerId: string | number) => {
    try {
      const typesResponse = await auctionsApi.getAuctionTypeByType(auctioneerId, props.auctionType, props.id);
      setAuctionTypes(typesResponse.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadTemplates = async (auctioneerId: string) => {
    try {
      const templatesResponse = await auctioneersApi.listTemplates(auctioneerId);
      const templates = templatesResponse.data.data.filter((item) => item.auctionType === props.auctionType);
      if (
        !props.copyFrom &&
        JSON.stringify(props.values) !== JSON.stringify(props.conceptData?.values) &&
        !props.templateId
      ) {
        templates.map((item) => item.isDefault && !props.id && props.setTemplateId(item.id.toString()));
      }
      setTemplatesData(templates);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadAuctionStatus = async (auctionType: string) => {
    try {
      const statusResponse = await auctionsApi.getAuctionStatus(auctionType);
      setAuctionStatus(statusResponse.data.data);
      const statusGroupsResponse = await auctionsApi.getAuctionStatusGroups(auctionType, props.values.status);
      setAuctionStatusGroups(statusGroupsResponse.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadUsers = async (auctioneerId: string) => {
    try {
      const response = await auctioneersApi.usersContactList(auctioneerId);
      setUsers(response.data);
      if (!props.values.contactPerson && response.data.length > 0) {
        props.setFieldValue('contactPerson', response.data[0].id);
      }
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadCategories = async (rootCategory?: string) => {
    try {
      const categoriesResponse = await auctionCategoriesApi.getCategories(rootCategory);
      if (!!rootCategory) {
        setSubCategories(categoriesResponse.data.data);
        props.setMainCategory(categoriesResponse.data.data.length < 1);
        const categoryResponse = await auctionCategoriesApi.getCategory(props.values.auctionRootCategory);
        if (categoryResponse.data.data) {
          setCategory(categoryResponse.data.data.machineName);
          props.setFieldValue('auctionRootCategoryMachineName', categoryResponse.data.data.machineName);
        }
      } else {
        setCategories(categoriesResponse.data.data);
      }
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const handleAuctioneerChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    if (props.auctionType === 'auction') {
      props.setFieldValue('auctionType', 'auction');
    } else {
      props.setFieldValue('auctionType', '');
      props.setFieldValue('status', undefined);
    }
    props.setFieldValue('contactPerson', '');
    props.setFieldValue('auctioneer', itemValue?.value || '');
  };

  const handleTemplateChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setTemplateId(itemValue?.value || null);
    if (!itemValue) {
      props.setFieldValue('addressCadastralArea', '');
      props.setFieldValue('addressCountry', '');
      props.setFieldValue('addressRegion', '');
      props.setFieldValue('addressDistrict', '');
      props.setFieldValue('addressCity', '');
      props.setFieldValue('addressStreet', '');
      props.setFieldValue('addressLandNumber', '');
      props.setFieldValue('addressHouseNumber', '');
      props.setFieldValue('addressRuian', '');
      props.setFieldValue('addressGps', '');
      props.setFieldValue('auctionAuthorizationsType', []);
      props.setFieldValue('auctionCautionCash', { enabled: false });
      props.setFieldValue('auctionCautionBankGuarantee', { enabled: false });
      props.setFieldValue('auctionCautionBankAccount', { enabled: false });
    }
  };

  const handleStatusChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('status', itemValue?.value || '');
  };

  const handleTypeChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionType', itemValue?.value || '');
  };

  const handleCategoryChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.onSubcategoryChange('');
    props.setFieldValue('auctionCategory', '');
    props.setFieldValue('auctionRootCategory', itemValue?.value || '');
    props.setFieldValue('mandatoryRuian', true);
    props.setFieldValue('mandatoryHouseNumber', true);
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateHouseType', undefined);
  };

  const handleSubCategoryChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionCategory', itemValue?.value || '');
    props.onSubcategoryChange(_.get(value, 'type', ''));
    props.setFieldValue('mandatoryRuian', true);
    props.setFieldValue('mandatoryHouseNumber', _.get(value, 'mandatoryHouseNumber', true));
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateHouseType', undefined);
  };

  const handleUserChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('contactPerson', itemValue?.value || '');
  };

  const handleRepeatedChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('repeated', itemValue?.value === 'true');
  };

  const handleOverchargeChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('overcharge', itemValue?.value === 'true');
  };

  const handleNovelChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('novel', itemValue?.value === 'true');
  };

  const handleCanProtestChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('canProtest', itemValue?.value === 'true');
  };

  return (
    <div>
      {loaded ? (
        <div className="pl-2">
          <h2 className="f-weight-300 f-size-25">{props.getTranslation('tab_auction')}</h2>
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => props.handleSubmit(e)} className="mt-40">
            <div className="responsive-table-content">
              <div className="pt-2">
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-left">
                    {props.getTranslation('tab_auction_label_auctioneer')} *
                  </Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500" data-test-id="auctioneer">
                      <Select
                        size="md"
                        isDisabled={!_.get(props.fieldsEnabled, 'auctioneer', true)}
                        isInvalid={!!props.errors.auctioneer}
                        options={auctioneersOptions}
                        value={auctioneersOptions.find((i) => i.value === props.values.auctioneer) || null}
                        onChange={handleAuctioneerChange}
                      />
                      {!!props.errors.auctioneer && (
                        <ControlFeedback type="invalid">{props.errors.auctioneer as string}</ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
                {!props.id && !props.copyFrom && (
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label text-left">
                      {props.getTranslation('tab_auction_label_templates')}
                    </Form.Label>
                    <div className="f-inline-control">
                      <div className="w-max-500" data-test-id="template">
                        <Select
                          size="md"
                          isClearable
                          options={templatesOptions}
                          value={templatesOptions.find((i) => i.value === props.templateId) || null}
                          onChange={handleTemplateChange}
                          isDisabled={!props.values.auctioneer}
                        />
                      </div>
                    </div>
                  </Form.Group>
                )}

                {currentUserRoleChecks.isOfRoleSuperAdmin() && (
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label text-left">
                      {props.getTranslation('tab_auction_label_contact')} *
                    </Form.Label>
                    <div className="f-inline-control">
                      <div className="w-max-500" data-test-id="contactPerson">
                        <Select
                          size="md"
                          isInvalid={!!props.errors.contactPerson}
                          options={usersOptions}
                          onChange={handleUserChange}
                          value={
                            usersOptions.find((i) => i.value.toString() === props.values.contactPerson.toString()) ||
                            null
                          }
                          isDisabled={!_.get(props.fieldsEnabled, 'contactPerson', true)}
                        />
                        {!!props.errors.contactPerson && (
                          <ControlFeedback type="invalid">{props.errors.contactPerson as string}</ControlFeedback>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                )}

                {props.auctionType !== 'auction' && (
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label text-left">
                      {props.getTranslation('tab_auction_label_type')} *
                    </Form.Label>
                    <div className="f-inline-control">
                      <div className="w-max-500" data-test-id="auctionType">
                        <Select
                          isInvalid={!!props.errors.auctionType}
                          size="md"
                          options={typesOptions}
                          value={typesOptions.find((i) => i.value === props.values.auctionType) || null}
                          onChange={handleTypeChange}
                          isDisabled={!props.values.auctioneer || !_.get(props.fieldsEnabled, 'auctionType', true)}
                        />
                        {!!props.errors.auctionType && (
                          <ControlFeedback type="invalid">{props.errors.auctionType as string}</ControlFeedback>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                )}
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-left">
                    {props.getTranslation('tab_auction_label_status')} *
                  </Form.Label>
                  <div className="f-inline-control" data-test-id="auctionStatus">
                    <div className="w-max-500">
                      <Select
                        isInvalid={!!props.errors.status}
                        size="md"
                        options={statusOptions as any}
                        value={statusValues.find((i) => i.value === props.values.status) || null}
                        onChange={handleStatusChange}
                        isDisabled={
                          (!props.values.auctionType && props.auctionType === 'auction_public') ||
                          !_.get(props.fieldsEnabled, 'status', true)
                        }
                      />
                      {!!props.errors.status && (
                        <ControlFeedback type="invalid">{props.errors.status as string}</ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-left">
                    {props.getTranslation('tab_subject_label_repeated_auction')}
                  </Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500" data-test-id="auctionRepeated">
                      <Select
                        size="md"
                        options={booleanOptions}
                        value={booleanOptions.find((i) => i.value === String(props.values.repeated)) || null}
                        onChange={handleRepeatedChange}
                        isDisabled={!_.get(props.fieldsEnabled, 'repeated', true)}
                      />
                    </div>
                  </div>
                </Form.Group>

                {(props.values.auctionType === 'executor_auction_voluntary' ||
                  props.values.auctionType === 'executor_auction_involuntary') && (
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label text-left">
                      {props.getTranslation('tab_subject_label_afterNovel')}
                    </Form.Label>
                    <div className="f-inline-control">
                      <div className="w-max-500" data-test-id="afterNovel">
                        <Select
                          size="md"
                          options={booleanOptions}
                          isDisabled={!_.get(props.fieldsEnabled, 'afterNovel', true)}
                          value={booleanOptions.find((i) => i.value === String(props.values.novel)) || null}
                          onChange={handleNovelChange}
                        />
                      </div>
                    </div>
                  </Form.Group>
                )}

                {!props.values.novel &&
                  (props.values.auctionType === 'executor_auction_voluntary' ||
                    props.values.auctionType === 'executor_auction_involuntary') && (
                    <Form.Group className="f-inline-group">
                      <Form.Label className="f-inline-label text-left">
                        {props.getTranslation('tab_subject_label_can_protest')}
                      </Form.Label>
                      <div className="f-inline-control">
                        <div className="w-max-500" data-test-id="canProtest">
                          <Select
                            size="md"
                            options={booleanOptions}
                            isDisabled={!_.get(props.fieldsEnabled, 'canProtest', true)}
                            value={booleanOptions.find((i) => i.value === String(props.values.canProtest)) || null}
                            onChange={handleCanProtestChange}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  )}

                {!props.values.novel &&
                  !!props.values.canProtest &&
                  (props.values.auctionType === 'executor_auction_voluntary' ||
                    props.values.auctionType === 'executor_auction_involuntary') && (
                    <Form.Group className="f-inline-group">
                      <Form.Label className="f-inline-label text-left">
                        {props.getTranslation('tab_subject_label_protest_time')} *
                      </Form.Label>
                      <div className="f-inline-control">
                        <div className="w-max-500" data-test-id="protestTime">
                          <Select
                            size="md"
                            options={protestTimeOptions}
                            isInvalid={!!props.errors.protestTime}
                            isDisabled={!_.get(props.fieldsEnabled, 'protestTime', true)}
                            value={
                              protestTimeOptions.find((i) => i.value === (props.values.protestTime || '').toString()) ||
                              null
                            }
                            onChange={(val) => props.setFieldValue('protestTime', val?.value || '')}
                          />
                          {!!props.errors.protestTime && (
                            <ControlFeedback type="invalid">{props.errors.protestTime as string}</ControlFeedback>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  )}

                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-left">
                    {props.getTranslation('tab_auction_label_main_category')} *
                  </Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500" data-test-id="auctionCategory">
                      <Select
                        isInvalid={!!props.errors.auctionCategory}
                        size="md"
                        options={categoriesOptions}
                        isDisabled={!_.get(props.fieldsEnabled, 'auctionCategory', true)}
                        value={categoriesOptions.find((i) => i.value === props.values.auctionRootCategory) || null}
                        onChange={handleCategoryChange}
                      />
                      {!!props.errors.auctionCategory && (
                        <ControlFeedback type="invalid">{props.errors.auctionCategory as string}</ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>

                {subCategoriesOptions.length > 0 && (
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label text-left">
                      {props.getTranslation('tab_auction_label_sub_category')} *
                    </Form.Label>
                    <div className="f-inline-control">
                      <div className="w-max-500" data-test-id="auctionSubCategory">
                        <Select
                          isInvalid={!!props.errors.auctionCategory}
                          size="md"
                          options={subCategoriesOptions}
                          isDisabled={!_.get(props.fieldsEnabled, 'auctionCategory', true)}
                          value={subCategoriesOptions.find((i) => i.value === props.values.auctionCategory) || null}
                          onChange={handleSubCategoryChange}
                        />
                        {!!props.errors.auctionCategory && (
                          <ControlFeedback type="invalid">{props.errors.auctionCategory as string}</ControlFeedback>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                )}
                {(props.values.auctionType === 'executor_auction_voluntary' ||
                  props.values.auctionType === 'executor_auction_involuntary') &&
                  category === 'real_estate' &&
                  props.values.novel && (
                    <Form.Group className="f-inline-group">
                      <Form.Label className="f-inline-label text-left">
                        {props.getTranslation('tab_subject_label_overcharge_auction')}
                      </Form.Label>
                      <div className="f-inline-control">
                        <div className="w-max-500" data-test-id="auctionOvercharge">
                          <Select
                            size="md"
                            options={booleanOptions}
                            isDisabled={!_.get(props.fieldsEnabled, 'overcharge', true)}
                            value={booleanOptions.find((i) => i.value === String(props.values.overcharge)) || null}
                            onChange={handleOverchargeChange}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  )}
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label">
                    {props.getTranslation('tab_auction_label_number')} *
                  </Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500 d-flex w-100">
                      <div className="w-max-150 w-100 mr-2">
                        <FormGroup
                          required
                          name="numberPrefix"
                          controlOnly={true}
                          controlClassName="w-max-150"
                          labelClassName="text-left"
                          readOnly={!_.get(props.fieldsEnabled, 'numberPrefix', true)}
                          error={props.errors.numberPrefix as string}
                          value={props.values.numberPrefix}
                          onChange={props.handleChange}
                          dataTestId="numberPrefix"
                        />
                      </div>
                      <div className="w-100">
                        <FormGroup
                          name="numberSuffix"
                          required
                          controlOnly={true}
                          labelClassName="text-left"
                          readOnly={!_.get(props.fieldsEnabled, 'numberSuffix', true)}
                          error={!props.errors.numberPrefix ? (props.errors.numberSuffix as string) : undefined}
                          value={props.values.numberSuffix}
                          onChange={props.handleChange}
                          dataTestId="numberSuffix"
                        />
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div>
              <p className="f-size-12 w-weight-400 text-color-gray-2">
                {props.getTranslation('tab_auction_help_text')}
              </p>
            </div>
            {!props.id && (
              <Row>
                <Col xs={12} className="mt-4 text-right">
                  {!props.isSubmitting ? (
                    <Button type="submit">
                      {!!props.id
                        ? props.getTranslation('tab_auction_btn_save')
                        : props.getTranslation('tab_auction_btn_next')}
                    </Button>
                  ) : (
                    <BasePreloader size={29} className="d-inline-block" />
                  )}
                </Col>
              </Row>
            )}
          </Form>
        </div>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};

export default AuctionSection;
