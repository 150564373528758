import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PageType } from '@types';
import * as routes from '@routes';
import { usePageContext, usePageStore } from '@stores';
import { FrontSidebarMenu } from '@components/FrontSidebarBox';

const AccountSidebarMenu: React.FC = () => {
  const navigate = useNavigate();
  const pageState = usePageStore();
  const { activePage } = usePageContext();

  return (
    <FrontSidebarMenu
      title="Můj účet"
      items={[
        pageState.getPage(routes.front.ACCOUNT) as PageType,
        ...pageState.getPagesByParentPageId(pageState.getPage(routes.front.ACCOUNT)?.id || 0, false),
      ]
        .filter((page) => page)
        .map((page) => ({
          label: page.name,
          testId: `my-account-sidebar-${page.routeName}`,
          active: page.routeName === activePage?.routeName,
          onClick: () => navigate(pageState.getPagePath(page.routeName)),
        }))}
      onClick={(item) => {
        if (typeof item.onClick === 'function') {
          item.onClick();
        }
      }}
    />
  );
};

export default AccountSidebarMenu;
