import moment from 'moment';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { OnChangeValue } from 'react-select';
import React, { FC, useState } from 'react';

import { arrKeyExists } from '@helpers/validator';
import { getRequestDateTimeFormat } from '@helpers/datetime';
import { BaseType, AuctionParametersResponse } from '@types';
import { useAuctionParametersApi } from '@api/auctionParameters';
import { ControlFeedback, DatePickerInput, FormGroup, Select } from '@components';

import { BooleanParameter } from './BooleanParameter';
import { AuctionParameterForm } from './AuctionParameterForm';

interface SelectOptionType {
  value: string;
  label: string;
  mandatoryHouseNumber?: boolean;
}

export const RealEstateHouseForm: FC<AuctionParameterForm> = (props) => {
  const auctionParameterApi = useAuctionParametersApi();
  const [houseTypes, setHouseTypes] = useState<AuctionParametersResponse[]>([]);
  const [houseObjectTypes, setHouseObjectTypes] = useState<AuctionParametersResponse[]>([]);
  const [objectLocationTypes, setObjectLocationTypes] = useState<AuctionParametersResponse[]>([]);
  const [placementTypes, setPlacementTypes] = useState<AuctionParametersResponse[]>([]);
  const [buildingConstructionTypes, setBuildingConstructionTypes] = useState<AuctionParametersResponse[]>([]);
  const [objectStatusTypes, setObjectStatusTypes] = useState<AuctionParametersResponse[]>([]);
  const [heatingElement, setHeatingElement] = useState<AuctionParametersResponse[]>([]);
  const [heatingSource, setHeatingSource] = useState<AuctionParametersResponse[]>([]);
  const [circuitBreaker, setCircuitBreaker] = useState<AuctionParametersResponse[]>([]);
  const [waterHeatSource, setWaterHeatSource] = useState<AuctionParametersResponse[]>([]);
  const [waterTypes, setWaterTypes] = useState<AuctionParametersResponse[]>([]);
  const [electricityTypes, setElectricityTypes] = useState<AuctionParametersResponse[]>([]);
  const [wasteTypes, setWasteTypes] = useState<AuctionParametersResponse[]>([]);
  const [gasTypes, setGasTypes] = useState<AuctionParametersResponse[]>([]);
  const [parkingTypes, setParkingTypes] = useState<AuctionParametersResponse[]>([]);
  const [transportTypes, setTransportTypes] = useState<AuctionParametersResponse[]>([]);
  const [roomCountType, setRoomCountType] = useState<AuctionParametersResponse[]>([]);
  //
  const [furnishedTypes, setFurnishedTypes] = useState<AuctionParametersResponse[]>([]);
  const [protectionTypes, setProtectionTypes] = useState<AuctionParametersResponse[]>([]);
  const [surroundingsTypes, setSurroundingsTypes] = useState<AuctionParametersResponse[]>([]);
  const [roadTypes, setRoadTypes] = useState<AuctionParametersResponse[]>([]);
  const [energyEfficiencyRatingTypes, setEnergyEfficiencyRatingTypes] = useState<AuctionParametersResponse[]>([]);
  const [telecommucationTypes, setTelecommucationTypes] = useState<AuctionParametersResponse[]>([]);
  const [internetConnectionTypes, setInternetConnectionTypes] = useState<AuctionParametersResponse[]>([]);
  const [wellTypes, setWellTypes] = useState<AuctionParametersResponse[]>([]);
  const [phaseDistributions, setPhaseDistributions] = useState<AuctionParametersResponse[]>([]);

  const furnishedTypeOptions = furnishedTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const protectionTypeOptions = protectionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const surroundingsTypeOptions = surroundingsTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const roadTypeOptions = roadTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const energyEfficiencyRatingTypeOptions = energyEfficiencyRatingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const telecommucationTypeOptions = telecommucationTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const houseTypeOptions = houseTypes.map((item) => ({
    value: item.id,
    label: item.title,
    mandatoryHouseNumber: item.mandatoryHouseNumber,
  }));
  const objectLocationTypeOptions = objectLocationTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const placementTypeOptions = placementTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const buildingConstructionTypeOptions = buildingConstructionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const objectStatusTypeOptions = objectStatusTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const heatingElementOptions = heatingElement.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const heatingSourceOptions = heatingSource.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const waterHeatSourceOptions = waterHeatSource.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const objectTypeOptions = houseObjectTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const circuitBreakerOptions = circuitBreaker.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const waterTypeOptions = waterTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const electricityTypeOptions = electricityTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wasteTypeOptions = wasteTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const gasTypeOptions = gasTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const parkingTypeOptions = parkingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const transportTypeOptions = transportTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const roomCountTypeOptions = roomCountType.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const internetConnectionTypeOptions = internetConnectionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wellTypeOptions = wellTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const phaseDistributionOptions = phaseDistributions.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  React.useEffect(() => {
    loadData();
    return () => {
      auctionParameterApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const houseTypesResponse = await auctionParameterApi.getRealEstateType(props.values.auctionCategory);
      setHouseTypes(houseTypesResponse.data.data);

      if (
        props.values.auctionParameterRealEstateHouse?.parameterRealEstateHouseType &&
        !~houseTypesResponse.data.data
          .map((v) => v.id)
          .indexOf(
            props.values.auctionParameterRealEstateHouse?.parameterRealEstateHouseType?.id ||
              props.values.auctionParameterRealEstateHouse?.parameterRealEstateHouseType
          )
      ) {
        props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateHouseType', undefined);
      }

      if (houseTypesResponse.data.data.length === 1) {
        props.setFieldValue(
          'auctionParameterRealEstateHouse.parameterRealEstateHouseType',
          houseTypesResponse.data.data[0].id
        );
      }

      const realEstateRes = await auctionParameterApi.getRealEstateAll();
      setPlacementTypes(realEstateRes.data.data.parameterRealEstatePlacement);
      setBuildingConstructionTypes(realEstateRes.data.data.parameterRealEstateBuildingConstruction);
      setObjectStatusTypes(realEstateRes.data.data.parameterRealEstateObjectStatus);
      setHeatingElement(realEstateRes.data.data.parameterRealEstateHeatingElement);
      setHeatingSource(realEstateRes.data.data.parameterRealEstateHeatingSource);
      setWaterHeatSource(realEstateRes.data.data.parameterRealEstateWaterHeatSource);
      setWaterTypes(realEstateRes.data.data.parameterRealEstateWater);
      setElectricityTypes(realEstateRes.data.data.parameterRealEstateElectricity);
      setWasteTypes(realEstateRes.data.data.parameterRealEstateWaste);
      setGasTypes(realEstateRes.data.data.parameterRealEstateGas);
      setParkingTypes(realEstateRes.data.data.parameterRealEstateParking);
      setTransportTypes(realEstateRes.data.data.parameterRealEstateTransport);
      setFurnishedTypes(realEstateRes.data.data.parameterRealEstateFurnished);
      setProtectionTypes(realEstateRes.data.data.parameterRealEstateProtection);
      setSurroundingsTypes(realEstateRes.data.data.parameterRealEstateSurroundingsType);
      setRoadTypes(realEstateRes.data.data.parameterRealEstateRoadType);
      setEnergyEfficiencyRatingTypes(realEstateRes.data.data.parameterRealEstateEnergyEfficiencyRating);
      setTelecommucationTypes(realEstateRes.data.data.parameterRealEstateTelecommunication);
      setCircuitBreaker(realEstateRes.data.data.parameterRealEstateCircuitBreaker);
      setInternetConnectionTypes(realEstateRes.data.data.parameterRealEstateInternetConnectionType);
      setWellTypes(realEstateRes.data.data.parameterRealEstateWellType);
      setPhaseDistributions(realEstateRes.data.data.parameterRealEstatePhaseDistribution);

      const realEstateHouseRes = await auctionParameterApi.getRealEstateHouseAll();
      setRoomCountType(realEstateHouseRes.data.data.parameterRealEstateHouseRoomCount);
      setHouseObjectTypes(realEstateHouseRes.data.data.parameterRealEstateHouseObjectType);
      setObjectLocationTypes(realEstateHouseRes.data.data.parameterRealEstateHouseLocationObject);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const handlerPhaseDistributionChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstatePhaseDistribution', itemValue?.value);
  };

  const handleRealEstateHouseWellTypesChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseWellTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseWellTypes',
      itemValue.map((i) => ({
        parameterRealEstateWellType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateWellType.id || f.parameterRealEstateWellType) === i.value
        )?.id,
      }))
    );
  };

  const handleInternetConnectionTypeChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseInternetConnectionTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseInternetConnectionTypes',
      itemValue.map((i) => ({
        parameterRealEstateInternetConnectionType: i.value,
        id: currentValues.find(
          (f: any) =>
            (f.parameterRealEstateInternetConnectionType.id || f.parameterRealEstateInternetConnectionType) === i.value
        )?.id,
      }))
    );
  };

  const handleHouseTypeChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateHouseType', itemValue?.value);
    props.setFieldValue('mandatoryHouseNumber', itemValue?.mandatoryHouseNumber ?? true);
  };

  const handleHouseObjectTypeChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateHouseObjectType', itemValue?.value);
  };

  const handleHouseLocationObjectChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateHouseLocationObject', itemValue?.value);
  };

  const handlePlacementChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstatePlacement', itemValue?.value);
  };

  const handleHouseBuildingConstructionChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateBuildingConstruction', itemValue?.value);
  };

  const handleHouseObjectStatusChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateObjectStatus', itemValue?.value);
  };

  const handleRoomCountChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateHouseRoomCount', itemValue?.value);
  };

  const handleRealEstateHouseHeatingElementChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseHeatingElements || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseHeatingElements',
      itemValue.map((i) => ({
        parameterRealEstateHeatingElement: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeatingElement.id || f.parameterRealEstateHeatingElement) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateHouseHeatingSourceChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseHeatingSources || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseHeatingSources',
      itemValue.map((i) => ({
        parameterRealEstateHeatingSource: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeatingSource.id || f.parameterRealEstateHeatingSource) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateHouseWaterHeatSourceChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseWaterHeatSources || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseWaterHeatSources',
      itemValue.map((i) => ({
        parameterRealEstateWaterHeatSource: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateWaterHeatSource.id || f.parameterRealEstateWaterHeatSource) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateWaterChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseWaters || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseWaters',
      itemValue.map((i) => ({
        parameterRealEstateWater: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateWater.id || f.parameterRealEstateWater) === i.value)
          ?.id,
      }))
    );
  };

  const handleRealEstateElectricityChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseElectricities || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseElectricities',
      itemValue.map((i) => ({
        parameterRealEstateElectricity: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateElectricity.id || f.parameterRealEstateElectricity) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateWasteChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseWastes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseWastes',
      itemValue.map((i) => ({
        parameterRealEstateWaste: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateWaste.id || f.parameterRealEstateWaste) === i.value)
          ?.id,
      }))
    );
  };

  const handleRealEstateGasChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseGases || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseGases',
      itemValue.map((i) => ({
        parameterRealEstateGas: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateGas.id || f.parameterRealEstateGas) === i.value)?.id,
      }))
    );
  };

  const handleHouseParkingChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateParking', itemValue?.value);
  };

  const handleTransportChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseTransports || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseTransports',
      itemValue.map((i) => ({
        parameterRealEstateTransport: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTransport.id || f.parameterRealEstateTransport) === i.value
        )?.id,
      }))
    );
  };

  const handleFurnishedChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateFurnished', itemValue?.value);
  };

  const handlerCircuitBreakerChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateCircuitBreaker', itemValue?.value);
  };

  const handleProtectionChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateProtection', itemValue?.value);
  };

  const handleSurroundingsTypeChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateSurroundingsType', itemValue?.value);
  };

  const handleRoadTypeChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseRoadTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseRoadTypes',
      itemValue.map((i) => ({
        parameterRealEstateRoadType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateRoadType.id || f.parameterRealEstateRoadType) === i.value
        )?.id,
      }))
    );
  };

  const handleEnergyEfficiencyRatingChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateHouse.parameterRealEstateEnergyEfficiencyRating', itemValue?.value);
  };

  const handleTelecommucationChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const currentValues =
      props.values.auctionParameterRealEstateHouse?.auctionParameterRealEstateHouseTelecommunications || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateHouse.auctionParameterRealEstateHouseTelecommunications',
      itemValue.map((i) => ({
        parameterRealEstateTelecommunication: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTelecommunication.id || f.parameterRealEstateTelecommunication) === i.value
        )?.id,
      }))
    );
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  return (
    <>
      <div className="pt-4 pb-3">
        <h3 className="mb-4 font-weight-normal">Základní informace</h3>
      </div>

      {houseTypeOptions.length > 1 && (
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-left">
            Typ nemovitosti{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateHouseType') ? ' *' : ''}
          </Form.Label>
          <div className="f-inline-control">
            <div className="w-max-500" data-test-id="parameterRealEstateHouseType">
              <Select
                isClearable
                required
                size="md"
                isDisabled={props.readOnly}
                name="parameterRealEstateHouseType"
                isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseType}
                options={houseTypeOptions}
                onChange={handleHouseTypeChange}
                value={
                  houseTypeOptions.find(
                    (i) =>
                      i.value ===
                      getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateHouseType)
                  ) || null
                }
              />
              {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseType && (
                <ControlFeedback type="invalid">
                  {props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseType as string}
                </ControlFeedback>
              )}
            </div>
          </div>
        </Form.Group>
      )}

      {/*/api/admin/parameter/real-estate/house/building-construction */}
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Budova{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateBuildingConstruction') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateBuildingConstruction">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateBuildingConstruction"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateBuildingConstruction}
              options={buildingConstructionTypeOptions}
              onChange={handleHouseBuildingConstructionChange}
              value={
                buildingConstructionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateBuildingConstruction)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateBuildingConstruction && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstateBuildingConstruction as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      {/*/api/admin/parameter/real-estate/house/object-status */}
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Stav{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateObjectStatus') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateObjectStatus">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateObjectStatus"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateObjectStatus}
              options={objectStatusTypeOptions}
              onChange={handleHouseObjectStatusChange}
              value={
                objectStatusTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateObjectStatus)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateObjectStatus && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstateObjectStatus as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ stavby{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateHouseObjectType') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHouseObjectType">
            <Select
              isClearable
              required
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateHouseObjectType"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseObjectType}
              options={objectTypeOptions}
              onChange={handleHouseObjectTypeChange}
              value={
                objectTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateHouseObjectType)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseObjectType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseObjectType as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Velikost{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateHouseRoomCount') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHouseRoomCount">
            <Select
              isClearable
              required
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateHouseRoomCount"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseRoomCount}
              options={roomCountTypeOptions}
              onChange={handleRoomCountChange}
              value={
                roomCountTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateHouseRoomCount)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseRoomCount && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseRoomCount as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <FormGroup
        type="number"
        label="Počet podlaží"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateHouse_numberOfFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateHouse.numberOfFloors"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'numberOfFloors')}
        value={props.values.auctionParameterRealEstateHouse.numberOfFloors}
        dataTestId="auctionParameterRealEstateHouse.numberOfFloors"
      />

      <FormGroup
        type="number"
        label="Počet podzemních podlaží"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateHouse_undergroundFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateHouse.undergroundFloors"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'undergroundFloors')}
        value={props.values.auctionParameterRealEstateHouse.undergroundFloors}
        dataTestId="auctionParameterRealEstateHouse.undergroundFloors"
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      {/* /api/admin/parameter/real-estate/house/location-object */}
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Poloha domu
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateHouseLocationObject') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHouseLocationObject">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateHouseLocationObject"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseLocationObject}
              options={objectLocationTypeOptions}
              onChange={handleHouseLocationObjectChange}
              value={
                objectLocationTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateHouseLocationObject)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseLocationObject && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstateHouseLocationObject as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <BooleanParameter
        label="Výtah"
        dataTestId="parameterRealEstateElevator"
        name="auctionParameterRealEstateHouse.elevator"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateHouse.elevator}
        errors={props.errors.auctionParameterRealEstateHouse_elevator}
        required={arrKeyExists(props.requiredParametersFields, 'elevator')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateHouse.elevator', val)}
      />

      <BooleanParameter
        label="Bezbariérový přístup"
        dataTestId="parameterRealEstateEasyAccess"
        name="auctionParameterRealEstateHouse.easyAccess"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateHouse.easyAccess}
        errors={props.errors.auctionParameterRealEstateHouse_easyAccess}
        required={arrKeyExists(props.requiredParametersFields, 'easyAccess')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateHouse.easyAccess', val)}
      />

      {/* /api/admin/parameter/real-estate/placement */}
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Umístění{arrKeyExists(props.requiredParametersFields, 'parameterRealEstatePlacement') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePlacement">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstatePlacement"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstatePlacement}
              options={placementTypeOptions}
              onChange={handlePlacementChange}
              value={
                placementTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstatePlacement)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstatePlacement && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstatePlacement as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Okolní zástavba
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateSurroundingsType') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateSurroundingsType">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateSurroundingsType"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateSurroundingsType}
              options={surroundingsTypeOptions}
              onChange={handleSurroundingsTypeChange}
              value={
                surroundingsTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateSurroundingsType)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateSurroundingsType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstateSurroundingsType as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Ochrana{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateProtection') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateProtection">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateProtection"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateProtection}
              options={protectionTypeOptions}
              onChange={handleProtectionChange}
              value={
                protectionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateProtection)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateProtection && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstateProtection as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Plochy</h3>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateHouse.usableArea"
          thousandSeparator
          label="Plocha užitná"
          error={props.errors.auctionParameterRealEstateHouse_usableArea as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'usableArea')}
          value={props.values.auctionParameterRealEstateHouse.usableArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateHouse.usableArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateHouse.usableArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateHouse.landArea"
          error={props.errors.auctionParameterRealEstateHouse_landArea as string}
          thousandSeparator
          label="Plocha pozemku"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'landArea')}
          value={props.values.auctionParameterRealEstateHouse.landArea}
          onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateHouse.landArea', parseInt(val.value))}
          dataTestId="auctionParameterRealEstateHouse.landArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateHouse.builtUpArea"
          thousandSeparator
          label="Plocha zastavěná"
          error={props.errors.auctionParameterRealEstateHouse_builtUpArea as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'builtUpArea')}
          value={props.values.auctionParameterRealEstateHouse.builtUpArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateHouse.builtUpArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateHouse.builtUpArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateHouse.gardenArea"
          error={props.errors.auctionParameterRealEstateHouse_gardenArea as string}
          thousandSeparator
          label="Plocha zahrady"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'gardenArea')}
          value={props.values.auctionParameterRealEstateHouse.gardenArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateHouse.gardenArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateHouse.gardenArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateHouse.floorArea"
          error={props.errors.auctionParameterRealEstateHouse_floorArea as string}
          thousandSeparator
          label="Plocha podlahová"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'floorArea')}
          value={props.values.auctionParameterRealEstateHouse.floorArea}
          onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateHouse.floorArea', parseInt(val.value))}
          dataTestId="auctionParameterRealEstateHouse.floorArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Vybavení</h3>
      </div>

      <BooleanParameter
        label="Bazén"
        className="mb-4"
        dataTestId="parameterRealEstateBasin"
        name="auctionParameterRealEstateHouse.basin"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateHouse.basin}
        errors={props.errors.auctionParameterRealEstateHouse_basin}
        required={arrKeyExists(props.requiredParametersFields, 'basin')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateHouse.basin', val)}
      />

      {props.values.auctionParameterRealEstateHouse.basin === 'yes' && (
        <Form.Group className="d-flex align-items-center">
          <div className="mr-2" data-test-id="auctionParameterRealEstateHouse.basin">
            <div className="d-flex align-items-center">
              <FormGroup
                name="auctionParameterRealEstateHouse.basinArea"
                thousandSeparator
                label="Plocha bazénu"
                error={props.errors.auctionParameterRealEstateHouse_basinArea as string}
                className="mt-0 mb-0"
                labelClassName="text-left"
                controlClassName="w-max-205"
                readOnly={props.readOnly}
                required={arrKeyExists(props.requiredParametersFields, 'basinArea')}
                value={props.values.auctionParameterRealEstateHouse.basinArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateHouse.basinArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateHouse.basinArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          </div>
        </Form.Group>
      )}

      <BooleanParameter
        label="Sklep"
        className="mb-4"
        dataTestId="parameterRealEstateCellar"
        name="auctionParameterRealEstateHouse.cellar"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateHouse.cellar}
        errors={props.errors.auctionParameterRealEstateHouse_cellar}
        required={arrKeyExists(props.requiredParametersFields, 'cellar')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateHouse.cellar', val)}
      />

      {props.values.auctionParameterRealEstateHouse.cellar === 'yes' && (
        <Form.Group className="d-flex align-items-center">
          <div className="mr-2" data-test-id="auctionParameterRealEstateHouse.cellar">
            <div className="d-flex align-items-center">
              <FormGroup
                name="auctionParameterRealEstateHouse.cellarArea"
                thousandSeparator
                label="Plocha sklepu"
                error={props.errors.auctionParameterRealEstateHouse_cellarArea as string}
                className="mt-0 mb-0"
                labelClassName="text-left"
                controlClassName="w-max-205"
                type="number"
                readOnly={props.readOnly}
                required={arrKeyExists(props.requiredParametersFields, 'cellarArea')}
                value={props.values.auctionParameterRealEstateHouse.cellarArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateHouse.cellarArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateHouse.cellarArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          </div>
        </Form.Group>
      )}

      <BooleanParameter
        label="Parkování"
        dataTestId="parameterRealEstateParkingExists"
        name="auctionParameterRealEstateHouse.parkingExists"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateHouse.parkingExists}
        errors={props.errors.auctionParameterRealEstateHouse_parkingExists}
        required={arrKeyExists(props.requiredParametersFields, 'parkingExists')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateHouse.parkingExists', val)}
      />

      {props.values.auctionParameterRealEstateHouse.parkingExists === 'yes' && (
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-left">Typ parkovacího místa *</Form.Label>
          <div className="f-inline-control">
            <div className="w-max-500" data-test-id="parameterRealEstateParking">
              <Select
                isClearable
                size="md"
                isDisabled={props.readOnly}
                name="parameterRealEstateParking"
                isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateParking}
                options={parkingTypeOptions}
                onChange={handleHouseParkingChange}
                value={
                  parkingTypeOptions.find(
                    (i) =>
                      i.value ===
                      getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateParking)
                  ) || null
                }
              />
              {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateParking && (
                <ControlFeedback type="invalid">
                  {props.errors.auctionParameterRealEstateHouse_parameterRealEstateParking as string}
                </ControlFeedback>
              )}
            </div>
          </div>
        </Form.Group>
      )}

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Termíny</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Nastěhování{arrKeyExists(props.requiredParametersFields, 'readyDate') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <DatePickerInput
            isNullable
            name="auctionParameterRealEstateHouse.readyDate"
            className={classNames([
              'form-control',
              'w-max-205',
              { 'is-invalid': !!props.errors.auctionParameterRealEstateHouse_readyDate },
            ])}
            value={
              !!props.values.auctionParameterRealEstateHouse.readyDate
                ? moment(props.values.auctionParameterRealEstateHouse.readyDate).toDate()
                : undefined
            }
            onChange={(val) =>
              props.setFieldValue(
                'auctionParameterRealEstateHouse.readyDate',
                val instanceof Date ? getRequestDateTimeFormat(val) : undefined
              )
            }
            data-test-id="auctionParameterRealEstateHouse.readyDate"
          />
          {!!props.errors.auctionParameterRealEstateHouse_readyDate && (
            <ControlFeedback type="invalid">
              {props.errors.auctionParameterRealEstateHouse_readyDate as string}
            </ControlFeedback>
          )}
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zahájení výstavby{arrKeyExists(props.requiredParametersFields, 'beginningDate') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <DatePickerInput
            isNullable
            name="auctionParameterRealEstateHouse.beginningDate"
            className={classNames([
              'form-control',
              'w-max-205',
              { 'is-invalid': !!props.errors.auctionParameterRealEstateHouse_beginningDate },
            ])}
            value={
              !!props.values.auctionParameterRealEstateHouse.beginningDate
                ? moment(props.values.auctionParameterRealEstateHouse.beginningDate).toDate()
                : undefined
            }
            onChange={(val) =>
              props.setFieldValue(
                'auctionParameterRealEstateHouse.beginningDate',
                val instanceof Date ? getRequestDateTimeFormat(val) : undefined
              )
            }
            data-test-id="auctionParameterRealEstateHouse.beginningDate"
          />
          {!!props.errors.auctionParameterRealEstateHouse_beginningDate && (
            <ControlFeedback type="invalid">
              {props.errors.auctionParameterRealEstateHouse_beginningDate as string}
            </ControlFeedback>
          )}
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Ukončení výstavby{arrKeyExists(props.requiredParametersFields, 'finishDate') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <DatePickerInput
            isNullable
            name="auctionParameterRealEstateHouse.finishDate"
            className={classNames([
              'form-control',
              'w-max-205',
              { 'is-invalid': !!props.errors.auctionParameterRealEstateHouse_finishDate },
            ])}
            value={
              !!props.values.auctionParameterRealEstateHouse.finishDate
                ? moment(props.values.auctionParameterRealEstateHouse.finishDate).toDate()
                : undefined
            }
            onChange={(val) =>
              props.setFieldValue(
                'auctionParameterRealEstateHouse.finishDate',
                val instanceof Date ? getRequestDateTimeFormat(val) : undefined
              )
            }
            data-test-id="auctionParameterRealEstateHouse.finishDate"
          />
          {!!props.errors.auctionParameterRealEstateHouse_finishDate && (
            <ControlFeedback type="invalid">
              {props.errors.auctionParameterRealEstateHouse_finishDate as string}
            </ControlFeedback>
          )}
        </div>
      </Form.Group>

      <FormGroup
        type="number"
        label="Rok výstavby"
        readOnly={props.readOnly}
        controlClassName="w-max-205"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateHouse_objectYear as string}
        name="auctionParameterRealEstateHouse.objectYear"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'objectYear')}
        value={props.values.auctionParameterRealEstateHouse.objectYear}
        dataTestId="auctionParameterRealEstateHouse.objectYear"
      />

      <FormGroup
        type="number"
        label="Rok kolaudace"
        labelClassName="text-left"
        controlClassName="w-max-205"
        name="auctionParameterRealEstateHouse.acceptanceYear"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateHouse_acceptanceYear as string}
        required={arrKeyExists(props.requiredParametersFields, 'acceptanceYear')}
        value={props.values.auctionParameterRealEstateHouse.acceptanceYear}
        onChange={props.handleChange}
        dataTestId="auctionParameterRealEstateHouse.acceptanceYear"
      />

      <FormGroup
        type="number"
        label="Rok rekonstrukce"
        controlClassName="w-max-205"
        labelClassName="text-left"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateHouse_reconstructionYear as string}
        name="auctionParameterRealEstateHouse.reconstructionYear"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'reconstructionYear')}
        value={props.values.auctionParameterRealEstateHouse.reconstructionYear}
        dataTestId="auctionParameterRealEstateHouse.reconstructionYear"
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Ostatní</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Třída energetické náročnosti
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateEnergyEfficiencyRating') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateEnergyEfficiencyRating">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateEnergyEfficiencyRating"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateEnergyEfficiencyRating}
              options={energyEfficiencyRatingTypeOptions}
              onChange={handleEnergyEfficiencyRatingChange}
              value={
                energyEfficiencyRatingTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateHouse.parameterRealEstateEnergyEfficiencyRating
                    )
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateEnergyEfficiencyRating && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstateEnergyEfficiencyRating as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj vody{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseWaters') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWater">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateWater"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWaters}
              options={waterTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWaterChange(val)}
              value={(props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseWaters || []).map(
                (v: { parameterRealEstateWater: string | number }) =>
                  waterTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWater))
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWaters && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWaters as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ studny
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseWellTypes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWellTypet">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateHouseWellTypes"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWellTypes}
              options={wellTypeOptions}
              onChange={(val: BaseType) => handleRealEstateHouseWellTypesChange(val)}
              value={(props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseWellTypes || []).map(
                (v: { parameterRealEstateWellType: string | number }) =>
                  wellTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWellType))
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWellTypes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWellTypes as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Topné těleso
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseHeatingElement') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHeatingElement">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateHouseHeatingElements"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseHeatingElements}
              options={heatingElementOptions}
              onChange={(val: BaseType) => handleRealEstateHouseHeatingElementChange(val)}
              value={(
                props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseHeatingElements || []
              ).map((v: { parameterRealEstateHeatingElement: string | number }) =>
                heatingElementOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeatingElement))
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseHeatingElements && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseHeatingElements as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj topení
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseHeatingSources') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHeatingSource">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateHouseHeatingSources"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseHeatingSources}
              options={heatingSourceOptions}
              onChange={(val: BaseType) => handleRealEstateHouseHeatingSourceChange(val)}
              value={(
                props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseHeatingSources || []
              ).map((v: { parameterRealEstateHeatingSource: string | number }) =>
                heatingSourceOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeatingSource))
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseHeatingSources && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseHeatingSources as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj teplé vody
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseWaterHeatSources') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWaterHeatSource">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateHouseWaterHeatSources"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWaterHeatSources}
              options={waterHeatSourceOptions}
              onChange={(val: BaseType) => handleRealEstateHouseWaterHeatSourceChange(val)}
              value={(
                props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseWaterHeatSources || []
              ).map((v: { parameterRealEstateWaterHeatSource: string | number }) =>
                waterHeatSourceOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaterHeatSource))
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWaterHeatSources && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWaterHeatSources as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Rozvod plynu{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseGases') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateHouseGases">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateHouseGases"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseGases}
              options={gasTypeOptions}
              onChange={(val: BaseType) => handleRealEstateGasChange(val)}
              value={(props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseGases || []).map(
                (v: { parameterRealEstateGas: string | number }) =>
                  gasTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateGas))
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseGases && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseGases as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ odpadu{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseWastes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWaste">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateWaste"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWastes}
              options={wasteTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWasteChange(val)}
              value={(props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseWastes || []).map(
                (v: { parameterRealEstateWaste: string | number }) =>
                  wasteTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaste))
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWastes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseWastes as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Příjezdová komunikace
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseRoadTypes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateRoadType">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateRoadType"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseRoadTypes}
              options={roadTypeOptions}
              onChange={(val: BaseType) => handleRoadTypeChange(val)}
              value={(props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseRoadTypes || []).map(
                (v: { parameterRealEstateRoadType: string | number }) =>
                  roadTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateRoadType))
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseRoadTypes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseRoadTypes as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Telekomunikace
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseTelecommunications')
            ? ' *'
            : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateHouseTelecommunications">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateHouseTelecommunications"
              isInvalid={
                !!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseTelecommunications
              }
              options={telecommucationTypeOptions}
              onChange={(val: BaseType) => handleTelecommucationChange(val)}
              value={(
                props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseTelecommunications || []
              ).map((v: { parameterRealEstateTelecommunication: string | number }) =>
                telecommucationTypeOptions.find(
                  (f) => f.value === getSelectValue(v.parameterRealEstateTelecommunication)
                )
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseTelecommunications && (
              <ControlFeedback type="invalid">
                {
                  props.errors
                    .auctionParameterRealEstateHouse_auctionParameterRealEstateHouseTelecommunications as string
                }
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Elektřina
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseElectricities') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateElectricity">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateElectricity"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseElectricities}
              options={electricityTypeOptions}
              onChange={(val: BaseType) => handleRealEstateElectricityChange(val)}
              value={(
                props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseElectricities || []
              ).map((v: { parameterRealEstateElectricity: string | number }) =>
                electricityTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateElectricity))
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseElectricities && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseElectricities as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Doprava{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseTransports') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateHouseTransports">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateHouseTransports"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseTransports}
              options={transportTypeOptions}
              onChange={(val: BaseType) => handleTransportChange(val)}
              value={(props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseTransports || []).map(
                (v: { parameterRealEstateTransport: string | number }) =>
                  transportTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateTransport))
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseTransports && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseTransports as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <BooleanParameter
        label="Fotovoltaika"
        dataTestId="auctionParameterRealEstateHouse.ftvPanels"
        name="auctionParameterRealEstateHouse.ftvPanels"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateHouse?.ftvPanels}
        errors={props.errors.auctionParameterRealEstateHouse_ftvPanels}
        required={arrKeyExists(props.requiredParametersFields, 'ftvPanels')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateHouse.ftvPanels', val)}
      />

      <BooleanParameter
        label="Solární panely"
        dataTestId="auctionParameterRealEstateHouse.solarPanels"
        name="auctionParameterRealEstateHouse.solarPanels"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateHouse?.solarPanels}
        errors={props.errors.auctionParameterRealEstateHouse_solarPanels}
        required={arrKeyExists(props.requiredParametersFields, 'solarPanels')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateHouse.solarPanels', val)}
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <FormGroup
        type="text"
        label="Poskytovatel innternetového připojení"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateHouse.internetConnectionProvider"
        value={props.values.auctionParameterRealEstateHouse.internetConnectionProvider}
        error={props.errors.auctionParameterRealEstateHouse_internetConnectionProvider as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'internetConnectionProvider')}
        dataTestId="auctionParameterRealEstateHouse.internetConnectionProvider"
      />

      <FormGroup
        type="number"
        label="Rychlost internetového připojení (Mbit/s)"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateHouse.internetConnectionSpeed"
        value={props.values.auctionParameterRealEstateHouse.internetConnectionSpeed}
        error={props.errors.auctionParameterRealEstateHouse_internetConnectionSpeed as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'internetConnectionSpeed')}
        dataTestId="auctionParameterRealEstateHouse.internetConnectionSpeed"
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ internetového připojení
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateHouseInternetConnectionTypes')
            ? ' *'
            : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateHouseInternetConnectionTypes">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateHouseInternetConnectionTypes"
              isInvalid={
                !!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseInternetConnectionTypes
              }
              options={internetConnectionTypeOptions}
              onChange={(val: BaseType) => handleInternetConnectionTypeChange(val)}
              value={(
                props.values.auctionParameterRealEstateHouse.auctionParameterRealEstateHouseInternetConnectionTypes ||
                []
              ).map((v: { parameterRealEstateInternetConnectionType: string | number }) =>
                internetConnectionTypeOptions.find(
                  (f) => f.value === getSelectValue(v.parameterRealEstateInternetConnectionType)
                )
              )}
            />
            {!!props.errors.auctionParameterRealEstateHouse_auctionParameterRealEstateHouseInternetConnectionTypes && (
              <ControlFeedback type="invalid">
                {
                  props.errors
                    .auctionParameterRealEstateHouse_auctionParameterRealEstateHouseInternetConnectionTypes as string
                }
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          thousandSeparator
          name="auctionParameterRealEstateHouse.ceilingHeight"
          label="Výška stropu"
          error={props.errors.auctionParameterRealEstateHouse_ceilingHeight as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'ceilingHeight')}
          value={props.values.auctionParameterRealEstateHouse.ceilingHeight}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateHouse.ceilingHeight', parseFloat(val.value))
          }
          dataTestId="auctionParameterRealEstateHouse.ceilingHeight"
        />
        <span className="f-size-12 f-weight-400">m</span>
      </div>

      <FormGroup
        readOnly={props.readOnly}
        label="Náklady na bydlení"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateHouse_costOfLiving as string}
        name="auctionParameterRealEstateHouse.costOfLiving"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'costOfLiving')}
        value={props.values.auctionParameterRealEstateHouse.costOfLiving}
        dataTestId="auctionParameterRealEstateHouse.costOfLiving"
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Vybavení{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateFurnished') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateFurnished">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateFurnished"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateFurnished}
              options={furnishedTypeOptions}
              onChange={handleFurnishedChange}
              value={
                furnishedTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateFurnished)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateFurnished && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstateFurnished as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Jističe{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateCircuitBreaker') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateCircuitBreaker">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateCircuitBreaker"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateCircuitBreaker}
              options={circuitBreakerOptions}
              onChange={handlerCircuitBreakerChange}
              value={
                circuitBreakerOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstateCircuitBreaker)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstateCircuitBreaker && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstateCircuitBreaker as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Počet fází{arrKeyExists(props.requiredParametersFields, 'parameterRealEstatePhaseDistribution') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePhaseDistribution">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstatePhaseDistribution"
              isInvalid={!!props.errors.auctionParameterRealEstateHouse_parameterRealEstatePhaseDistribution}
              options={phaseDistributionOptions}
              onChange={handlerPhaseDistributionChange}
              value={
                phaseDistributionOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateHouse.parameterRealEstatePhaseDistribution)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateHouse_parameterRealEstatePhaseDistribution && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateHouse_parameterRealEstatePhaseDistribution as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <FormGroup
        type="text"
        label="Klíčová slova (oddělená středníkem)"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateHouse.keywords"
        value={props.values.auctionParameterRealEstateHouse.keywords}
        error={props.errors.auctionParameterRealEstateHouse_keywords as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'keywords')}
        dataTestId="auctionParameterRealEstateHouse.keywords"
      />

      <div className="pb-3">
        <hr />
      </div>
    </>
  );
};
