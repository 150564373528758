import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { Auctioneer, UserPermission } from '@types';
import * as routes from '@routes';
import { useAuctioneersApi } from '@api/auctioneers';
import { urlSearchStore, useCurrentUserRoleChecks, usePageStore, useUrlSearchStore } from '@stores';
import { BasePage, Pagination, BasePreloader, AdminBoxContent, Button, Visible } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import ItemsTable from './components/ItemsTable';
import FiltersForm from './components/FiltersForm';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';

const AuctioneerListPage: React.FC = () => {
  const navigate = useNavigate();
  const auctioneersApi = useAuctioneersApi();
  const location = useLocation();
  const pageState = usePageStore();
  const urlSearchState = useUrlSearchStore();
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState([] as Array<Auctioneer>);
  const [dataLen, setDataLen] = React.useState(0);
  const defaultQuery = React.useMemo(
    () => ({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 15),
      ic: withDefault(StringParam, undefined),
      email: withDefault(StringParam, undefined),
      auctioneerName: withDefault(StringParam, undefined),
      type: withDefault(StringParam, undefined),
      state: withDefault(StringParam, undefined),
      testDateFrom: withDefault(StringParam, undefined),
      testDateTo: withDefault(StringParam, undefined),
      testDate: withDefault(BooleanParam, false),
      invoice: withDefault(BooleanParam, false),
      noInvoice: withDefault(BooleanParam, false),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);
  const { isOfAnyStaffRole } = useCurrentUserRoleChecks();
  const page = pageState.getPage(routes.admin.AUCTIONEER_LIST) || pageState.getPage(routes.admin.MY_COMPANY_LIST);

  React.useEffect(() => {
    if (isOfAnyStaffRole()) {
      if (urlSearchState.adminAuctioneerList) {
        navigate(`${pageState.getPagePath(routes.admin.AUCTIONEER_LIST)}${urlSearchState.adminAuctioneerList}`);
      } else if (!query.state) {
        setQuery({ state: 'active' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    loadData();
    urlSearchStore.setState({ adminAuctioneerList: location.search });
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, query]);

  const loadData = async () => {
    setLoaded(false);
    try {
      const response = await auctioneersApi.list(query);
      setData(response.data.data);
      setDataLen(response.data.total as number);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const handleDeleteClick = (item: Auctioneer) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctioneersApi.delete(item.id.toString()).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const handleRestoreClick = (item: Auctioneer) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete obnovit tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctioneersApi.restore(item.id.toString()).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  return (
    <BasePage
      page={
        !!pageState.getPage(routes.admin.AUCTIONEER_LIST) ? routes.admin.AUCTIONEER_LIST : routes.admin.MY_COMPANY_LIST
      }
      title={page?.name}
    >
      {!!pageState.getPage(routes.admin.AUCTIONEER_LIST) && (
        <FiltersForm values={query} onSubmit={(values) => setQuery(values, 'push')} />
      )}
      <AdminBoxContent>
        <Row className="align-items-center">
          <Col xs={12} lg={6}>
            <Visible permissionName={UserPermission.canCreate}>
              <Button
                to={pageState.getPagePath(routes.admin.AUCTIONEER_CREATE)}
                dataTestId="admin-auctioneer-create-btn"
              >
                <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                Přidat dražebníka
              </Button>
            </Visible>
          </Col>
          <Col xs={12} lg={6} className="text-left text-lg-right mt-4 mt-lg-0">
            {loaded && !!pageState.getPage(routes.admin.AUCTIONEER_LIST) && (
              <p className="m-0">
                Celkem <strong>{dataLen}</strong> zaregistrovaných subjektů
              </p>
            )}
          </Col>
        </Row>
        <Row className="mt-3 pt-3">
          <Col xs={12}>
            {loaded ? (
              <div>
                <div>
                  <ItemsTable data={data} onDeleteClick={handleDeleteClick} onRestoreClick={handleRestoreClick} />
                </div>
                {data.length > 0 && (
                  <div className="mt-2">
                    <Pagination
                      page={query.page}
                      perPage={query.perPage}
                      totalRows={dataLen}
                      onPageChange={(page) => setQuery({ ...query, page: page }, 'push')}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                <BasePreloader />
              </div>
            )}
          </Col>
        </Row>
      </AdminBoxContent>
    </BasePage>
  );
};

export default AuctioneerListPage;
