import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { BaseAdmin, SEO } from '@components';
import Admin404Page from './pages/admin/Admin404Page/Admin404Page';
import AuctioneerCreatePage from './pages/admin/AuctioneerCreatePage/AuctioneerCreatePage';
import AuctioneerListPage from './pages/admin/AuctioneerListPage/AuctioneerListPage';
import UserListPage from './pages/admin/UserListPage/UserListPage';
import UserUpdatePage from './pages/admin/UserUpdatePage/UserUpdatePage';
import SettingsPage from './pages/admin/SettingsPage/SettingsPage';
import AuctionsListPage from './pages/admin/AuctionsListPage/AuctionsListPage';
import AuctionsUpdatePage from './pages/admin/AuctionsUpdatePage/AuctionsUpdatePage';
import ActiveAuctionPage from './pages/admin/ActiveAuctionPage/ActiveAuctionPage';
import DocumentsPage from './pages/admin/DocumentsPage/DocumentsPage';
import LogListPage from './pages/admin/LogListPage/LogListPage';
import UserDocumentsPage from './pages/admin/UserDocumentsPage/UserDocumentsPage';
import InvoicesPage from './pages/admin/InvoicesPage/InvoicesPage';
import StatisticPage from './pages/admin/StatisticsPage/StatisticsPage';
import DocumentsAndSecurityPage from './pages/admin/DocumentsAndSecurityPage/DocumentsAndSecurityPage';
import { InvoiceUpdatePage } from './pages/admin/InvoiceUpdatePage';

const AdminRoutes: React.FC = () => {
  const pageState = usePageStore();

  return (
    <BaseAdmin>
      <SEO />
      <Routes>
        {!!pageState.getPage(routes.admin.AUCTIONEER_LIST) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.AUCTIONEER_LIST, routes.admin.BASE)}
            element={<AuctioneerListPage />}
          />
        )}
        {!!pageState.getPage(routes.admin.AUCTIONEER_CREATE) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.AUCTIONEER_CREATE, routes.admin.BASE)}
            element={<AuctioneerCreatePage />}
          />
        )}
        {!!pageState.getPage(routes.admin.AUCTIONEER_UPDATE) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.AUCTIONEER_UPDATE, routes.admin.BASE)}
            element={<AuctioneerCreatePage />}
          />
        )}
        {!!pageState.getPage(routes.admin.MY_COMPANY_LIST) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.MY_COMPANY_LIST, routes.admin.BASE)}
            element={<AuctioneerListPage />}
          />
        )}
        {!!pageState.getPage(routes.admin.MY_COMPANY_UPDATE) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.MY_COMPANY_UPDATE, routes.admin.BASE)}
            element={<AuctioneerCreatePage />}
          />
        )}
        {!!pageState.getPage(routes.admin.USER_LIST) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.USER_LIST, routes.admin.BASE)}
            element={<UserListPage />}
          />
        )}
        {!!pageState.getPage(routes.admin.USER_CREATE) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.USER_CREATE, routes.admin.BASE)}
            element={<UserUpdatePage />}
          />
        )}
        {!!pageState.getPage(routes.admin.USER_DETAIL) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.USER_DETAIL, routes.admin.BASE)}
            element={<UserUpdatePage readOnly={true} />}
          />
        )}
        {!!pageState.getPage(routes.admin.USER_UPDATE) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.USER_UPDATE, routes.admin.BASE)}
            element={<UserUpdatePage />}
          />
        )}
        {!!pageState.getPage(routes.admin.USER_DOCUMENTS) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.USER_DOCUMENTS, routes.admin.BASE)}
            element={<UserDocumentsPage />}
          />
        )}
        {!!pageState.getPage(routes.admin.DOCUMENTS_AND_SECURITY) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.DOCUMENTS_AND_SECURITY, routes.admin.BASE)}
            element={<DocumentsAndSecurityPage />}
          />
        )}
        {!!pageState.getPage(routes.admin.AUCTIONS_LIST) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.AUCTIONS_LIST, routes.admin.BASE)}
            element={<AuctionsListPage />}
          />
        )}
        {!!pageState.getPage(routes.admin.AUCTIONS_CREATE) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.AUCTIONS_CREATE, routes.admin.BASE)}
            element={<AuctionsUpdatePage />}
          />
        )}
        {!!pageState.getPage(routes.admin.AUCTIONS_UPDATE) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.AUCTIONS_UPDATE, routes.admin.BASE)}
            element={<AuctionsUpdatePage />}
          />
        )}
        {!!pageState.getPage(routes.admin.AUCTIONS_ACTIVE_DETAIL) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.AUCTIONS_ACTIVE_DETAIL, routes.admin.BASE)}
            element={<ActiveAuctionPage />}
          />
        )}
        {!!pageState.getPage(routes.admin.INVOICES) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.INVOICES, routes.admin.BASE)}
            element={<InvoicesPage />}
          />
        )}
        {!!pageState.getPage(routes.admin.INVOICES_UPDATE) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.INVOICES_UPDATE, routes.admin.BASE)}
            element={<InvoiceUpdatePage />}
          />
        )}
        {!!pageState.getPage(routes.admin.CASES) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.CASES, routes.admin.BASE)}
            element={<Admin404Page page={routes.admin.CASES} />}
          />
        )}
        {!!pageState.getPage(routes.admin.DOCUMENTS) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.DOCUMENTS, routes.admin.BASE)}
            element={<DocumentsPage />}
          />
        )}
        {!!pageState.getPage(routes.admin.SETTINGS) && (
          <Route
            path={`${pageState.getChildPagePath(routes.admin.SETTINGS, routes.admin.BASE)}/*`}
            element={<SettingsPage />}
          />
        )}
        {!!pageState.getPage(routes.admin.LOG) && (
          <Route path={pageState.getChildPagePath(routes.admin.LOG, routes.admin.BASE)} element={<LogListPage />} />
        )}
        {!!pageState.getPage(routes.admin.STATISTICS) && (
          <Route
            path={pageState.getChildPagePath(routes.admin.STATISTICS, routes.admin.BASE)}
            element={<StatisticPage />}
          />
        )}
        {/* Redirect to AUCTIONS_LIST if no route matched */}
        <Route path="*" element={<Navigate to={pageState.getPagePath(routes.admin.AUCTIONS_LIST)} />} />
      </Routes>
    </BaseAdmin>
  );
};

export default AdminRoutes;
