import React from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import closeIcon from '@assets/images/front/icons/login-close.svg';

export interface ModalProps {
  title: string;
  isOpen: boolean;
  className?: string;
  noPadding?: boolean;
  hideClose?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  onRequestClose?: (event?: React.MouseEvent | React.KeyboardEvent) => void;
  onAfterClose?: () => void;
  onAfterOpen?: () => void;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = (props) => {
  return (
    // @ts-ignore
    <ReactModal
      isOpen={props.isOpen}
      onAfterClose={props.onAfterClose}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      className={classNames(['modal-content', { 'modal-no-padding': props.noPadding }, props.className])}
      overlayClassName="modal-overlay"
    >
      <div className="modal-item">
        <div className="modal-header">
          <p>{props.title}</p>
          {!props.hideClose && (
            <span onClick={props.onRequestClose}>
              <img src={closeIcon} alt="icon" />
            </span>
          )}
        </div>
        <div className="modal-item-content">{props.children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;
