import React from 'react';

import { BaseFrontPage, AccountSidebarMenu } from '@components';

interface BaseAccountPageProps {
  page?: string;
  className?: string;
  disableScrollToTop?: boolean;
  children?: React.ReactNode;
}

const BaseAccountPage: React.FC<BaseAccountPageProps> = (props) => {
  return (
    <BaseFrontPage
      page={props.page}
      className={props.className}
      sidebarItems={[<AccountSidebarMenu />]}
      disableScrollToTop={props.disableScrollToTop}
    >
      <div className="account-page">{props.children}</div>
    </BaseFrontPage>
  );
};

export default BaseAccountPage;
