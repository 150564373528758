import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import clx from 'classnames';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { applicationStore } from '@stores';
import { fileToBase64 } from '@helpers/files';
import { confirmMessage } from '@helpers/messages';
import { getFormatBytes } from '@helpers/formats';
import { useAuctionInformationApi } from '@api/auctionInformation';
import { getRequestDateFormat, getRequestDateTimeFormat } from '@helpers/datetime';
import { BasePreloader, Button, ControlFeedback, DatePickerInput, FormGroup } from '@components';
import {
  AuctionInformationResponse,
  AuctionInformationRequest,
  BaseObjectType,
  AuctionInformationDocumentResponse,
} from '@types';

import AuctionInformationAddDocument from './AuctionInformationAddDocument';
import AuctionInformationDocumentsTable from './AuctionInformationDocumentsTable';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

interface Props {
  id: string;
  formType: string;
  item?: AuctionInformationResponse;
  auctionType: 'auction' | 'auction_public';
  onClose: (item?: AuctionInformationResponse) => void;
}

interface FormValues extends AuctionInformationRequest {
  dateHour: string;
  dateMinute: string;
}

const InformationForm: React.FC<Props> = (props) => {
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [savedFile, setSavedFile] = React.useState(true);
  const [timeTypes, setTimeTypes] = React.useState<BaseObjectType<string>[]>([]);
  const auctionInformationApi = useAuctionInformationApi();
  const [documentsLoaded, setDocumentsLoaded] = React.useState<boolean>(false);
  const [documents, setDocuments] = React.useState<AuctionInformationDocumentResponse[]>([]);
  const formik = useFormik<FormValues>({
    onSubmit: () => handleSubmit(),
    initialValues: {
      title: props.item?.title || '',
      content: props.item?.content || '',
      position: !!props.item?.position ? props.item.position.toString() : '',
      showTimeType: props.item?.showTimeType || '',
      date: !!props.item?.date ? moment(props.item.date).format('YYYY-MM-DD') : '',
      dateHour: !!props.item?.date ? moment(props.item.date).format('H') : '0',
      dateMinute: !!props.item?.date ? moment(props.item.date).format('m') : '0',
      category: props.formType,
    },
  });

  React.useEffect(() => {
    loadTimeTypes();
    loadDocuments();
    return () => auctionInformationApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item?.id]);

  const loadDocuments = async () => {
    if (!props.item) {
      return;
    }
    try {
      const response = await auctionInformationApi.getAdminDocuments(props.id, props.item.id);
      setDocuments(response.data.data);
      setDocumentsLoaded(true);
    } catch (err) {
      if (auctionInformationApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const loadTimeTypes = async () => {
    try {
      const response = await auctionInformationApi.getTimeTypes(props.auctionType);
      setTimeTypes(response.data.data);
      if (!props.item?.showTimeType && response.data.data.length > 0) {
        formik.setFieldValue('showTimeType', Object.keys(response.data.data[0])[0]);
      }
    } catch (err) {
      if (auctionInformationApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const saveFile = async (itemId: string | number) => {
    const maxPostBodySize = applicationStore.getState().systemInfo?.post_max_size;
    if (!!file && !!maxPostBodySize && maxPostBodySize < file.size) {
      toast.error(`Soubor ${file.name} nesmí být větší než ${getFormatBytes(maxPostBodySize)}`);
      return;
    }

    setSavedFile(false);
    try {
      const fileBase64 = await fileToBase64(file as File);
      const data = {
        type: 'file',
        data: fileBase64,
        mime: file?.type as string,
        original_name: file?.name as string,
        title: file?.name as string,
      };
      await auctionInformationApi.createDocument(props.id, itemId, data);
      setFile(undefined);
      setSavedFile(true);
      if (!!props.item) {
        loadDocuments();
      }
    } catch (err) {
      if (!err.response) {
        return;
      }
      setSavedFile(true);
    }
  };

  const handleSubmit = async () => {
    try {
      const data = {
        title: formik.values.title,
        content: formik.values.content,
        position: formik.values.position,
        showTimeType: formik.values.showTimeType,
        category: formik.values.category,
        categoryKey: props.item?.categoryKey || undefined,
        date: !!formik.values.date
          ? getRequestDateTimeFormat(`${formik.values.date} ${formik.values.dateHour}:${formik.values.dateMinute}`)
          : '',
      };
      if (!!props.item) {
        await auctionInformationApi.adminUpdate(props.id, props.item.id, data);
      } else {
        const response = await auctionInformationApi.adminCreate(props.id, data);
        if (!!file) {
          await saveFile(response.data.data.id);
        }
        props.onClose(response.data.data);
      }
    } catch (err) {
      if (auctionInformationApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    }
  };

  const handleTimeFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    if (String(_.get(formik.values, fieldName, '0')) === '0') {
      formik.setFieldValue(fieldName, '');
    }
  };

  const handleTimeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    if (/^\s*$/.test(_.get(formik.values, fieldName, '0'))) {
      formik.setFieldValue(fieldName, '0');
    }
  };

  const handleMaxNumberChange = (e: React.ChangeEvent<HTMLInputElement>, max: number) => {
    if (!!e.target.value && parseInt(e.target.value) > max) {
      e.target.value = max.toString();
    }
    formik.handleChange(e);
  };

  const handleDocumentDeleteClick = (document: AuctionInformationDocumentResponse) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise(async (resolve) => {
          try {
            await auctionInformationApi.deleteDocument(props.id, props.item?.id || '', document.id);
            await loadDocuments();
            resolve(true);
          } catch {
            resolve(true);
          }
        });
      },
    });
  };

  const getTitle = () => {
    switch (props.formType) {
      case 'a':
        return 'Informace typ A - (v průběhu a na začátku)';
      case 'b':
        return 'Informace typ B - (na konci)';
      case 'c':
        return 'Informace typ C - (automatické možnosti na konci vydraženo/nevydraženo)';
      default:
        return undefined;
    }
  };

  return (
    <div>
      <div className="pl-2">
        <h2 className="f-weight-300 f-size-25">{getTitle() || '-'}</h2>
        <Form className="mt-40" onSubmit={formik.handleSubmit}>
          <div className="responsive-table-content">
            <div className="w-min-600 pt-2">
              <FormGroup
                required
                name="title"
                label="Název"
                labelClassName="text-left"
                error={formik.errors.title}
                value={formik.values.title}
                onChange={formik.handleChange}
              />
              <Wysiwyg
                name="content"
                label="Text"
                error={formik.errors.content as string}
                value={formik.values.content}
                onChange={(val) => formik.setFieldValue('content', val)}
              />
              {props.formType !== 'c' && (
                <>
                  <FormGroup
                    required
                    name="position"
                    type="number"
                    label="Pořadí"
                    labelClassName="text-left"
                    controlClassName="w-max-140"
                    error={formik.errors.position}
                    value={formik.values.position}
                    onChange={formik.handleChange}
                  />
                </>
              )}
              {props.formType === 'a' && (
                <>
                  <div className="pl-1 mb-4">
                    {timeTypes.map((item, index) => (
                      <div key={`time-type-${index}`} className="mb-3">
                        <Form.Check
                          custom
                          type="radio"
                          id={`time-type-${index}`}
                          name="showTimeType"
                          className="radio-point"
                          value={Object.keys(item)[0]}
                          label={item[Object.keys(item)[0]]}
                          checked={Object.keys(item)[0] === formik.values.showTimeType}
                          onChange={formik.handleChange}
                        />
                      </div>
                    ))}
                  </div>
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label">Datum</Form.Label>
                    <div className="f-inline-control d-flex align-items-center datetime-feedback">
                      <div>
                        <DatePickerInput
                          readOnly={formik.values.showTimeType !== 'specific_time'}
                          name="date"
                          className={clx(['form-control', 'w-max-140', { 'is-invalid': !!formik.errors.date }])}
                          calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                          clearIcon={null}
                          onChange={(val) => handleDateChange('date', val)}
                          value={!!formik.values.date ? moment(formik.values.date).toDate() : undefined}
                        />
                      </div>
                      <span className="f-size-12 w-weight-400 ml-3 mr-3">v</span>
                      <FormGroup
                        readOnly={formik.values.showTimeType !== 'specific_time'}
                        type="number"
                        name="dateHour"
                        controlOnly={true}
                        controlClassName="w-max-70 text-center"
                        value={formik.values.dateHour}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaxNumberChange(e, 23)}
                        onFocus={handleTimeFocus}
                        onBlur={handleTimeBlur}
                      />
                      <span className="f-size-12 w-weight-400 ml-3 mr-3">:</span>
                      <FormGroup
                        readOnly={formik.values.showTimeType !== 'specific_time'}
                        type="number"
                        name="dateMinute"
                        controlOnly={true}
                        controlClassName="w-max-70 text-center"
                        value={formik.values.dateMinute}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaxNumberChange(e, 59)}
                        onFocus={handleTimeFocus}
                        onBlur={handleTimeBlur}
                      />
                      <span className="f-size-12 w-weight-400 ml-3 mr-3">hod</span>
                      {!!formik.errors.date && <ControlFeedback type="invalid">{formik.errors.date}</ControlFeedback>}
                    </div>
                  </Form.Group>
                </>
              )}
            </div>
          </div>

          {props.formType !== 'c' && (
            <div>
              <AuctionInformationAddDocument
                isDetail={!!props.item}
                saved={savedFile}
                file={file}
                onFileChange={setFile}
                onSave={() => saveFile(props.item?.id || '')}
              />
              {!!props.item && (
                <>
                  {documentsLoaded ? (
                    <AuctionInformationDocumentsTable
                      data={documents}
                      auctionId={props.id}
                      informationId={props.item?.id || ''}
                      onDeleteClick={handleDocumentDeleteClick}
                    />
                  ) : (
                    <div className="pl-2 pb-3">
                      <BasePreloader size={30} />
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          <div className="pt-3 d-flex flex-wrap justify-content-between">
            <Button type="button" onClick={() => props.onClose()}>
              Zpět na seznam
            </Button>
            <div>{formik.isSubmitting ? <BasePreloader /> : <Button type="submit">Uložit</Button>}</div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InformationForm;
