import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import * as routes from '@routes';
import { Button } from '@components';
import BaseFrontSidebarBox from './BaseFrontSidebarBox';
import LoginModal from '@components/LoginModal/LoginModal';
import { pageStore, useAuthFrontStore, usePageStore } from '@stores';

interface Props {
  title: string;
}

const FrontSidebarNotifications: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { isLogged } = useAuthFrontStore();
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const pageState = usePageStore();

  const handleLoginClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowLoginModal(true);
  };

  const handleSetupClick = () => {
    if (isLogged) {
      navigate(pageState.getPagePath(routes.front.ACCOUNT_DOG));
    } else {
      setShowLoginModal(true);
    }
  };

  const handleSuccessLogin = () => {
    setShowLoginModal(false);
    const page = pageStore.getState().pages.find((i) => i.routeName === routes.front.ACCOUNT_DOG);
    if (page) {
      navigate(page.url);
    }
  };

  return (
    <>
      <LoginModal
        title="Přihlášení"
        isOpen={showLoginModal}
        onRequestClose={() => setShowLoginModal(false)}
        onSuccess={() => handleSuccessLogin()}
      />
      <BaseFrontSidebarBox title={props.title} className="sidebar-notifications">
        <p>Jakmile se objeví v databázi dražba dle vašich parametrů, zašleme vám upozornění.</p>
        {!isLogged && (
          <p>
            Je třeba se{' '}
            <Link className="f-weight-700 text-underline" to={pageState.getPagePath(routes.front.REGISTRATION)}>
              registrovat
            </Link>
            <br />
            nebo se{' '}
            <a href="/" onClick={handleLoginClick} className="f-weight-700 text-underline">
              přihlásit
            </a>
          </p>
        )}
        <Button type="button" variant="btn-outline-primary" className="mt-3" onClick={handleSetupClick}>
          Nastavit nyní
        </Button>
      </BaseFrontSidebarBox>
    </>
  );
};

export default FrontSidebarNotifications;
