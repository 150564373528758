import { JSX } from 'react/jsx-runtime';
import { useEffect, useState } from 'react';

import { usePagesApi } from '@api/pages';
import { BasePreloader, Button } from '@components';
import { usePageStore } from '@stores';
import { PageTextBlockType, PageType } from '@types';

type HintSubPageContentProps = {
  page: PageType;
};

export const HintSubPageContent = (props: HintSubPageContentProps) => {
  const { page } = props;
  const pageState = usePageStore();
  const pagesApi = usePagesApi();
  const [loaded, setLoaded] = useState(true);
  const [textBlocks, setTextBlock] = useState<PageTextBlockType[]>([]);
  const backUrl = pageState.pages.find((item) => item.id === page.parentPageId)?.fullUrl;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (page) {
      loadTextBlocks(page.id);
    }
    return () => {
      pagesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadTextBlocks = async (pageId: number) => {
    setLoaded(false);
    try {
      const response = await pagesApi.listTextBlocks(pageId);
      const data = response.data.data;
      setTextBlock(data);
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
    }
    setLoaded(true);
  };

  const renderTextBlock = (textBlocks: PageTextBlockType[]): JSX.Element => {
    return (
      <>
        {textBlocks.map((textBlock) => {
          return <div key={textBlock.id} dangerouslySetInnerHTML={{ __html: textBlock.content }} />;
        })}
      </>
    );
  };

  return (
    <div className="component-front-hint">
      <h1>{page?.name || ''}</h1>
      <div className="hint-wrapper">
        <div className="hint-content">{loaded ? renderTextBlock(textBlocks) : <BasePreloader />}</div>

        <div className="action-holder">
          <Button type="button" variant="btn-outline-primary" to={backUrl}>
            Vrátit se zpět
          </Button>
        </div>
      </div>
    </div>
  );
};
