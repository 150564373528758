import { OnChangeValue } from 'react-select';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { useEnumApi } from '@api/enum';
import { usePageStore } from '@stores';
import { useAuctionsApi } from '@api/auctions';
import { Select, AdminFiltersBox } from '@components';
import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';
import { AuctionUsersFilters, AuctionAuthorizationType, AuctionStatusResponse } from '@types';

type SelectOptionType = {
  value: string;
  label: string;
};

const FiltersForm: React.FC = () => {
  const location = useLocation();
  const pageState = usePageStore();
  const enumApi = useEnumApi();
  const auctionsApi = useAuctionsApi();
  const [auctionStatus, setAuctionStatus] = useState<Array<AuctionStatusResponse>>([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [auctionAuthorizationTypes, setAuctionAuthorizationTypes] = React.useState<AuctionAuthorizationType[]>([]);
  const formik = useFormik<AuctionUsersFilters>({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: (values) => handleSubmit(values),
  });
  const defaultQuery = React.useMemo(
    () => ({
      s: withDefault(NumberParam, 2),
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 15),
      uniqueCode: withDefault(StringParam, undefined),
      specificSymbol: withDefault(StringParam, undefined),
      variableSymbol: withDefault(StringParam, undefined),
      preEmption: withDefault(BooleanParam, undefined),
      admitted: withDefault(BooleanParam, undefined),
      auctionSecurity: withDefault(ArrayParam, undefined),
      authorizationState: withDefault(StringParam, undefined),
      fullName: withDefault(StringParam, undefined),
      withoutWinner: withDefault(BooleanParam, undefined),
      status: withDefault(ArrayParam, undefined),
      number: withDefault(StringParam, undefined),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  const authorizationTypes = auctionAuthorizationTypes.map((v) => ({
    value: v.type,
    label: v.translated,
  }));

  useEffect(() => {
    loadEnums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.setValues(query);
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const loadEnums = async () => {
    try {
      setAuctionAuthorizationTypes((await enumApi.getAuctionAuthorizationState()).data.data);
      setAuctionStatus((await auctionsApi.getAuctionStatus()).data.data);
    } catch (err: any) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const getStatusOptions = () => {
    return auctionStatus.map((i) => ({ value: i.type, label: i.translated }));
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    setQuery(formValues, 'push');
  };

  const handleStatusChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const itemValue = value as SelectOptionType[];
    formik.setFieldValue(
      'status',
      itemValue.map((s) => s.value)
    );
  };

  const hasFilters = (): boolean => {
    if (location.search === '?s=2') {
      return false;
    }
    return !!location.search;
  };

  const getClearUrl = () => {
    return `${pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY)}?s=2`;
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Identifikační číslo</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="uniqueCode"
                  value={formik.values.uniqueCode || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Jméno a příjmení</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="fullName"
                  value={formik.values.fullName || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Specific. symbol</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="specificSymbol"
                  value={formik.values.specificSymbol || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Variabilní symbol</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="variableSymbol"
                  value={formik.values.variableSymbol || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Jednací číslo</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="number"
                  value={formik.values.number || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Select
                  isMulti
                  isClearable={true}
                  options={getStatusOptions()}
                  value={(() => {
                    const res: SelectOptionType[] = [];
                    (formik.values.status || []).forEach((v) => {
                      const item = getStatusOptions().find((s) => s.value === v);
                      if (!!item) {
                        res.push(item);
                      }
                    });
                    return res;
                  })()}
                  onChange={(val: any) => handleStatusChange(val)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Ověření totožnosti</Form.Label>
                <Select
                  isClearable={true}
                  options={authorizationTypes}
                  value={authorizationTypes.find((i) => i.value === formik.values.authorizationState) || null}
                  onChange={(val) => formik.setFieldValue('authorizationState', val?.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <Row>
                <Col sm="auto" className="pb-4 pt-2 align-self-center">
                  <Row>
                    <Col sm="auto">
                      <Form.Check
                        custom
                        type="checkbox"
                        label="Předkupní právo"
                        className="mt-checkbox"
                        id="preEmption"
                        name="preEmption"
                        checked={formik.values.preEmption || false}
                        onChange={(e) => formik.setFieldValue('preEmption', e.target.checked || undefined)}
                      />
                    </Col>
                    <Col sm="auto">
                      <Form.Check
                        custom
                        type="checkbox"
                        label="Připuštěn"
                        className="mt-checkbox"
                        id="admitted"
                        name="admitted"
                        checked={formik.values.admitted || false}
                        onChange={(e) => formik.setFieldValue('admitted', e.target.checked || undefined)}
                      />
                    </Col>
                    <Col sm="auto">
                      <Form.Check
                        custom
                        type="checkbox"
                        label="Bez vítěze"
                        className="mt-checkbox"
                        id="withoutWinner"
                        name="withoutWinner"
                        checked={formik.values.withoutWinner || false}
                        onChange={(e) => formik.setFieldValue('withoutWinner', e.target.checked || undefined)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit hasFilters={hasFilters()} onOpenClick={() => setIsOpen(true)} clearUrl={getClearUrl()} />
      </AdminFiltersBox>
    </Form>
  );
};

export default FiltersForm;
