import React, { FC, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { Form } from 'react-bootstrap';

import { AuctionParametersResponse, BaseType } from '@types';
import { useAuctionParametersApi } from '@api/auctionParameters';
import { ControlFeedback, FormGroup, Select } from '@components';

import { AuctionParameterForm } from './AuctionParameterForm';
import { arrKeyExists } from '@helpers/validator';
import { BooleanParameter } from './BooleanParameter';

interface SelectOptionType {
  value: string;
  label: string;
}

export const RealEstateLandForm: FC<AuctionParameterForm> = (props) => {
  const auctionParameterApi = useAuctionParametersApi();
  const [landGroupTypes, setLandGroupTypes] = useState<AuctionParametersResponse[]>([]);
  const [placementTypes, setPlacementTypes] = useState<AuctionParametersResponse[]>([]);
  const [waterTypes, setWaterTypes] = useState<AuctionParametersResponse[]>([]);
  const [circuitBreaker, setCircuitBreaker] = useState<AuctionParametersResponse[]>([]);
  const [electricityTypes, setElectricityTypes] = useState<AuctionParametersResponse[]>([]);
  const [wasteTypes, setWasteTypes] = useState<AuctionParametersResponse[]>([]);
  const [gasTypes, setGasTypes] = useState<AuctionParametersResponse[]>([]);
  const [heatingElement, setHeatingElement] = useState<AuctionParametersResponse[]>([]);
  const [heatingSource, setHeatingSource] = useState<AuctionParametersResponse[]>([]);
  const [waterHeatSource, setWaterHeatSource] = useState<AuctionParametersResponse[]>([]);
  const [transportTypes, setTransportTypes] = useState<AuctionParametersResponse[]>([]);
  const [telecommucationTypes, setTelecommucationTypes] = useState<AuctionParametersResponse[]>([]);
  const [internetConnectionTypes, setInternetConnectionTypes] = useState<AuctionParametersResponse[]>([]);
  const [wellTypes, setWellTypes] = useState<AuctionParametersResponse[]>([]);
  const [phaseDistributions, setPhaseDistributions] = useState<AuctionParametersResponse[]>([]);

  const [roadTypes, setRoadTypes] = useState<AuctionParametersResponse[]>([]);

  const roadTypeOptions = roadTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const landGroupOptions = landGroupTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const waterTypeOptions = waterTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const electricityTypeOptions = electricityTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wasteTypeOptions = wasteTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const gasTypeOptions = gasTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const placementTypeOptions = placementTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const heatingElementOptions = heatingElement.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const heatingSourceOptions = heatingSource.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const waterHeatSourceOptions = waterHeatSource.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const transportTypeOptions = transportTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const telecommucationTypeOptions = telecommucationTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const circuitBreakerOptions = circuitBreaker.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const internetConnectionTypeOptions = internetConnectionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wellTypeOptions = wellTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const phaseDistributionOptions = phaseDistributions.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  useEffect(() => {
    loadData();
    return () => {
      auctionParameterApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const landGroupTypesResponse = await auctionParameterApi.getRealEstateLandGroup(props.values.auctionCategory);
      setLandGroupTypes(landGroupTypesResponse.data.data);

      if (
        props.values.auctionParameterRealEstateLand?.parameterRealEstateLandGroup &&
        !~landGroupTypesResponse.data.data
          .map((v) => v.id)
          .indexOf(
            props.values.auctionParameterRealEstateLand?.parameterRealEstateLandGroup?.id ||
              props.values.auctionParameterRealEstateLand?.parameterRealEstateLandGroup
          )
      ) {
        props.setFieldValue('auctionParameterRealEstateLand.parameterRealEstateLandGroup', undefined);
      }

      if (landGroupTypesResponse.data.data.length === 1) {
        props.setFieldValue(
          'auctionParameterRealEstateLand.parameterRealEstateLandGroup',
          landGroupTypesResponse.data.data[0].id
        );
      }

      const realEstateRes = await auctionParameterApi.getRealEstateAll();
      setWaterTypes(realEstateRes.data.data.parameterRealEstateWater);
      setElectricityTypes(realEstateRes.data.data.parameterRealEstateElectricity);
      setWasteTypes(realEstateRes.data.data.parameterRealEstateWaste);
      setGasTypes(realEstateRes.data.data.parameterRealEstateGas);
      setHeatingElement(realEstateRes.data.data.parameterRealEstateHeatingElement);
      setHeatingSource(realEstateRes.data.data.parameterRealEstateHeatingSource);
      setWaterHeatSource(realEstateRes.data.data.parameterRealEstateWaterHeatSource);
      setPlacementTypes(realEstateRes.data.data.parameterRealEstatePlacement);
      setRoadTypes(realEstateRes.data.data.parameterRealEstateRoadType);
      setTransportTypes(realEstateRes.data.data.parameterRealEstateTransport);
      setTelecommucationTypes(realEstateRes.data.data.parameterRealEstateTelecommunication);
      setCircuitBreaker(realEstateRes.data.data.parameterRealEstateCircuitBreaker);
      setInternetConnectionTypes(realEstateRes.data.data.parameterRealEstateInternetConnectionType);
      setWellTypes(realEstateRes.data.data.parameterRealEstateWellType);
      setPhaseDistributions(realEstateRes.data.data.parameterRealEstatePhaseDistribution);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const handlerPhaseDistributionChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateLand.parameterRealEstatePhaseDistribution', itemValue?.value);
  };

  const handleRealEstateLandWellTypesChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandWellTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandWellTypes',
      itemValue.map((i) => ({
        parameterRealEstateWellType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateWellType.id || f.parameterRealEstateWellType) === i.value
        )?.id,
      }))
    );
  };

  const handleInternetConnectionTypeChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandInternetConnectionTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandInternetConnectionTypes',
      itemValue.map((i) => ({
        parameterRealEstateInternetConnectionType: i.value,
        id: currentValues.find(
          (f: any) =>
            (f.parameterRealEstateInternetConnectionType.id || f.parameterRealEstateInternetConnectionType) === i.value
        )?.id,
      }))
    );
  };

  const handleLandGroupChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateLand.parameterRealEstateLandGroup', itemValue?.value);
  };

  const handlerCircuitBreakerChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateLand.parameterRealEstateCircuitBreaker', itemValue?.value);
  };

  const handleRealEstateWaterChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandWaters || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandWaters',
      itemValue.map((i) => ({
        parameterRealEstateWater: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateWater.id || f.parameterRealEstateWater) === i.value)
          ?.id,
      }))
    );
  };

  const handleRealEstateElectricityChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandElectricities || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandElectricities',
      itemValue.map((i) => ({
        parameterRealEstateElectricity: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateElectricity.id || f.parameterRealEstateElectricity) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateWasteChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandWastes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandWastes',
      itemValue.map((i) => ({
        parameterRealEstateWaste: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateWaste.id || f.parameterRealEstateWaste) === i.value)
          ?.id,
      }))
    );
  };

  const handleRealEstateGasChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandGases || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandGases',
      itemValue.map((i) => ({
        parameterRealEstateGas: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateGas.id || f.parameterRealEstateGas) === i.value)?.id,
      }))
    );
  };

  const handlePlacementChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateLand.parameterRealEstatePlacement', itemValue?.value);
  };

  const handleRoadTypeChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandRoadTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandRoadTypes',
      itemValue.map((i) => ({
        parameterRealEstateRoadType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateRoadType.id || f.parameterRealEstateRoadType) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateLandHeatingElementChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandHeatingElements || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandHeatingElements',
      itemValue.map((i) => ({
        parameterRealEstateHeatingElement: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeatingElement.id || f.parameterRealEstateHeatingElement) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateLandHeatingSourceChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandHeatingSources || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandHeatingSources',
      itemValue.map((i) => ({
        parameterRealEstateHeatingSource: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeatingSource.id || f.parameterRealEstateHeatingSource) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateLandWaterHeatSourceChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandWaterHeatSources || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandWaterHeatSources',
      itemValue.map((i) => ({
        parameterRealEstateWaterHeatSource: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateWaterHeatSource.id || f.parameterRealEstateWaterHeatSource) === i.value
        )?.id,
      }))
    );
  };

  const handleTransportChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandTransports || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandTransports',
      itemValue.map((i) => ({
        parameterRealEstateTransport: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTransport.id || f.parameterRealEstateTransport) === i.value
        )?.id,
      }))
    );
  };

  const handleTelecommucationChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const currentValues =
      props.values.auctionParameterRealEstateLand?.auctionParameterRealEstateLandTelecommunications || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateLand.auctionParameterRealEstateLandTelecommunications',
      itemValue.map((i) => ({
        parameterRealEstateTelecommunication: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTelecommunication.id || f.parameterRealEstateTelecommunication) === i.value
        )?.id,
      }))
    );
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  return (
    <>
      <div className="pt-4 pb-3">
        <h3 className="mb-4 font-weight-normal">Základní informace</h3>
      </div>

      {landGroupOptions.length > 1 && (
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-left">
            Typ pozemků{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateLandGroup') ? ' *' : ''}
          </Form.Label>
          <div className="f-inline-control">
            <div className="w-max-500" data-test-id="parameterRealEstateLandGroup">
              <Select
                isClearable
                readOnly={props.readOnly}
                size="md"
                name="parameterRealEstateLandGroup"
                isInvalid={!!props.errors.auctionParameterRealEstateLand_parameterRealEstateLandGroup}
                options={landGroupOptions}
                onChange={handleLandGroupChange}
                value={
                  landGroupOptions.find(
                    (i) =>
                      i.value ===
                      getSelectValue(props.values.auctionParameterRealEstateLand.parameterRealEstateLandGroup)
                  ) || null
                }
              />
              {!!props.errors.auctionParameterRealEstateLand_parameterRealEstateLandGroup && (
                <ControlFeedback type="invalid">
                  {props.errors.auctionParameterRealEstateLand_parameterRealEstateLandGroup as string}
                </ControlFeedback>
              )}
            </div>
          </div>
        </Form.Group>
      )}

      {/* /api/admin/parameter/real-estate/placement */}
      <Form.Group className="f-inline-group mb-2">
        <Form.Label className="f-inline-label text-left">
          Umístění{arrKeyExists(props.requiredParametersFields, 'parameterRealEstatePlacement') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePlacement">
            <Select
              isClearable
              readOnly={props.readOnly}
              size="md"
              name="parameterRealEstatePlacement"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_parameterRealEstatePlacement}
              onChange={handlePlacementChange}
              options={placementTypeOptions}
              value={
                placementTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateLand.parameterRealEstatePlacement)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateLand_parameterRealEstatePlacement && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_parameterRealEstatePlacement as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-1 pt-1">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Plochy</h3>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          name="auctionParameterRealEstateLand.landArea"
          error={props.errors.auctionParameterRealEstateLand_landArea as string}
          thousandSeparator
          label="Plocha pozemku"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          required={arrKeyExists(props.requiredParametersFields, 'landArea')}
          value={props.values.auctionParameterRealEstateLand.landArea}
          onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateLand.landArea', parseInt(val.value))}
          dataTestId="auctionParameterRealEstateLand.landArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Ostatní</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj vody{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandWaters') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateLandWaters">
            <Select
              isMulti
              isClearable
              readOnly={props.readOnly}
              size="md"
              name="auctionParameterRealEstateLandWaters"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_parameterRealEstateWater}
              options={waterTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWaterChange(val)}
              value={(props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandWaters || []).map(
                (v: { parameterRealEstateWater: string | number }) =>
                  waterTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWater))
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandWaters && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandWaters as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ studny
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandWellTypes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWellTypet">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateLandWellTypes"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandWellTypes}
              options={wellTypeOptions}
              onChange={(val: BaseType) => handleRealEstateLandWellTypesChange(val)}
              value={(props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandWellTypes || []).map(
                (v: { parameterRealEstateWellType: string | number }) =>
                  wellTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWellType))
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandWellTypes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandWellTypes as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Topné těleso
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandHeatingElement') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHeatingElement">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateLandHeatingElements"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandHeatingElements}
              options={heatingElementOptions}
              onChange={(val: BaseType) => handleRealEstateLandHeatingElementChange(val)}
              value={(
                props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandHeatingElements || []
              ).map((v: { parameterRealEstateHeatingElement: string | number }) =>
                heatingElementOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeatingElement))
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandHeatingElements && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandHeatingElements as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj topení
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandHeatingSources') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHeatingSource">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateLandHeatingSources"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandHeatingSources}
              options={heatingSourceOptions}
              onChange={(val: BaseType) => handleRealEstateLandHeatingSourceChange(val)}
              value={(
                props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandHeatingSources || []
              ).map((v: { parameterRealEstateHeatingSource: string | number }) =>
                heatingSourceOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeatingSource))
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandHeatingSources && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandHeatingSources as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj teplé vody
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandWaterHeatSources') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWaterHeatSource">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateLandWaterHeatSources"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandWaterHeatSources}
              options={waterHeatSourceOptions}
              onChange={(val: BaseType) => handleRealEstateLandWaterHeatSourceChange(val)}
              value={(
                props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandWaterHeatSources || []
              ).map((v: { parameterRealEstateWaterHeatSource: string | number }) =>
                waterHeatSourceOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaterHeatSource))
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandWaterHeatSources && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandWaterHeatSources as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Rozvod plynu{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandGases') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateLandGases">
            <Select
              isMulti
              isClearable
              readOnly={props.readOnly}
              size="md"
              name="auctionParameterRealEstateLandGases"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandGases}
              options={gasTypeOptions}
              onChange={(val: BaseType) => handleRealEstateGasChange(val)}
              value={(props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandGases || []).map(
                (v: { parameterRealEstateGas: string | number }) =>
                  gasTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateGas))
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandGases && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandGases as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ odpadu{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandWastes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateLandWastes">
            <Select
              isMulti
              isClearable
              readOnly={props.readOnly}
              size="md"
              name="auctionParameterRealEstateLandWastes"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_parameterRealEstateWaste}
              options={wasteTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWasteChange(val)}
              value={(props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandWastes || []).map(
                (v: { parameterRealEstateWaste: string | number }) =>
                  wasteTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaste))
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandWastes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandWastes as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Příjezdová komunikace
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandRoadTypes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateLandRoadTypes">
            <Select
              isMulti
              isClearable
              readOnly={props.readOnly}
              size="md"
              name="auctionParameterRealEstateLandRoadTypes"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandRoadTypes}
              options={roadTypeOptions}
              onChange={(val: BaseType) => handleRoadTypeChange(val)}
              value={(props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandRoadTypes || []).map(
                (v: { parameterRealEstateRoadType: string | number }) =>
                  roadTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateRoadType))
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandRoadTypes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandRoadTypes as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Telekomunikace
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandTelecommunications') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateLandTelecommunications">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateLandTelecommunications"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandTelecommunications}
              options={telecommucationTypeOptions}
              onChange={(val: BaseType) => handleTelecommucationChange(val)}
              value={(
                props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandTelecommunications || []
              ).map((v: { parameterRealEstateTelecommunication: string | number }) =>
                telecommucationTypeOptions.find(
                  (f) => f.value === getSelectValue(v.parameterRealEstateTelecommunication)
                )
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandTelecommunications && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandTelecommunications as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Elektřina
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandElectricities') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateLandElectricities">
            <Select
              isMulti
              isClearable
              readOnly={props.readOnly}
              size="md"
              name="auctionParameterRealEstateLandElectricities"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandElectricities}
              options={electricityTypeOptions}
              onChange={(val: BaseType) => handleRealEstateElectricityChange(val)}
              value={(
                props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandElectricities || []
              ).map((v: { parameterRealEstateElectricity: string | number }) =>
                electricityTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateElectricity))
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandElectricities && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandElectricities as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Doprava{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandTransports') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateLandTransports">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateLandTransports"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandTransports}
              options={transportTypeOptions}
              onChange={(val: BaseType) => handleTransportChange(val)}
              value={(props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandTransports || []).map(
                (v: { parameterRealEstateTransport: string | number }) =>
                  transportTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateTransport))
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandTransports && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandTransports as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <BooleanParameter
        label="Fotovoltaika"
        dataTestId="auctionParameterRealEstateLand.ftvPanels"
        name="auctionParameterRealEstateLand.ftvPanels"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateLand?.ftvPanels}
        errors={props.errors.auctionParameterRealEstateLand_ftvPanels}
        required={arrKeyExists(props.requiredParametersFields, 'ftvPanels')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateLand.ftvPanels', val)}
      />

      <BooleanParameter
        label="Solární panely"
        dataTestId="auctionParameterRealEstateLand.solarPanels"
        name="auctionParameterRealEstateLand.solarPanels"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateLand?.solarPanels}
        errors={props.errors.auctionParameterRealEstateLand_solarPanels}
        required={arrKeyExists(props.requiredParametersFields, 'solarPanels')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateLand.solarPanels', val)}
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Jističe{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateCircuitBreaker') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateCircuitBreaker">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateCircuitBreaker"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_parameterRealEstateCircuitBreaker}
              options={circuitBreakerOptions}
              onChange={handlerCircuitBreakerChange}
              value={
                circuitBreakerOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateLand.parameterRealEstateCircuitBreaker)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateLand_parameterRealEstateCircuitBreaker && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_parameterRealEstateCircuitBreaker as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Počet fází{arrKeyExists(props.requiredParametersFields, 'parameterRealEstatePhaseDistribution') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePhaseDistribution">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstatePhaseDistribution"
              isInvalid={!!props.errors.auctionParameterRealEstateLand_parameterRealEstatePhaseDistribution}
              options={phaseDistributionOptions}
              onChange={handlerPhaseDistributionChange}
              value={
                phaseDistributionOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateLand.parameterRealEstatePhaseDistribution)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateLand_parameterRealEstatePhaseDistribution && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateLand_parameterRealEstatePhaseDistribution as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <FormGroup
        type="text"
        label="Klíčová slova (oddělená středníkem)"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateLand.keywords"
        value={props.values.auctionParameterRealEstateLand.keywords}
        error={props.errors.auctionParameterRealEstateLand_keywords as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'keywords')}
        dataTestId="auctionParameterRealEstateLand.keywords"
      />

      <div className="pb-3">
        <hr />
      </div>

      <FormGroup
        type="text"
        label="Poskytovatel innternetového připojení"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateLand.internetConnectionProvider"
        value={props.values.auctionParameterRealEstateLand.internetConnectionProvider}
        error={props.errors.auctionParameterRealEstateLand_internetConnectionProvider as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'internetConnectionProvider')}
        dataTestId="auctionParameterRealEstateLand.internetConnectionProvider"
      />

      <FormGroup
        type="number"
        label="Rychlost internetového připojení (Mbit/s)"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateLand.internetConnectionSpeed"
        value={props.values.auctionParameterRealEstateLand.internetConnectionSpeed}
        error={props.errors.auctionParameterRealEstateLand_internetConnectionSpeed as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'internetConnectionSpeed')}
        dataTestId="auctionParameterRealEstateLand.internetConnectionSpeed"
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ internetového připojení
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateLandInternetConnectionTypes')
            ? ' *'
            : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateLandInternetConnectionTypes">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateLandInternetConnectionTypes"
              isInvalid={
                !!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandInternetConnectionTypes
              }
              options={internetConnectionTypeOptions}
              onChange={(val: BaseType) => handleInternetConnectionTypeChange(val)}
              value={(
                props.values.auctionParameterRealEstateLand.auctionParameterRealEstateLandInternetConnectionTypes || []
              ).map((v: { parameterRealEstateInternetConnectionType: string | number }) =>
                internetConnectionTypeOptions.find(
                  (f) => f.value === getSelectValue(v.parameterRealEstateInternetConnectionType)
                )
              )}
            />
            {!!props.errors.auctionParameterRealEstateLand_auctionParameterRealEstateLandInternetConnectionTypes && (
              <ControlFeedback type="invalid">
                {
                  props.errors
                    .auctionParameterRealEstateLand_auctionParameterRealEstateLandInternetConnectionTypes as string
                }
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>
    </>
  );
};
