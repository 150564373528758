import React, { useEffect, useState } from 'react';
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params';

import { BaseFrontPage } from '@components';
import { FrontSidebarBanners, FrontSidebarStatistics, FrontSidebarNotifications } from '@components/FrontSidebarBox';
import { FrontAuctioneerAuctions } from '@components/FrontAuctioneerList/FrontAuctioneerAuctions';
import { useLocation, useParams } from 'react-router-dom';
import { useAuctioneersApi } from '@api/auctioneers';
import { useAuthFrontStore } from '@stores';
import { AuctionResponse } from '@types';

export const AuctioneerDetailPage = () => {
  const { id } = useParams<{ id?: string }>();
  const location = useLocation();
  const auctioneersApi = useAuctioneersApi();
  const authFrontState = useAuthFrontStore();
  const [auctions, setAuctions] = useState<Array<AuctionResponse>>([]);
  const [totalAuctions, setTotalAuctions] = useState<number>(0);
  const [loaded, setLoaded] = useState(false);
  const defaultQuery = React.useMemo(
    () => ({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 15),
      status: withDefault(StringParam, 'prepared'),
      auctionType: withDefault(StringParam, undefined),
      search: withDefault(StringParam, undefined),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  useEffect(() => {
    if (id) {
      loadAuctions(id);
      window.scrollTo(0, 0);
    }
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, authFrontState.user, location.search]);

  const loadAuctions = async (id: string | number) => {
    try {
      const response = await auctioneersApi.getWebAuctioneerAuctions(id, { ...query });
      setAuctions(response.data.data);
      setTotalAuctions(response.data?.total as number);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };
  return (
    <BaseFrontPage
      disableScrollToTop
      sidebarItems={[
        <FrontSidebarNotifications title="Upozornění na Váš e-mail" />,
        <FrontSidebarStatistics title="Exdražby v číslech" />,
        <FrontSidebarBanners />,
      ]}
    >
      <FrontAuctioneerAuctions
        id={id as string}
        auctions={auctions}
        loadAuctions={loadAuctions}
        loaded={loaded}
        query={query}
        setQuery={setQuery}
        totalAuctions={totalAuctions}
      />
    </BaseFrontPage>
  );
};
