import React from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import * as routes from '@routes';
import { BasePage, AdminBoxContent, AdminProgressFormMenu } from '@components';
import { usePageStore } from '@stores';
import Services from './components/Services';
import Administrators from './components/Administrators';
import Administrator from './components/Administrator';
import Informations from './components/Informations';
import Pages from './components/Pages';
import TextBlocks from './components/TextBlocks';
import TextBlock from './components/TextBlock';
import TextBlockContent from './components/TextBlockContent';
import PageForm from './components/PageForm';
import PageBlockForm from './components/PageBlockForm';
import News from './components/News';
import New from './components/New';
import BillingInformations from './components/BillingInformations';
import OtherSettings from './components/OtherSettings';
import InformedConsents from './components/InformedConsents';

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageState = usePageStore();
  const page = pageState.getPage(routes.admin.SETTINGS);

  const menuItems = pageState
    .getPagesByParentPageId(pageState.getPage(routes.admin.SETTINGS)?.id || 0)
    .filter((item) => item.menu)
    .map((item) => ({
      label: item.name,
      url: item.url,
      routeName: item.routeName,
      isActive: location.pathname.startsWith(item.url),
    }));

  return (
    <BasePage page={routes.admin.SETTINGS} title={page?.name}>
      <AdminProgressFormMenu
        onClick={(url) => navigate(url.toString())}
        items={menuItems}
        dataTestId="admin-settings-menu"
      />
      <AdminBoxContent className="p-0">
        <div className="w-min-500">
          <div className="component-admin-box-content overflow-x-auto">
            <Routes>
              {pageState.getPage(routes.admin.SETTINGS_INFORMATIONS) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_INFORMATIONS, routes.admin.SETTINGS)}
                  element={<Informations />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_INFORMED_CONSENTS) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_INFORMED_CONSENTS, routes.admin.SETTINGS)}
                  element={<InformedConsents />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_PRICE_LIST) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_PRICE_LIST, routes.admin.SETTINGS)}
                  element={<Services />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_ADMINISTRATORS, routes.admin.SETTINGS)}
                  element={<Administrators />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS_CREATE) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_ADMINISTRATORS_CREATE, routes.admin.SETTINGS)}
                  element={<Administrator />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS_UPDATE) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_ADMINISTRATORS_UPDATE, routes.admin.SETTINGS)}
                  element={<Administrator />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_PAGES_LIST) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_PAGES_LIST, routes.admin.SETTINGS)}
                  element={<Pages />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_PAGES_DETAIL) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_PAGES_DETAIL, routes.admin.SETTINGS)}
                  element={<PageForm />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_PAGES_CREATE) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_PAGES_CREATE, routes.admin.SETTINGS)}
                  element={<PageForm />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_PAGES_BLOCK_CREATE) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_PAGES_BLOCK_CREATE, routes.admin.SETTINGS)}
                  element={<PageBlockForm />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_PAGES_BLOCK_DETAIL) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_PAGES_BLOCK_DETAIL, routes.admin.SETTINGS)}
                  element={<PageBlockForm />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_TEXT_BLOCK, routes.admin.SETTINGS)}
                  element={<TextBlocks />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CREATE) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_TEXT_BLOCK_CREATE, routes.admin.SETTINGS)}
                  element={<TextBlock />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_UPDATE) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_TEXT_BLOCK_UPDATE, routes.admin.SETTINGS)}
                  element={<TextBlock />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_CREATE) && (
                <Route
                  path={pageState.getChildPagePath(
                    routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_CREATE,
                    routes.admin.SETTINGS
                  )}
                  element={<TextBlockContent />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_UPDATE) && (
                <Route
                  path={pageState.getChildPagePath(
                    routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_UPDATE,
                    routes.admin.SETTINGS
                  )}
                  element={<TextBlockContent />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_NEWS) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_NEWS, routes.admin.SETTINGS)}
                  element={<News />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_NEWS_CREATE) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_NEWS_CREATE, routes.admin.SETTINGS)}
                  element={<New />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_NEWS_UPDATE) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_NEWS_UPDATE, routes.admin.SETTINGS)}
                  element={<New />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_BILLING_INFORMATIONS) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_BILLING_INFORMATIONS, routes.admin.SETTINGS)}
                  element={<BillingInformations />}
                />
              )}
              {pageState.getPage(routes.admin.SETTINGS_OTHER) && (
                <Route
                  path={pageState.getChildPagePath(routes.admin.SETTINGS_OTHER, routes.admin.SETTINGS)}
                  element={<OtherSettings />}
                />
              )}
              <Route
                path="*"
                element={<Navigate to={pageState.getPage(routes.admin.SETTINGS_PRICE_LIST)?.url || '/'} />}
              />
            </Routes>
          </div>
        </div>
      </AdminBoxContent>
    </BasePage>
  );
};

export default SettingsPage;
