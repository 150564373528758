import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import { NewsType, PermissionGroup, UserPermission } from '@types';
import { usePageStore } from '@stores';
import * as routes from '@routes';
import { Pagination, BasePreloader, Button, Visible } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { useNewsApi } from '@api/news';
import NewsTable from './NewsTable';

const News: React.FC = () => {
  const newsApi = useNewsApi();
  const pageState = usePageStore();
  const location = useLocation();
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState<Array<NewsType>>([]);
  const [dataLen, setDataLen] = React.useState(0);
  const defaultQuery = React.useMemo(
    () => ({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 15),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  React.useEffect(() => {
    loadData();
    return () => newsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, query]);

  const loadData = async () => {
    try {
      const response = await newsApi.newsList(query);
      setData(response.data.data);
      setDataLen(response.data.total as number);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const handleArchiveClick = (item: NewsType) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete archivovat aktualitu?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return newsApi.archiveNews(item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const handleActivateClick = (item: NewsType) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete obnovit aktualitu?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return newsApi.activateNews(item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  return (
    <div>
      <div>
        <h2>Aktuality</h2>
      </div>
      <Row className="align-items-center">
        <Col xs={12} lg={6}>
          <Visible permissionGroupName={PermissionGroup.administrator} permissionName={UserPermission.canCreate}>
            <Button to={pageState.getPagePath(routes.admin.SETTINGS_NEWS_CREATE)} className="mt-3">
              <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
              <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
              Přidat aktualitu
            </Button>
          </Visible>
        </Col>
        <Col xs={12} lg={6} className="text-left text-lg-right mt-4 mt-lg-0">
          {loaded && (
            <p className="m-0">
              Celkem <strong>{dataLen}</strong> aktualit
            </p>
          )}
        </Col>
      </Row>
      <Row className="mt-3 pt-3">
        <Col xs={12}>
          {loaded ? (
            <div>
              <div>
                <NewsTable data={data} onArchiveClick={handleArchiveClick} onActivateClick={handleActivateClick} />
              </div>
              {data.length > 0 && (
                <div className="mt-2">
                  <Pagination
                    page={query.page}
                    perPage={query.perPage}
                    totalRows={dataLen}
                    onPageChange={(page) => setQuery({ ...query, page: page }, 'push')}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default News;
