import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { usePagesApi } from '@api/pages';
import { AdminPageRequest, PageType } from '@types';
import { BasePreloader, Button, FormGroup, Select } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import PageTextBlocksTable from './PageBlocksTable';

const PageForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const pagesApi = usePagesApi();
  const pageState = usePageStore();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<PageType | undefined>();
  const [isLoaded, setIsLoaded] = useState<boolean>(!id);
  const [pages, setPages] = useState<PageType[]>([]);
  const page = pageState.getPage(!id ? routes.admin.SETTINGS_PAGES_CREATE : routes.admin.SETTINGS_PAGES_DETAIL);
  const formik = useFormik<AdminPageRequest>({
    initialValues: {
      url: '',
      name: '',
      routeName: '',
      menuPosition: 0,
      footerPosition: 0,
      parentPageId: null,
      enabled: false,
      footer: false,
      menu: false,
      perex: '',
    },
    onSubmit: () => handleSubmit(),
  });
  const pagesOptions = pages.map((i) => ({ label: i.name, value: i.id.toString() }));

  useEffect(() => {
    formik.setValues(formik.initialValues);
    setCurrentPage(undefined);
    setIsLoaded(!id);
    loadPage();
    loadPages();
    return () => pagesApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = () => {
    if (!id) {
      pageCreate();
    } else {
      pageUpdate();
    }
  };

  const pageCreate = async () => {
    try {
      const response = await pagesApi.adminPageCreate(formik.values);
      formik.setSubmitting(false);
      navigate(pageState.getPagePath(routes.admin.SETTINGS_PAGES_DETAIL, { ':id': response.data.data.id }));
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const pageUpdate = async () => {
    try {
      await pagesApi.adminPageUpdate(id as string, formik.values);
      formik.setSubmitting(false);
      loadPage();
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const loadPage = async () => {
    if (!id) {
      formik.setValues(formik.initialValues);
      return;
    }
    try {
      const data = await pagesApi.getAdminPageDetail(id);
      const result = data.data.data;
      setIsLoaded(true);
      setCurrentPage(result);
      formik.setValues({
        url: result.url,
        name: result.name,
        routeName: result.routeName,
        menuPosition: result.menuPosition || 0,
        footerPosition: result.footerPosition || 0,
        parentPageId: result.parentPageId?.toString() || null,
        enabled: result.enabled,
        footer: result.footer,
        menu: result.menu,
        roles: result.roles,
        perex: result.perex,
      });
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const loadPages = async () => {
    try {
      const data = await pagesApi.getAdminPagesList();
      setPages(data.data.data.filter((i) => i.id.toString() !== (id || '').toString()));
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleDeleteClick = () => {
    confirmMessage({
      isHtml: true,
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return pagesApi.adminPageDelete(id as string).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        navigate(pageState.getPagePath(routes.admin.SETTINGS_PAGES_LIST));
      }
    });
  };

  if (!isLoaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <h2 className="f-size-25 f-weight-300 mr-4 mb-0 mt-0">{page?.name}</h2>
      <Form onSubmit={formik.handleSubmit} className="mt-5">
        <div className="responsive-table-content">
          <FormGroup
            required
            label="Nadpis"
            name="name"
            labelClassName="text-left"
            error={formik.errors.name}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <Form.Group className="f-inline-group">
            <Form.Label className="f-inline-label text-left">Nadřazená stránka</Form.Label>
            <div className="f-inline-control">
              <div className="w-max-500">
                <Select
                  isClearable
                  className="w-max-500"
                  size="md"
                  name="parentPageId"
                  options={pagesOptions}
                  onChange={(v) => formik.setFieldValue('parentPageId', v?.value || null)}
                  value={pagesOptions.find((i) => i.value === formik.values.parentPageId)}
                />
              </div>
            </div>
          </Form.Group>
          <FormGroup
            required
            type="number"
            label="Pořadí"
            name="menuPosition"
            labelClassName="text-left"
            error={formik.errors.menuPosition}
            value={formik.values.menuPosition}
            onChange={formik.handleChange}
            helpText={<div>Pořadí stránek v menu, řazení dlaždic a pod.</div>}
          />
          <FormGroup
            label="URL"
            name="url"
            labelClassName="text-left"
            helpText={<div>Pokud tuto hodnotu necháte prázdnou, automaticky se vygeneruje z názvu stránky.</div>}
            error={formik.errors.url}
            value={formik.values.url}
            onChange={formik.handleChange}
            readOnly={currentPage?.systemPage}
          />
          <FormGroup
            label="Strojový název"
            name="routeName"
            labelClassName="text-left"
            helpText={<div>Pokud tuto hodnotu necháte prázdnou, automaticky se vygeneruje z názvu stránky.</div>}
            error={formik.errors.routeName}
            value={formik.values.routeName}
            onChange={formik.handleChange}
            readOnly={currentPage?.systemPage}
          />
          <FormGroup
            rows={4}
            as="textarea"
            name="perex"
            label="Perex"
            className="f-align-start"
            controlClassName="w-max-500 resize-none"
            labelClassName="text-left"
            error={formik.errors.perex}
            value={formik.values.perex}
            onChange={formik.handleChange}
          />
          <Form.Group className="d-flex align-items-center mt-5">
            <div className="mr-2">
              <Form.Check
                custom
                id="enabled"
                name="enabled"
                type="checkbox"
                className="mr-4"
                label="Povoleno"
                checked={formik.values.enabled}
                onChange={(e) => formik.setFieldValue('enabled', e.target.checked)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex align-items-center">
            <div className="mr-2">
              <Form.Check
                custom
                id="menu"
                name="menu"
                type="checkbox"
                className="mr-4"
                label="Zobrazeno v menu"
                checked={formik.values.menu}
                onChange={(e) => formik.setFieldValue('menu', e.target.checked)}
              />
            </div>
          </Form.Group>
          <Form.Group className="d-flex align-items-center">
            <div className="mr-2">
              <Form.Check
                custom
                id="footer"
                name="footer"
                type="checkbox"
                className="mr-4"
                label="Zobrazeno v patičce"
                checked={formik.values.footer}
                onChange={(e) => formik.setFieldValue('footer', e.target.checked)}
              />
            </div>
          </Form.Group>
          {formik.values.footer && (
            <FormGroup
              required
              type="number"
              label="Pozice v patičce"
              name="footerPosition"
              labelClassName="text-left"
              error={formik.errors.footerPosition}
              value={formik.values.footerPosition}
              onChange={formik.handleChange}
            />
          )}
        </div>
        <Row>
          <Col xs={12} className="mt-4 text-right">
            {!formik.isSubmitting ? (
              <>
                {id && !currentPage?.systemPage && (
                  <Button variant="btn-outline-primary" type="button" className="mr-3" onClick={handleDeleteClick}>
                    Odstranit
                  </Button>
                )}
                <Button variant="btn-outline-primary" type="submit" disabled={formik.isSubmitting}>
                  Uložit
                </Button>
              </>
            ) : (
              <BasePreloader size={29} className="d-inline-block" />
            )}
          </Col>
        </Row>
      </Form>

      {currentPage && (
        <div className="mt-3">
          <PageTextBlocksTable page={currentPage} onDelete={() => loadPage()} />
        </div>
      )}

      <div className="mt-5">
        <Button to={pageState.getPagePath(routes.admin.SETTINGS_PAGES_LIST)}>Zpět na seznam stránek</Button>
      </div>
    </div>
  );
};

export default PageForm;
