import React, { FC, useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { OnChangeValue } from 'react-select';

import { arrKeyExists } from '@helpers/validator';
import { getRequestDateTimeFormat } from '@helpers/datetime';
import { AuctionParametersResponse, BaseType } from '@types';
import { AuctionParameterForm } from './AuctionParameterForm';
import { useAuctionParametersApi } from '@api/auctionParameters';
import { ControlFeedback, DatePickerInput, FormGroup, Select } from '@components';
import { BooleanParameter } from './BooleanParameter';

interface SelectOptionType {
  value: string;
  label: string;
}

export const RealEstateFlatForm: FC<AuctionParameterForm> = (props) => {
  const auctionParameterApi = useAuctionParametersApi();
  const [flatTypes, setFlatTypes] = useState<AuctionParametersResponse[]>([]);
  const [objectStatusTypes, setObjectStatusTypes] = useState<AuctionParametersResponse[]>([]);
  const [placementTypes, setPlacementTypes] = useState<AuctionParametersResponse[]>([]);
  const [ownershipTypes, setOwnershipTypes] = useState<AuctionParametersResponse[]>([]);
  const [buildingConstructionTypes, setBuildingConstructionTypes] = useState<AuctionParametersResponse[]>([]);
  const [heatingElement, setHeatingElement] = useState<AuctionParametersResponse[]>([]);
  const [heatingSource, setHeatingSource] = useState<AuctionParametersResponse[]>([]);
  const [waterHeatSource, setWaterHeatSource] = useState<AuctionParametersResponse[]>([]);
  const [parkingTypes, setParkingTypes] = useState<AuctionParametersResponse[]>([]);
  const [flatClass, setflatClass] = useState<AuctionParametersResponse[]>([]);
  const [gasTypes, setGasTypes] = useState<AuctionParametersResponse[]>([]);
  const [electricityTypes, setElectricityTypes] = useState<AuctionParametersResponse[]>([]);
  const [transportTypes, setTransportTypes] = useState<AuctionParametersResponse[]>([]);
  const [wasteTypes, setWasteTypes] = useState<AuctionParametersResponse[]>([]);
  const [waterTypes, setWaterTypes] = useState<AuctionParametersResponse[]>([]);

  const [circuitBreaker, setCircuitBreaker] = useState<AuctionParametersResponse[]>([]);
  const [furnishedTypes, setFurnishedTypes] = useState<AuctionParametersResponse[]>([]);
  const [protectionTypes, setProtectionTypes] = useState<AuctionParametersResponse[]>([]);
  const [surroundingsTypes, setSurroundingsTypes] = useState<AuctionParametersResponse[]>([]);
  const [roadTypes, setRoadTypes] = useState<AuctionParametersResponse[]>([]);
  const [energyEfficiencyRatingTypes, setEnergyEfficiencyRatingTypes] = useState<AuctionParametersResponse[]>([]);
  const [telecommucationTypes, setTelecommucationTypes] = useState<AuctionParametersResponse[]>([]);
  const [internetConnectionTypes, setInternetConnectionTypes] = useState<AuctionParametersResponse[]>([]);
  const [wellTypes, setWellTypes] = useState<AuctionParametersResponse[]>([]);
  const [phaseDistributions, setPhaseDistributions] = useState<AuctionParametersResponse[]>([]);

  const furnishedTypeOptions = furnishedTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const protectionTypeOptions = protectionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const surroundingsTypeOptions = surroundingsTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const roadTypeOptions = roadTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const energyEfficiencyRatingTypeOptions = energyEfficiencyRatingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const telecommucationTypeOptions = telecommucationTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const flatTypeOptions = flatTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const placementTypeOptions = placementTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const ownershipTypeOptions = ownershipTypes.map((item) => ({
    value: item.id,
    label: item.title,
    annuity: item.annuity,
  }));
  const buildingConstructionTypeOptions = buildingConstructionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const objectStatusTypeOptions = objectStatusTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const heatingElementOptions = heatingElement.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const heatingSourceOptions = heatingSource.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const waterHeatSourceOptions = waterHeatSource.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const parkingTypeOptions = parkingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const flatClassOptions = flatClass.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const gasTypeOptions = gasTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const electricityTypeOptions = electricityTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const transportTypeOptions = transportTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wasteTypeOptions = wasteTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const waterTypeOptions = waterTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const circuitBreakerOptions = circuitBreaker.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const internetConnectionTypeOptions = internetConnectionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wellTypeOptions = wellTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const phaseDistributionOptions = phaseDistributions.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  useEffect(() => {
    loadData();
    return () => {
      auctionParameterApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const val = getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateOwnership);
    const annuity = showAnnuity(val);
    if (annuity === false) {
      props.setFieldValue('auctionParameterRealEstateFlat.annuity', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.auctionParameterRealEstateFlat.parameterRealEstateOwnership]);

  const loadData = async () => {
    try {
      const realEstateRes = await auctionParameterApi.getRealEstateAll();
      setPlacementTypes(realEstateRes.data.data.parameterRealEstatePlacement);
      setOwnershipTypes(realEstateRes.data.data.parameterRealEstateOwnership);
      setBuildingConstructionTypes(realEstateRes.data.data.parameterRealEstateBuildingConstruction);
      setObjectStatusTypes(realEstateRes.data.data.parameterRealEstateObjectStatus);
      setHeatingElement(realEstateRes.data.data.parameterRealEstateHeatingElement);
      setHeatingSource(realEstateRes.data.data.parameterRealEstateHeatingSource);
      setWaterHeatSource(realEstateRes.data.data.parameterRealEstateWaterHeatSource);
      setElectricityTypes(realEstateRes.data.data.parameterRealEstateElectricity);
      setGasTypes(realEstateRes.data.data.parameterRealEstateGas);
      setParkingTypes(realEstateRes.data.data.parameterRealEstateParking);
      setTransportTypes(realEstateRes.data.data.parameterRealEstateTransport);
      setFurnishedTypes(realEstateRes.data.data.parameterRealEstateFurnished);
      setWasteTypes(realEstateRes.data.data.parameterRealEstateWaste);
      setWaterTypes(realEstateRes.data.data.parameterRealEstateWater);
      setProtectionTypes(realEstateRes.data.data.parameterRealEstateProtection);
      setSurroundingsTypes(realEstateRes.data.data.parameterRealEstateSurroundingsType);
      setRoadTypes(realEstateRes.data.data.parameterRealEstateRoadType);
      setEnergyEfficiencyRatingTypes(realEstateRes.data.data.parameterRealEstateEnergyEfficiencyRating);
      setTelecommucationTypes(realEstateRes.data.data.parameterRealEstateTelecommunication);
      setCircuitBreaker(realEstateRes.data.data.parameterRealEstateCircuitBreaker);
      setInternetConnectionTypes(realEstateRes.data.data.parameterRealEstateInternetConnectionType);
      setWellTypes(realEstateRes.data.data.parameterRealEstateWellType);
      setPhaseDistributions(realEstateRes.data.data.parameterRealEstatePhaseDistribution);

      const realEstateFlatRes = await auctionParameterApi.getRealEstateFlatAll();
      setFlatTypes(realEstateFlatRes.data.data.parameterRealEstateType);
      setflatClass(realEstateFlatRes.data.data.parameterRealEstateFlatClass);
    } catch (e: any) {
      if (!e.response) {
        return;
      }
    }
  };

  const handlerPhaseDistributionChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstatePhaseDistribution', itemValue?.value);
  };

  const handleRealEstateFlatWellTypesChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatWellTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWellTypes',
      itemValue.map((i) => ({
        parameterRealEstateWellType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateWellType.id || f.parameterRealEstateWellType) === i.value
        )?.id,
      }))
    );
  };

  const handlerCircuitBreakerChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateCircuitBreaker', itemValue?.value);
  };

  const handleInternetConnectionTypeChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatInternetConnectionTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatInternetConnectionTypes',
      itemValue.map((i) => ({
        parameterRealEstateInternetConnectionType: i.value,
        id: currentValues.find(
          (f: any) =>
            (f.parameterRealEstateInternetConnectionType.id || f.parameterRealEstateInternetConnectionType) === i.value
        )?.id,
      }))
    );
  };

  const handleFlatTypeChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateFlatType', itemValue?.value);
  };

  const handleFlatClassChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateFlatClass', itemValue?.value);
  };

  const handlePlacementChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstatePlacement', itemValue?.value);
  };

  const handleFlatOwnershipChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateOwnership', itemValue?.value);
  };

  const handleFlatBuildingConstructionChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateBuildingConstruction', itemValue?.value);
  };

  const handleFlatObjectStatusChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateObjectStatus', itemValue?.value);
  };

  const handleRealEstateFlatHeatingElementChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatHeatingElements || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatHeatingElements',
      itemValue.map((i) => ({
        parameterRealEstateHeatingElement: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeatingElement.id || f.parameterRealEstateHeatingElement) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateFlatHeatingSourceChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatHeatingSources || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatHeatingSources',
      itemValue.map((i) => ({
        parameterRealEstateHeatingSource: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeatingSource.id || f.parameterRealEstateHeatingSource) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateFlatWaterHeatSourceChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatWaterHeatSources || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWaterHeatSources',
      itemValue.map((i) => ({
        parameterRealEstateWaterHeatSource: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateWaterHeatSource.id || f.parameterRealEstateWaterHeatSource) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateGasChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatGases || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatGases',
      itemValue.map((i) => ({
        parameterRealEstateGas: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateGas.id || f.parameterRealEstateGas) === i.value)?.id,
      }))
    );
  };

  const handleRealEstateElectricityChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatElectricities || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatElectricities',
      itemValue.map((i) => ({
        parameterRealEstateElectricity: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateElectricity.id || f.parameterRealEstateElectricity) === i.value
        )?.id,
      }))
    );
  };

  const handleFlatParkingChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateParking', itemValue?.value);
  };

  const handleFurnishedChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateFurnished', itemValue?.value);
  };

  const handleProtectionChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateProtection', itemValue?.value);
  };

  const handleSurroundingsTypeChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateSurroundingsType', itemValue?.value);
  };

  const handleRoadTypeChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatRoadTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatRoadTypes',
      itemValue.map((i) => ({
        parameterRealEstateRoadType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateRoadType.id || f.parameterRealEstateRoadType) === i.value
        )?.id,
      }))
    );
  };

  const handleTransportChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatTransports || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatTransports',
      itemValue.map((i) => ({
        parameterRealEstateTransport: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTransport.id || f.parameterRealEstateTransport) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateWasteChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatWastes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWastes',
      itemValue.map((i) => {
        return {
          parameterRealEstateWaste: i.value,
          id: currentValues.find((f: any) => (f.parameterRealEstateWaste.id || f.parameterRealEstateWaste) === i.value)
            ?.id,
        };
      })
    );
  };

  const handleRealEstateWaterChange = (value: OnChangeValue<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatWaters || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWaters',
      itemValue.map((i) => ({
        parameterRealEstateWater: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateWater.id || f.parameterRealEstateWater) === i.value)
          ?.id,
      }))
    );
  };

  const handleEnergyEfficiencyRatingChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateEnergyEfficiencyRating', itemValue?.value);
  };

  const handleTelecommucationChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const currentValues =
      props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatTelecommunications || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatTelecommunications',
      itemValue.map((i) => ({
        parameterRealEstateTelecommunication: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTelecommunication.id || f.parameterRealEstateTelecommunication) === i.value
        )?.id,
      }))
    );
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  const showAnnuity = (val?: string): boolean | undefined => {
    return ownershipTypeOptions.find((o) => o.value === val)?.annuity;
  };

  return (
    <>
      <div className="pt-4 pb-3">
        <h3 className="mb-4 font-weight-normal">Základní informace</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Dispozice{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateFlatType') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateFlatType">
            <Select
              isClearable
              isDisabled={props.readOnly}
              required
              size="md"
              name="parameterRealEstateFlatType"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatType}
              options={flatTypeOptions}
              onChange={handleFlatTypeChange}
              value={
                flatTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateFlatType)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatType as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Budova{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateBuildingConstruction') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateBuildingConstruction">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateBuildingConstruction"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateBuildingConstruction}
              options={buildingConstructionTypeOptions}
              onChange={handleFlatBuildingConstructionChange}
              value={
                buildingConstructionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateBuildingConstruction)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateBuildingConstruction && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateBuildingConstruction as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Stav{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateObjectStatus') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateObjectStatus">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateObjectStatus"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateObjectStatus}
              options={objectStatusTypeOptions}
              onChange={handleFlatObjectStatusChange}
              value={
                objectStatusTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateObjectStatus)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateObjectStatus && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateObjectStatus as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ bytu{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateFlatClass') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateFlatClass">
            <Select
              isClearable
              isDisabled={props.readOnly}
              required
              size="md"
              name="parameterRealEstateFlatClass"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatClass}
              options={flatClassOptions}
              onChange={handleFlatClassChange}
              value={
                flatClassOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateFlatClass)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatClass && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatClass as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <FormGroup
        readOnly={props.readOnly}
        type="number"
        label="Počet podlaží"
        required={arrKeyExists(props.requiredParametersFields, 'numberOfFloors')}
        error={props.errors.auctionParameterRealEstateFlat_numberOfFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateFlat.numberOfFloors"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateFlat.numberOfFloors}
        dataTestId="auctionParameterRealEstateFlat.numberOfFloors"
      />

      <FormGroup
        type="number"
        label="Počet podzemních podlaží"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateFlat_undergroundFloors as string}
        labelClassName="text-left"
        required={arrKeyExists(props.requiredParametersFields, 'undergroundFloors')}
        name="auctionParameterRealEstateFlat.undergroundFloors"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateFlat.undergroundFloors}
        dataTestId="auctionParameterRealEstateFlat.undergroundFloors"
      />

      <FormGroup
        readOnly={props.readOnly}
        type="number"
        label="Umístění podlaží"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateFlat_floor as string}
        name="auctionParameterRealEstateFlat.floor"
        required={arrKeyExists(props.requiredParametersFields, 'floor')}
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateFlat.floor}
        dataTestId="auctionParameterRealEstateFlat.floor"
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Vlastnictví{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateOwnership') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateOwnership">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateOwnership"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateOwnership}
              options={ownershipTypeOptions}
              onChange={handleFlatOwnershipChange}
              value={
                ownershipTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateOwnership)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateOwnership && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateOwnership as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <BooleanParameter
        label="Převod do OV"
        dataTestId="auctionParameterRealEstateFlat.personal"
        name="auctionParameterRealEstateFlat.personal"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.personal}
        errors={props.errors.auctionParameterRealEstateFlat_personal}
        required={arrKeyExists(props.requiredParametersFields, 'personal')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.personal', val)}
      />

      <BooleanParameter
        label="Výtah"
        dataTestId="auctionParameterRealEstateFlat.elevator"
        name="auctionParameterRealEstateFlat.elevator"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.elevator}
        errors={props.errors.auctionParameterRealEstateFlat_elevator}
        required={arrKeyExists(props.requiredParametersFields, 'elevator')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.elevator', val)}
      />

      <BooleanParameter
        label="Bezbariérový přístup"
        dataTestId="auctionParameterRealEstateFlat.easyAccess"
        name="auctionParameterRealEstateFlat.easyAccess"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.easyAccess}
        errors={props.errors.auctionParameterRealEstateFlat_easyAccess}
        required={arrKeyExists(props.requiredParametersFields, 'easyAccess')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.easyAccess', val)}
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Umístění{arrKeyExists(props.requiredParametersFields, 'parameterRealEstatePlacement') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePlacement">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstatePlacement"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstatePlacement}
              options={placementTypeOptions}
              onChange={handlePlacementChange}
              value={
                placementTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstatePlacement)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstatePlacement && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstatePlacement as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Okolní zástavba
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateSurroundingsType') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateSurroundingsType">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateSurroundingsType"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateSurroundingsType}
              options={surroundingsTypeOptions}
              onChange={handleSurroundingsTypeChange}
              value={
                surroundingsTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateSurroundingsType)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateSurroundingsType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateSurroundingsType as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Ochrana{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateProtection') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateProtection">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateProtection"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateProtection}
              options={protectionTypeOptions}
              onChange={handleProtectionChange}
              value={
                protectionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateProtection)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateProtection && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateProtection as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Plochy</h3>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          name="auctionParameterRealEstateFlat.usableArea"
          error={props.errors.auctionParameterRealEstateFlat_usableArea as string}
          thousandSeparator
          label="Plocha užitná"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          required={arrKeyExists(props.requiredParametersFields, 'usableArea')}
          value={props.values.auctionParameterRealEstateFlat.usableArea}
          onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.usableArea', parseInt(val.value))}
          dataTestId="auctionParameterRealEstateFlat.usableArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          name="auctionParameterRealEstateFlat.builtUpArea"
          error={props.errors.auctionParameterRealEstateFlat_builtUpArea as string}
          thousandSeparator
          label="Plocha zastavěná"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          required={arrKeyExists(props.requiredParametersFields, 'builtUpArea')}
          value={props.values.auctionParameterRealEstateFlat.builtUpArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateFlat.builtUpArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateFlat.builtUpArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          name="auctionParameterRealEstateFlat.gardenArea"
          error={props.errors.auctionParameterRealEstateFlat_gardenArea as string}
          thousandSeparator
          label="Plocha zahrady"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          value={props.values.auctionParameterRealEstateFlat.gardenArea}
          required={arrKeyExists(props.requiredParametersFields, 'gardenArea')}
          onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.gardenArea', parseInt(val.value))}
          dataTestId="auctionParameterRealEstateFlat.gardenArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          name="auctionParameterRealEstateFlat.floorArea"
          error={props.errors.auctionParameterRealEstateFlat_floorArea as string}
          thousandSeparator
          label="Plocha podlahová"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          value={props.values.auctionParameterRealEstateFlat.floorArea}
          required={arrKeyExists(props.requiredParametersFields, 'floorArea')}
          onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.floorArea', parseInt(val.value))}
          dataTestId="auctionParameterRealEstateFlat.floorArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Vybavení</h3>
      </div>

      <BooleanParameter
        label="Balkon"
        dataTestId="auctionParameterRealEstateFlat.balcony"
        name="auctionParameterRealEstateFlat.balcony"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.balcony}
        errors={props.errors.auctionParameterRealEstateFlat_balcony}
        required={arrKeyExists(props.requiredParametersFields, 'balcony')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.balcony', val)}
      />

      {props.values.auctionParameterRealEstateFlat.balcony === 'yes' && (
        <Form.Group className="d-flex align-items-center" style={{ marginTop: -5 }}>
          <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.balcony">
            <div className="d-flex align-items-center">
              <FormGroup
                readOnly={props.readOnly}
                name="auctionParameterRealEstateFlat.balconyArea"
                thousandSeparator
                label="Plocha balkonu"
                error={props.errors.auctionParameterRealEstateFlat_balconyArea as string}
                className="mt-0 mb-0"
                labelClassName="text-left"
                controlClassName="w-max-205"
                required={arrKeyExists(props.requiredParametersFields, 'balconyArea')}
                value={props.values.auctionParameterRealEstateFlat.balconyArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateFlat.balconyArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateFlat.balconyArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          </div>
        </Form.Group>
      )}

      <BooleanParameter
        label="Lodžie"
        dataTestId="auctionParameterRealEstateFlat.loggia"
        name="auctionParameterRealEstateFlat.loggia"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.loggia}
        errors={props.errors.auctionParameterRealEstateFlat_loggia}
        required={arrKeyExists(props.requiredParametersFields, 'loggia')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.loggia', val)}
      />

      {props.values.auctionParameterRealEstateFlat.loggia === 'yes' && (
        <Form.Group className="d-flex align-items-center" style={{ marginTop: -5 }}>
          <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.loggia">
            <div className="d-flex align-items-center">
              <FormGroup
                readOnly={props.readOnly}
                name="auctionParameterRealEstateFlat.loggiaArea"
                thousandSeparator
                label="Plocha lodžie"
                error={props.errors.auctionParameterRealEstateFlat_loggiaArea as string}
                className="mt-0 mb-0"
                labelClassName="text-left"
                controlClassName="w-max-205"
                required={arrKeyExists(props.requiredParametersFields, 'loggiaArea')}
                value={props.values.auctionParameterRealEstateFlat.loggiaArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateFlat.loggiaArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateFlat.loggiaArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          </div>
        </Form.Group>
      )}

      <BooleanParameter
        label="Terasa"
        dataTestId="auctionParameterRealEstateFlat.terrace"
        name="auctionParameterRealEstateFlat.terrace"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.terrace}
        errors={props.errors.auctionParameterRealEstateFlat_terrace}
        required={arrKeyExists(props.requiredParametersFields, 'terrace')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.terrace', val)}
      />

      {props.values.auctionParameterRealEstateFlat.terrace === 'yes' && (
        <Form.Group className="d-flex align-items-center" style={{ marginTop: -5 }}>
          <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.terrace">
            <div className="d-flex align-items-center">
              <FormGroup
                readOnly={props.readOnly}
                name="auctionParameterRealEstateFlat.terraceArea"
                thousandSeparator
                label="Plocha terasy"
                error={props.errors.auctionParameterRealEstateFlat_terraceArea as string}
                className="mt-0 mb-2"
                labelClassName="text-left"
                controlClassName="w-max-205"
                type="number"
                required={arrKeyExists(props.requiredParametersFields, 'terraceArea')}
                value={props.values.auctionParameterRealEstateFlat.terraceArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateFlat.terraceArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateFlat.terraceArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          </div>
        </Form.Group>
      )}

      <BooleanParameter
        label="Sklep"
        dataTestId="auctionParameterRealEstateFlat.cellar"
        name="auctionParameterRealEstateFlat.cellar"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.cellar}
        errors={props.errors.auctionParameterRealEstateFlat_cellar}
        required={arrKeyExists(props.requiredParametersFields, 'cellar')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.cellar', val)}
      />

      {props.values.auctionParameterRealEstateFlat.cellar === 'yes' && (
        <Form.Group className="d-flex align-items-center" style={{ marginTop: -5 }}>
          <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.cellar">
            <div className="d-flex align-items-center">
              <FormGroup
                readOnly={props.readOnly}
                name="auctionParameterRealEstateFlat.cellarArea"
                thousandSeparator
                label="Plocha sklepu"
                error={props.errors.auctionParameterRealEstateFlat_cellarArea as string}
                className="mt-0 mb-0"
                labelClassName="text-left"
                controlClassName="w-max-205"
                type="number"
                required={arrKeyExists(props.requiredParametersFields, 'cellarArea')}
                value={props.values.auctionParameterRealEstateFlat.cellarArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateFlat.cellarArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateFlat.cellarArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          </div>
        </Form.Group>
      )}

      <BooleanParameter
        label="Bazén"
        dataTestId="auctionParameterRealEstateFlat.basin"
        name="auctionParameterRealEstateFlat.basin"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.basin}
        errors={props.errors.auctionParameterRealEstateFlat_basin}
        required={arrKeyExists(props.requiredParametersFields, 'basin')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.basin', val)}
      />

      {props.values.auctionParameterRealEstateFlat.basin === 'yes' && (
        <Form.Group className="d-flex align-items-center" style={{ marginTop: -5 }}>
          <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.basin">
            <div className="d-flex align-items-center">
              <FormGroup
                readOnly={props.readOnly}
                name="auctionParameterRealEstateFlat.basinArea"
                thousandSeparator
                label="Plocha bazénu"
                error={props.errors.auctionParameterRealEstateFlat_basinArea as string}
                className="mt-0 mb-0"
                labelClassName="text-left"
                controlClassName="w-max-205"
                required={arrKeyExists(props.requiredParametersFields, 'basinArea')}
                value={props.values.auctionParameterRealEstateFlat.basinArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateFlat.basinArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateFlat.basinArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          </div>
        </Form.Group>
      )}

      <BooleanParameter
        label="Parkování"
        dataTestId="auctionParameterRealEstateFlat.parkingExists"
        name="auctionParameterRealEstateFlat.parkingExists"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.parkingExists}
        errors={props.errors.auctionParameterRealEstateFlat_parkingExists}
        required={arrKeyExists(props.requiredParametersFields, 'parkingExists')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.parkingExists', val)}
      />

      {props.values.auctionParameterRealEstateFlat.parkingExists === 'yes' && (
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-left">Typ parkovacího místa *</Form.Label>
          <div className="f-inline-control">
            <div className="w-max-500" data-test-id="parameterRealEstateParking">
              <Select
                isClearable
                isDisabled={props.readOnly}
                size="md"
                name="parameterRealEstateParking"
                isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateParking}
                options={parkingTypeOptions}
                onChange={handleFlatParkingChange}
                value={
                  parkingTypeOptions.find(
                    (i) =>
                      i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateParking)
                  ) || null
                }
              />
              {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateParking && (
                <ControlFeedback type="invalid">
                  {props.errors.auctionParameterRealEstateFlat_parameterRealEstateParking as string}
                </ControlFeedback>
              )}
            </div>
          </div>
        </Form.Group>
      )}

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Termíny</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Nastěhování{arrKeyExists(props.requiredParametersFields, 'readyDate') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <DatePickerInput
            isNullable
            name="auctionParameterRealEstateFlat.readyDate"
            className={classNames([
              'form-control',
              'w-max-205',
              { 'is-invalid': !!props.errors.auctionParameterRealEstateFlat_readyDate },
            ])}
            value={
              !!props.values.auctionParameterRealEstateFlat.readyDate
                ? moment(props.values.auctionParameterRealEstateFlat.readyDate).toDate()
                : undefined
            }
            onChange={(val) =>
              props.setFieldValue(
                'auctionParameterRealEstateFlat.readyDate',
                val instanceof Date ? getRequestDateTimeFormat(val) : undefined
              )
            }
            data-test-id="auctionParameterRealEstateFlat.readyDate"
          />
          {!!props.errors.auctionParameterRealEstateFlat_readyDate && (
            <ControlFeedback type="invalid">
              {props.errors.auctionParameterRealEstateFlat_readyDate as string}
            </ControlFeedback>
          )}
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zahájení výstavby{arrKeyExists(props.requiredParametersFields, 'beginningDate') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <DatePickerInput
            isNullable
            name="auctionParameterRealEstateFlat.beginningDate"
            className={classNames([
              'form-control',
              'w-max-205',
              { 'is-invalid': !!props.errors.auctionParameterRealEstateFlat_beginningDate },
            ])}
            value={
              !!props.values.auctionParameterRealEstateFlat.beginningDate
                ? moment(props.values.auctionParameterRealEstateFlat.beginningDate).toDate()
                : undefined
            }
            onChange={(val) =>
              props.setFieldValue(
                'auctionParameterRealEstateFlat.beginningDate',
                val instanceof Date ? getRequestDateTimeFormat(val) : undefined
              )
            }
            data-test-id="auctionParameterRealEstateFlat.beginningDate"
          />
          {!!props.errors.auctionParameterRealEstateFlat_beginningDate && (
            <ControlFeedback type="invalid">
              {props.errors.auctionParameterRealEstateFlat_beginningDate as string}
            </ControlFeedback>
          )}
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Ukončení výstavby{arrKeyExists(props.requiredParametersFields, 'finishDate') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <DatePickerInput
            isNullable
            name="auctionParameterRealEstateFlat.finishDate"
            className={classNames([
              'form-control',
              'w-max-205',
              { 'is-invalid': !!props.errors.auctionParameterRealEstateFlat_finishDate },
            ])}
            value={
              !!props.values.auctionParameterRealEstateFlat.finishDate
                ? moment(props.values.auctionParameterRealEstateFlat.finishDate).toDate()
                : undefined
            }
            onChange={(val) =>
              props.setFieldValue(
                'auctionParameterRealEstateFlat.finishDate',
                val instanceof Date ? getRequestDateTimeFormat(val) : undefined
              )
            }
            data-test-id="auctionParameterRealEstateFlat.finishDate"
          />
          {!!props.errors.auctionParameterRealEstateFlat_finishDate && (
            <ControlFeedback type="invalid">
              {props.errors.auctionParameterRealEstateFlat_finishDate as string}
            </ControlFeedback>
          )}
        </div>
      </Form.Group>

      <FormGroup
        readOnly={props.readOnly}
        type="number"
        label="Rok výstavby"
        controlClassName="w-max-205"
        labelClassName="text-left"
        required={arrKeyExists(props.requiredParametersFields, 'objectYear')}
        error={props.errors.auctionParameterRealEstateFlat_objectYear as string}
        name="auctionParameterRealEstateFlat.objectYear"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateFlat.objectYear}
        dataTestId="auctionParameterRealEstateFlat.objectYear"
      />

      <FormGroup
        type="number"
        label="Rok kolaudace"
        labelClassName="text-left"
        controlClassName="w-max-205"
        name="auctionParameterRealEstateFlat.acceptanceYear"
        readOnly={props.readOnly}
        required={arrKeyExists(props.requiredParametersFields, 'acceptanceYear')}
        error={props.errors.auctionParameterRealEstateFlat_acceptanceYear as string}
        value={props.values.auctionParameterRealEstateFlat.acceptanceYear}
        onChange={props.handleChange}
        dataTestId="auctionParameterRealEstateFlat.acceptanceYear"
      />

      <FormGroup
        readOnly={props.readOnly}
        type="number"
        label="Rok rekonstrukce"
        controlClassName="w-max-205"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateFlat_reconstructionYear as string}
        name="auctionParameterRealEstateFlat.reconstructionYear"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'reconstructionYear')}
        value={props.values.auctionParameterRealEstateFlat.reconstructionYear}
        dataTestId="auctionParameterRealEstateFlat.reconstructionYear"
      />

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Ostatní</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Třída energetické náročnosti
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateEnergyEfficiencyRating') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateEnergyEfficiencyRating">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateEnergyEfficiencyRating"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateEnergyEfficiencyRating}
              options={energyEfficiencyRatingTypeOptions}
              onChange={handleEnergyEfficiencyRatingChange}
              value={
                energyEfficiencyRatingTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateFlat.parameterRealEstateEnergyEfficiencyRating
                    )
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateEnergyEfficiencyRating && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateEnergyEfficiencyRating as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj vody{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatWaters') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatWaters">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatWaters"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWaters}
              options={waterTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWaterChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWaters || []).map(
                (v: { parameterRealEstateWater: string | number }) =>
                  waterTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWater))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWaters && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWaters as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ studny
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatWellTypes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWellTypet">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatWellTypes"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWellTypes}
              options={wellTypeOptions}
              onChange={(val: BaseType) => handleRealEstateFlatWellTypesChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWellTypes || []).map(
                (v: { parameterRealEstateWellType: string | number }) =>
                  wellTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWellType))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWellTypes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWellTypes as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Topné těleso
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatHeatingElement') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHeatingElement">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatHeatingElements"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatHeatingElements}
              options={heatingElementOptions}
              onChange={(val: BaseType) => handleRealEstateFlatHeatingElementChange(val)}
              value={(
                props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatHeatingElements || []
              ).map((v: { parameterRealEstateHeatingElement: string | number }) =>
                heatingElementOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeatingElement))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatHeatingElements && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatHeatingElements as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj topení
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatHeatingSources') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHeatingSource">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatHeatingSources"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatHeatingSources}
              options={heatingSourceOptions}
              onChange={(val: BaseType) => handleRealEstateFlatHeatingSourceChange(val)}
              value={(
                props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatHeatingSources || []
              ).map((v: { parameterRealEstateHeatingSource: string | number }) =>
                heatingSourceOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeatingSource))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatHeatingSources && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatHeatingSources as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj teplé vody
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatWaterHeatSources') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWaterHeatSource">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatWaterHeatSources"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWaterHeatSources}
              options={waterHeatSourceOptions}
              onChange={(val: BaseType) => handleRealEstateFlatWaterHeatSourceChange(val)}
              value={(
                props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWaterHeatSources || []
              ).map((v: { parameterRealEstateWaterHeatSource: string | number }) =>
                waterHeatSourceOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaterHeatSource))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWaterHeatSources && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWaterHeatSources as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Rozvod plynu{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatGases') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatGases">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatGases"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatGases}
              options={gasTypeOptions}
              onChange={(val: BaseType) => handleRealEstateGasChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatGases || []).map(
                (v: { parameterRealEstateGas: string | number }) =>
                  gasTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateGas))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatGases && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatGases as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ odpadu{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatWastes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatWastes">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatWastes"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWastes}
              options={wasteTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWasteChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWastes || []).map(
                (v: { parameterRealEstateWaste: string | number }) =>
                  wasteTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaste))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWastes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWastes as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Příjezdová komunikace
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatRoadTypes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateRoadType">
            <Select
              isMulti
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateRoadType"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateRoadType}
              options={roadTypeOptions}
              onChange={(val: BaseType) => handleRoadTypeChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatRoadTypes || []).map(
                (v: { parameterRealEstateRoadType: string | number }) =>
                  roadTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateRoadType))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateRoadType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateRoadType as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Telekomunikace
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatTelecommunications') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatTelecommunications">
            <Select
              isMulti
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="auctionParameterRealEstateFlatTelecommunications"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTelecommunications}
              options={telecommucationTypeOptions}
              onChange={(val: BaseType) => handleTelecommucationChange(val)}
              value={(
                props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatTelecommunications || []
              ).map((v: { parameterRealEstateTelecommunication: string | number }) =>
                telecommucationTypeOptions.find(
                  (f) => f.value === getSelectValue(v.parameterRealEstateTelecommunication)
                )
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTelecommunications && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTelecommunications as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Elektřina
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatElectricities') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatElectricities">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatElectricities"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatElectricities}
              options={electricityTypeOptions}
              onChange={(val: BaseType) => handleRealEstateElectricityChange(val)}
              value={(
                props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatElectricities || []
              ).map((v: { parameterRealEstateElectricity: string | number }) =>
                electricityTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateElectricity))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatElectricities && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatElectricities as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Doprava{arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatTransports') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatTransports">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatTransports"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTransports}
              options={transportTypeOptions}
              onChange={(val: BaseType) => handleTransportChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatTransports || []).map(
                (v: { parameterRealEstateTransport: string | number }) =>
                  transportTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateTransport))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTransports && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTransports as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <BooleanParameter
        label="Fotovoltaika"
        dataTestId="auctionParameterRealEstateFlat.ftvPanels"
        name="auctionParameterRealEstateFlat.ftvPanels"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.ftvPanels}
        errors={props.errors.auctionParameterRealEstateFlat_ftvPanels}
        required={arrKeyExists(props.requiredParametersFields, 'ftvPanels')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.ftvPanels', val)}
      />

      <BooleanParameter
        label="Solární panely"
        dataTestId="auctionParameterRealEstateFlat.solarPanels"
        name="auctionParameterRealEstateFlat.solarPanels"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateFlat?.solarPanels}
        errors={props.errors.auctionParameterRealEstateFlat_solarPanels}
        required={arrKeyExists(props.requiredParametersFields, 'solarPanels')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.solarPanels', val)}
      />

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <FormGroup
        type="text"
        label="Poskytovatel innternetového připojení"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateFlat.internetConnectionProvider"
        value={props.values.auctionParameterRealEstateFlat.internetConnectionProvider}
        error={props.errors.auctionParameterRealEstateFlat_internetConnectionProvider as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'internetConnectionProvider')}
        dataTestId="auctionParameterRealEstateFlat.internetConnectionProvider"
      />

      <FormGroup
        type="number"
        label="Rychlost internetového připojení (Mbit/s)"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateFlat.internetConnectionSpeed"
        value={props.values.auctionParameterRealEstateFlat.internetConnectionSpeed}
        error={props.errors.auctionParameterRealEstateFlat_internetConnectionSpeed as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'internetConnectionSpeed')}
        dataTestId="auctionParameterRealEstateFlat.internetConnectionSpeed"
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ internetového připojení
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateFlatInternetConnectionTypes')
            ? ' *'
            : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatInternetConnectionTypes">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatInternetConnectionTypes"
              isInvalid={
                !!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatInternetConnectionTypes
              }
              options={internetConnectionTypeOptions}
              onChange={(val: BaseType) => handleInternetConnectionTypeChange(val)}
              value={(
                props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatInternetConnectionTypes || []
              ).map((v: { parameterRealEstateInternetConnectionType: string | number }) =>
                internetConnectionTypeOptions.find(
                  (f) => f.value === getSelectValue(v.parameterRealEstateInternetConnectionType)
                )
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatInternetConnectionTypes && (
              <ControlFeedback type="invalid">
                {
                  props.errors
                    .auctionParameterRealEstateFlat_auctionParameterRealEstateFlatInternetConnectionTypes as string
                }
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      {showAnnuity(getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateOwnership)) && (
        <div className="d-flex align-items-center mb-35">
          <FormGroup
            readOnly={props.readOnly}
            name="auctionParameterRealEstateFlat.annuity"
            thousandSeparator
            label="Anuita"
            error={props.errors.auctionParameterRealEstateFlat_annuity as string}
            className="mt-0 mb-0"
            labelClassName="text-left"
            controlClassName="w-max-205"
            value={props.values.auctionParameterRealEstateFlat.annuity}
            required={arrKeyExists(props.requiredParametersFields, 'annuity')}
            onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.annuity', parseInt(val.value))}
            dataTestId="auctionParameterRealEstateFlat.annuity"
          />
        </div>
      )}

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          thousandSeparator
          name="auctionParameterRealEstateFlat.ceilingHeight"
          label="Výška stropu"
          error={props.errors.auctionParameterRealEstateFlat_ceilingHeight as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'ceilingHeight')}
          value={props.values.auctionParameterRealEstateFlat.ceilingHeight}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateFlat.ceilingHeight', parseFloat(val.value))
          }
          dataTestId="auctionParameterRealEstateFlat.ceilingHeight"
        />
        <span className="f-size-12 f-weight-400">m</span>
      </div>

      <FormGroup
        readOnly={props.readOnly}
        label="Náklady na bydlení"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateFlat_costOfLiving as string}
        name="auctionParameterRealEstateFlat.costOfLiving"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'costOfLiving')}
        value={props.values.auctionParameterRealEstateFlat.costOfLiving}
        dataTestId="auctionParameterRealEstateFlat.costOfLiving"
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Vybavení{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateFurnished') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateFurnished">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateFurnished"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFurnished}
              options={furnishedTypeOptions}
              onChange={handleFurnishedChange}
              value={
                furnishedTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateFurnished)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFurnished && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateFurnished as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Jističe{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateCircuitBreaker') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateCircuitBreaker">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateCircuitBreaker"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateCircuitBreaker}
              options={circuitBreakerOptions}
              onChange={handlerCircuitBreakerChange}
              value={
                circuitBreakerOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateCircuitBreaker)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateCircuitBreaker && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateCircuitBreaker as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Počet fází{arrKeyExists(props.requiredParametersFields, 'parameterRealEstatePhaseDistribution') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePhaseDistribution">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstatePhaseDistribution"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstatePhaseDistribution}
              options={phaseDistributionOptions}
              onChange={handlerPhaseDistributionChange}
              value={
                phaseDistributionOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstatePhaseDistribution)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstatePhaseDistribution && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstatePhaseDistribution as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <FormGroup
        type="text"
        label="Klíčová slova (oddělená středníkem)"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateFlat.keywords"
        value={props.values.auctionParameterRealEstateFlat.keywords}
        error={props.errors.auctionParameterRealEstateFlat_keywords as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'keywords')}
        dataTestId="auctionParameterRealEstateFlat.keywords"
      />

      <div className="pb-3">
        <hr />
      </div>
    </>
  );
};
