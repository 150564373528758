import React from 'react';
import { useParams } from 'react-router-dom';

import * as routes from '@routes';
import { AdminBoxContent, Button } from '@components';
import { usePageStore } from '@stores';
import AdministratorForm from './AdministratorForm';

const Administrator: React.FC = () => {
  const { id } = useParams<{ id: string}>();
  const pageState = usePageStore();
  const page = pageState.getPage(
    !!id ? routes.admin.SETTINGS_ADMINISTRATORS_UPDATE : routes.admin.SETTINGS_ADMINISTRATORS_CREATE
  );

  return (
    <div>
      <div>
        <h2>{page?.name}</h2>
      </div>
      <AdminBoxContent className="p-0">
        <div className="w-min-500">
          <div className="component-admin-box-content overlow-x-auto">
            <AdministratorForm id={id as any} />
          </div>
        </div>
      </AdminBoxContent>
      <Button to={pageState.getPagePath(routes.admin.SETTINGS_ADMINISTRATORS)}>Zpět na administrátory</Button>
    </div>
  );
};

export default Administrator;
