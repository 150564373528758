import React from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { Col, Row } from 'react-bootstrap';
import { BaseSelectType } from '@types';

interface Props {
  perPageChoiceEnabled?: boolean;
  page: number;
  perPage: number;
  totalRows: number;
  onPageChange: (page: number, perPage: number) => void;
}

const Pagination: React.FC<Props> = (props) => {
  const pagesCount = Math.ceil(props.totalRows / props.perPage);
  const [perPageOptions] = React.useState<BaseSelectType[]>([
    { label: '15', value: 15 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]);

  const handlePerPageChange = (value: any) => {
    props.onPageChange(props.page, value.value);
  };

  return (
    <div>
      <Row>
        <Col xs={12} md={2} lg={2}>
          {props.perPageChoiceEnabled ? (
            <div className="w-max-140">
              Výsledků na stránku:
              <div className="w-70 mt-2">
                <Select
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  isClearable={false}
                  options={perPageOptions}
                  onChange={handlePerPageChange}
                  value={perPageOptions.find((i) => i.value === props.perPage) || null}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </Col>

        <Col xs={12} md={8} lg={8}>
          <ReactPaginate
            forcePage={props.page - 1}
            pageCount={pagesCount}
            pageRangeDisplayed={4}
            marginPagesDisplayed={1}
            containerClassName="pagination"
            previousLabel={<FontAwesomeIcon icon={faArrowLeft} size="xs" />}
            nextLabel={<FontAwesomeIcon icon={faArrowRight} size="xs" />}
            onPageChange={(selectedItem) => props.onPageChange(selectedItem.selected + 1, props.perPage)}
          />
        </Col>
        <Col xs={12} md={2} lg={2} />
      </Row>
    </div>
  );
};

export default Pagination;
