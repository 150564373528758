import React, { useEffect, useState } from 'react';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import { BasePreloader, Pagination, SEO } from '@components';
import { NewsType } from '@types';

import { useNewsApi } from '@api/news';
import { NewsItem } from './NewsItem';

export const NewsList = () => {
  const newsApi = useNewsApi();
  const [loaded, setLoaded] = useState(false);
  const [news, setNews] = useState<Array<NewsType>>([]);
  const [dataLen, setDataLen] = useState(0);
  const defaultQuery = React.useMemo(
    () => ({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 10),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  useEffect(() => {
    loadData();
    return () => newsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const loadData = async () => {
    setLoaded(false);
    try {
      const response = await newsApi.webNewsList(query);
      setNews(response.data.data);
      setDataLen(response.data.total as number);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  return (
    <>
      <SEO title="Aktuality" />
      <h1 className="news-page-title">Aktuality</h1>
      {!loaded ? (
        <div className="d-flex pb-5 pt-2 align-items-center justify-content-center">
          <BasePreloader />
        </div>
      ) : (
        <div className="component-front-news-list">
          <div className="list-items">
            {news.map((item) => (
              <NewsItem item={item} />
            ))}
          </div>
          {dataLen > query.perPage && (
            <div className="mt-2">
              <Pagination
                page={query.page}
                perPage={query.perPage}
                totalRows={dataLen}
                onPageChange={(page) => setQuery({ ...query, page: page }, 'push')}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
