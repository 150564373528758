import React from 'react';
import classNames from 'classnames';

interface Props {
  title: string;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  children?: React.ReactNode;
}

const BaseFrontSidebarBox: React.FC<Props> = (props) => (
  <div className={classNames(['component-base-front-sidebar-box', props.className])}>
    <div className={classNames(['box-header', props.headerClassName])}>{props.title}</div>
    <div className={classNames(['box-content', props.contentClassName])}>{props.children}</div>
  </div>
);

export default BaseFrontSidebarBox;
