import React, { useState } from 'react';
import { OnChangeValue } from 'react-select';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { PaymentsFilters } from '@types';
import { useInvoiceApi } from '@api/invoice';
import { AdminFiltersBox, Select } from '@components';
import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';

import { InvoicesSections } from '../InvoicesPage';

interface Props {
  values: PaymentsFilters;
  onSubmit: (values: PaymentsFilters) => void;
}

type OptionType = {
  value: string;
  label: string;
};

const PaymentsDataFilter: React.FC<Props> = (props) => {
  const location = useLocation();
  const pageState = usePageStore();
  const invoiceApi = useInvoiceApi();
  const [paymentTypes, setPaymentTypes] = useState<OptionType[]>([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    setIsOpen(false);
    formik.resetForm();
    loadPaymentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const loadPaymentTypes = async () => {
    try {
      const res = await invoiceApi.getPaymentTypes();
      setPaymentTypes(res.data.data.map((i) => ({ value: i.type, label: i.translated })));
    } catch (err: any) {
      if (invoiceApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    props.onSubmit(formValues as PaymentsFilters);
  };

  const hasFilters = (): boolean => {
    return !!location.search && location.search !== `?section=${InvoicesSections.payments}`;
  };

  const handlePaymentTypeChange = (value: OnChangeValue<OptionType, boolean>) => {
    const itemValue = value as OptionType;
    formik.setFieldValue('paymentType', itemValue?.value);
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Číslo účtu</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="senderAccountNumber"
                  value={formik.values.senderAccountNumber || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Variabilní symbol</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="variableSymbol"
                  value={formik.values.variableSymbol || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Dražebník</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="senderName"
                  value={formik.values.senderName || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Předmět platby</Form.Label>
                <Select
                  isClearable={true}
                  options={paymentTypes}
                  value={paymentTypes.find((i) => i.value === formik.values.paymentType) || null}
                  onChange={(val) => handlePaymentTypeChange(val)}
                />
              </Form.Group>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit
          hasFilters={hasFilters()}
          onOpenClick={() => setIsOpen(true)}
          clearUrl={`${pageState.getPagePath(routes.admin.INVOICES)}?section=${InvoicesSections.payments}`}
        />
      </AdminFiltersBox>
    </Form>
  );
};

export default PaymentsDataFilter;
