import * as React from 'react';
import { isArray } from 'lodash';

import { UserPermission } from '@types';
import {
  useAuthFrontStore,
  useAuthStore,
  useCurrentUserPermissionCheck,
  useCurrentUserRoleChecks,
  usePageContext,
} from '@stores';

interface ConditionDefinition {
  isLoggedAdmin?: true | undefined;
  isLoggedFront?: true | undefined;
  permission?: UserPermission | Array<UserPermission>;
  role?: string | Array<string>;
  permissionGroupName?: string | null;
  permissionName?: string | Array<string>;
  disableSuperAdminOverride?: boolean;
  anyAdmin?: boolean;
  default?: boolean;
}

type Condition = ConditionDefinition | (() => boolean);

interface Options extends ConditionDefinition {
  and?: Array<Condition>;
  or?: Array<Condition>;
}

interface VisibilityHook {
  shouldBeVisible(options: Options): boolean;
}

const useVisibility = (): VisibilityHook => {
  let visibilityHook: VisibilityHook;

  const { isLogged: isLoggedAdmin } = useAuthStore();
  const { isLogged: isLoggedFront } = useAuthFrontStore();
  const { isUserOfRole, isOfAnyAdminRole, isOfRoleSuperAdmin } = useCurrentUserRoleChecks();
  const { hasPermission } = useCurrentUserPermissionCheck();
  const { activePage } = usePageContext();

  visibilityHook = {
    shouldBeVisible: (options: Options): boolean => {
      const superAdminOverrideEnabled = !options.disableSuperAdminOverride;
      if (superAdminOverrideEnabled && isOfRoleSuperAdmin()) {
        return true;
      }

      if (isArray(options.and) && options.and.length > 0) {
        return options.and
          .map((option) => {
            if (typeof option === 'function') {
              return option();
            }

            return visibilityHook.shouldBeVisible(option);
          })
          .reduce((a, b) => a && b, true);
      }

      if (isArray(options.or) && options.or.length > 0) {
        return options.or
          .map((option) => {
            if (typeof option === 'function') {
              return option();
            }

            return visibilityHook.shouldBeVisible(option);
          })
          .reduce((a, b) => a || b, false);
      }

      const permissionGroupName = options.permissionGroupName ? options.permissionGroupName : activePage?.routeName;

      if (typeof options.isLoggedAdmin === 'boolean' && options.isLoggedAdmin && !isLoggedAdmin) {
        return false;
      }

      if (typeof options.isLoggedFront === 'boolean' && options.isLoggedFront && !isLoggedFront) {
        return false;
      }

      if (options.anyAdmin) {
        return isOfAnyAdminRole();
      }

      const roles = isArray(options.role) ? options.role : [options.role];
      /* eslint-disable no-labels */
      checkRoles: {
        if (roles.filter((role) => role).length > 0) {
          for (const index in roles) {
            if (isUserOfRole(roles[index] as string)) {
              /* eslint-disable no-labels */
              break checkRoles;
            }
          }

          return false;
        }
      }

      const permissionNames = isArray(options.permissionName) ? options.permissionName : [options.permissionName];
      if (permissionNames.filter((permissionName) => permissionName).length > 0) {
        for (const index in permissionNames) {
          if (hasPermission(permissionNames[index] as string, permissionGroupName)) {
            return true;
          }
        }

        return false;
      }

      return typeof options.default === 'boolean' ? options.default : true;
    },
  };

  return visibilityHook;
};

interface Props extends Options {
  else?: React.ReactNode | (() => React.ReactNode);
  children?: React.ReactNode;
}

const Visible: React.FC<Props> = (props) => {
  const { shouldBeVisible } = useVisibility();

  if (shouldBeVisible(props)) {
    return <>{props.children}</>;
  }

  if (typeof props.else === 'function') {
    return props.else();
  } else if (props.else) {
    return props.else;
  }

  return null;
};

export { useVisibility };

export default Visible;
