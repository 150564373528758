import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { getName } from '@helpers/string';
import { BaseAPIResponse } from '@api/api';
import { useAuctionsApi } from '@api/auctions';
import SecurityFiltersForm from './SecurityFiltersForm';
import { AuctionUsersFilters, UserAuctionResponse } from '@types';
import { AdminBoxContent, BasePreloader, Button, Pagination } from '@components';

import { Panels as UserDocumentsPanel } from '../../../UserDocumentsPage/UserDocumentsPage';

import colorIcoConf from '@assets/images/color-ico-conf.svg';
import icoCheckGreen from '@assets/images/ico-check-green.svg';

export const SectionSecurity: React.FC = () => {
  const auctionsApi = useAuctionsApi();
  const location = useLocation();
  const pageState = usePageStore();
  const [dataLen, setDataLen] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState<UserAuctionResponse[]>([]);
  const defaultQuery = React.useMemo(
    () => ({
      s: withDefault(NumberParam, 2),
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 15),
      uniqueCode: withDefault(StringParam, undefined),
      specificSymbol: withDefault(StringParam, undefined),
      variableSymbol: withDefault(StringParam, undefined),
      preEmption: withDefault(BooleanParam, undefined),
      admitted: withDefault(BooleanParam, undefined),
      auctionSecurity: withDefault(ArrayParam, undefined),
      authorizationState: withDefault(StringParam, undefined),
      fullName: withDefault(StringParam, undefined),
      withoutWinner: withDefault(BooleanParam, undefined),
      status: withDefault(ArrayParam, undefined),
      number: withDefault(StringParam, undefined),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  useEffect(() => {
    setIsLoaded(false);
    loadItems();
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const loadItems = async () => {
    try {
      const res = await auctionsApi.getAuctionsSecurity({ ...query });
      setItems(res.data.data);
      setDataLen(res.data.total as number);
      setIsLoaded(true);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const getAuctionSecurity = (item: UserAuctionResponse) => {
    if (parseFloat(item.auction.cautionDeposit || '0') <= 0) {
      return 'Není vyžadována';
    }
    return item.auctionSecurity === 'none' || item.auctionSecurity === 'rejected' ? 'Ne' : 'Ano';
  };

  const downloadFile = async (
    func: (auctionId?: string | number, params?: AuctionUsersFilters) => BaseAPIResponse<Blob>,
    fileType: string
  ) => {
    try {
      const res = await func(undefined, { ...query });
      fileDownload(res.data, `export.${fileType}`);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const renderContent = () => {
    if (!isLoaded) {
      return (
        <AdminBoxContent>
          <div className="d-flex justify-content-center py-5">
            <BasePreloader />
          </div>
        </AdminBoxContent>
      );
    }

    return (
      <AdminBoxContent>
        <div className="btn-group d-flex justify-content-end pb-4">
          <div className="mr-2">
            <Button onClick={() => downloadFile(auctionsApi.getAuctionSecurityExcel, 'xlsx')}>Export do Excelu</Button>
          </div>
          <div>
            <Button onClick={() => downloadFile(auctionsApi.getAuctionSecurityGpc, 'gpc')}>Export do GPC</Button>
          </div>
        </div>

        <div className="component-admin-auctions-table">
          <Table className="border-bottom-0">
            <thead>
              <tr>
                <th className="text-nowrap">Jednací číslo</th>
                <th className="text-nowrap">Stav</th>
                <th className="text-nowrap">Jméno a příjmení</th>
                <th className="text-nowrap">Číslo účtu</th>
                <th className="text-nowrap">Specif. symbol</th>
                <th className="text-nowrap">Variabilní symbol</th>
                <th className="text-nowrap">Jistota</th>
                <th className="text-nowrap">Připuštěn</th>
                <th className="text-nowrap">Vítěz</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={`list-item-${index}`} className="border-top">
                  <td>
                    <Link
                      to={`${pageState.getPagePath(routes.admin.AUCTIONS_UPDATE, {
                        ':id': item.auction.id,
                      })}?back=admin-security`}
                    >
                      {item.auction.number}
                    </Link>
                  </td>
                  <td>{item.auction.statusTranslation}</td>
                  <td>
                    <span className="d-flex">
                      <Link
                        className="d-flex"
                        to={`${pageState.getPagePath(routes.admin.USER_DETAIL, {
                          ':id': item.user.id.toString(),
                        })}?back=admin-security`}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" />
                      </Link>
                      {!!item.user.company ? item.user.company : getName(item.user) || ''}
                    </span>
                  </td>
                  <td className="text-nowrap">{item.user.accountNumber || '-'}</td>
                  <td>{item.specificSymbol || '-'}</td>
                  <td>{item.variableSymbol || '-'}</td>
                  <td>
                    <span className="d-flex">
                      <Link
                        className="d-flex"
                        to={`${pageState.getPagePath(routes.admin.USER_DOCUMENTS, {
                          ':buyerId': item.user.id.toString(),
                          ':auctionId': item.auction.id.toString(),
                        })}?section=${UserDocumentsPanel.auctionSecurity}&back=admin-security`}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" />
                      </Link>
                      {getAuctionSecurity(item)}
                    </span>
                  </td>
                  <td>{item.admitted ? 'Ano' : 'Ne'}</td>
                  <td>{item.isWinner && <img src={icoCheckGreen} alt="is-winner" />}</td>
                </tr>
              ))}
              {items.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {dataLen > query.perPage && (
          <div className="mt-2">
            <Pagination
              page={query.page}
              perPage={query.perPage}
              totalRows={dataLen}
              perPageChoiceEnabled={true}
              onPageChange={(page, perPage) => setQuery({ ...query, page: page, perPage: perPage }, 'push')}
            />
          </div>
        )}
      </AdminBoxContent>
    );
  };

  return (
    <>
      <SecurityFiltersForm />
      {renderContent()}
    </>
  );
};
