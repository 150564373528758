import { useState, createElement, ReactNode } from 'react';
import { get, set, isArray, isPlainObject } from 'lodash';
import Cookies from 'js-cookie';
import { toast } from 'react-hot-toast';
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  CancelToken,
  InternalAxiosRequestConfig,
  AxiosHeaders,
} from 'axios';

import config from '@config';
import { BaseResponse } from '@types';

export const refreshTokenPath = () => `/token/refresh`;

export type BaseAPIResponse<T = any> = Promise<AxiosResponse<T>>;
export type APIResponse<Data extends any = undefined> = BaseAPIResponse<BaseResponse<Data>>;
export type APIResponseCustom<B = BaseResponse<any>> = BaseAPIResponse<B>;

export interface API {
  readonly request: AxiosInstance;
  readonly authRequest: AxiosInstance;
  readonly authFrontRequest: AxiosInstance;

  isCancel(value: any): boolean;
  prepareCancelToken(name: string, disabledCancel?: boolean): CancelToken;
  cancelToken(name: string): void;
  cancelAllRequests(): void;
}

export interface APICancelerType {
  [key: string]: Canceler;
}

export const useApi = (errorToastDisable?: boolean, successToastDisable?: boolean): API => {
  const [cancelTokens, setCancelTokens] = useState<APICancelerType>({});

  const getAxiosInstance = (config?: AxiosRequestConfig): AxiosInstance => {
    return axios.create(config);
  };

  const interceptAuthenticatedRequest = async (
    requestConfig: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    const headers = requestConfig.headers as AxiosHeaders;

    headers.set('Authorization', `Bearer ${Cookies.get(config.auth.accessTokenCookieName)}`);

    return requestConfig;
  };

  const interceptAuthenticatedFrontRequest = async (
    requestConfig: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    const headers = requestConfig.headers as AxiosHeaders;

    headers.set('Authorization', `Bearer ${Cookies.get(config.auth.accessTokenFrontCookieName)}`);

    return requestConfig;
  };

  const interceptSuccessResponse = <Data = any>(response: AxiosResponse<Data>): AxiosResponse<Data> => {
    // API maintenance reload
    if (get(response.data, 'maintenance') === true) {
      window.location.reload();
    }
    const message = get(response.data, 'message');
    if (!successToastDisable && !!message) {
      toast.success(message);
    }
    return response;
  };

  const prepareErrorMessages = (response: any) => {
    let messages: Array<ReactNode> = [];
    let hasErrorMessages: boolean = false;
    if (response?.data.message) {
      messages.push(response?.data.message);
    }
    const addErrorMessage = (errorMessage: string) => {
      if (!hasErrorMessages) {
        messages.push(createElement('div', { key: `breakline`, className: `toast-separator` }));
        hasErrorMessages = true;
      }
      messages.push(
        createElement(
          'div',
          {
            key: `error-${errorMessage}`,
            className: `toast-message`,
          },
          `- ${errorMessage}`
        )
      );
    };

    const errors = response?.data?.errors;
    if (isArray(errors)) {
      for (const index in errors) {
        addErrorMessage(errors[index]);
      }
    } else if (isPlainObject(errors)) {
      const keys = Object.keys(errors);
      for (const index in keys) {
        if (typeof errors[keys[index]] === 'string') {
          addErrorMessage(errors[keys[index]]);
        } else if (isArray(errors[keys[index]])) {
          for (const innerIndex in errors[keys[index]]) {
            if (typeof errors[keys[index]][innerIndex] === 'string') {
              addErrorMessage(errors[keys[index]][innerIndex]);
            }
          }
        }
      }
    }

    return {
      messages,
      timeout: messages.length < 3 ? 5000 : 1700 * messages.length,
    };
  };

  const interceptErrorResponse = async (err: any): Promise<any> => {
    const { messages, timeout } = prepareErrorMessages(err.response);
    if (err.config?.url !== refreshTokenPath() && messages.length > 0) {
      toast.error(messages, { duration: timeout });
    }
    throw err;
  };

  const interceptAuthenticatedErrorResponse = async (err: any): Promise<any> => {
    const status = get(err, 'response.status');
    const requestConfig = get(err, 'config');
    const _retry = get(config, 'status._retry');

    if (status === 401 && !_retry) {
      set(requestConfig, 'status._retry', true);

      const tokenResponse = await refreshAuthToken();

      Cookies.set(config.auth.accessTokenCookieName, tokenResponse.data.token);
      Cookies.set(config.auth.refreshTokenCookieName, tokenResponse.data.refresh_token);

      const authorizationHeader = `Bearer ${tokenResponse.data.token}`;
      set(requestConfig, 'headers.Authorization', authorizationHeader);

      return getAxiosInstance()(requestConfig);
    } else {
      const { messages, timeout } = prepareErrorMessages(err.response);
      if (!errorToastDisable && messages.length > 0) {
        toast.error(messages, { duration: timeout });
      }
    }

    throw err;
  };

  const interceptFrontAuthenticatedErrorResponse = async (err: any): Promise<any> => {
    const status = get(err, 'response.status');
    const requestConfig = get(err, 'config');
    const _retry = get(config, 'status._retry');

    if (status === 401 && !_retry) {
      set(requestConfig, 'status._retry', true);

      const tokenResponse = await refreshAuthToken(true);

      Cookies.set(config.auth.accessTokenFrontCookieName, tokenResponse.data.token);
      Cookies.set(config.auth.refreshTokenFrontCookieName, tokenResponse.data.refresh_token);

      const authorizationHeader = `Bearer ${tokenResponse.data.token}`;
      set(requestConfig, 'headers.Authorization', authorizationHeader);

      return getAxiosInstance()(requestConfig);
    } else {
      const { messages, timeout } = prepareErrorMessages(err.response);
      if (!errorToastDisable && messages.length > 0) {
        toast.error(messages, { duration: timeout });
      }
    }
    throw err;
  };

  const refreshAuthToken = (front?: boolean) => {
    return api.request.post(refreshTokenPath(), {
      refresh_token: Cookies.get(front ? 'front_refresh_token' : 'refresh_token') || '',
    });
  };

  const setCancelToken = (name: string, token: Canceler): void => {
    cancelTokens[name] = token;
    setCancelTokens(cancelTokens);
  };

  const api: API = {
    get request(): AxiosInstance {
      const axiosInstance = getAxiosInstance({
        baseURL: config.apiBaseUrl,
      });
      axiosInstance.interceptors.response.use(interceptSuccessResponse, interceptErrorResponse);
      return axiosInstance;
    },
    get authRequest(): AxiosInstance {
      const axiosInstance = getAxiosInstance({
        baseURL: config.apiBaseUrl,
      });
      axiosInstance.interceptors.request.use(interceptAuthenticatedRequest, (error: any) => {
        return Promise.reject(error);
      });
      axiosInstance.interceptors.response.use(interceptSuccessResponse, interceptAuthenticatedErrorResponse);
      return axiosInstance;
    },
    get authFrontRequest(): AxiosInstance {
      const axiosInstance = getAxiosInstance({
        baseURL: config.apiBaseUrl,
      });
      axiosInstance.interceptors.request.use(interceptAuthenticatedFrontRequest, (error: any) => {
        return Promise.reject(error);
      });
      axiosInstance.interceptors.response.use(interceptSuccessResponse, interceptFrontAuthenticatedErrorResponse);
      return axiosInstance;
    },
    prepareCancelToken(name: string, disabledCancel?: boolean): CancelToken {
      if (!disabledCancel) {
        api.cancelToken(name);
      }
      return new axios.CancelToken((token) => {
        setCancelToken(name, token);
      });
    },
    isCancel(value: any): boolean {
      return axios.isCancel(value);
    },
    cancelToken(name: string): void {
      if (typeof cancelTokens[name] === 'function') {
        cancelTokens[name]();
      }
    },
    cancelAllRequests(): void {
      Object.keys(cancelTokens).map(api.cancelToken);
    },
  };

  return api;
};
