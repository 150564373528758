import React, { useState, useEffect, createRef, useCallback, useRef } from 'react';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import config from '@config';
import { applicationStore } from '@stores';
import { fileToBase64 } from '@helpers/files';
import { useAuctionsApi } from '@api/auctions';
import { getFormatBytes } from '@helpers/formats';
import { confirmMessage } from '@helpers/messages';
import { BasePreloader, Button } from '@components';
import { AdminAuctionImageType } from '@types';

import MediaModal from './MediaModal';
import VideoModal from './VideoModal';

import plusIco from '@assets/images/plus-ico.svg';
import colorIcoView from '@assets/images/color-ico-eye.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';

interface AuctionImageProps {
  image: ImageType;
  index: number;
  disabled?: boolean;
  onClick: () => void;

  onDelete(event: React.MouseEvent, image: ImageType): void;

  moveImage: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  id: string | number;
  index: number;
  type: string;
}

interface DropResult {
  handlerId: string | symbol | null;
}

const ItemTypes = {
  IMAGE: 'image',
};

const AuctionImage: React.FC<AuctionImageProps> = ({ image, index, onDelete, onClick, moveImage, disabled }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, DropResult>({
    accept: ItemTypes.IMAGE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: { index: number }, monitor) {
      if (!ref.current || disabled) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.IMAGE,
    item: () => ({ id: image.id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !disabled,
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} className={cx('admin-auction-item-image')} style={{ opacity }} data-handler-id={handlerId}>
      {image.type.startsWith('image') && (
        <a
          href="/"
          className="item-image-preview"
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          <img src={colorIcoView} alt="del" className="preview-image" />
        </a>
      )}
      {image.type !== 'file' && !image.type.startsWith('image') && (
        <div className="video-icon" onClick={onClick}>
          <svg
            role="img"
            focusable="false"
            data-prefix="far"
            aria-hidden="true"
            viewBox="0 0 512 512"
            data-icon="play-circle"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#ddd"
              d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"
            ></path>
          </svg>
        </div>
      )}
      <div
        className="image-content"
        style={{ backgroundImage: `url(${image.src})`, backgroundSize: 'cover' }}
        onClick={onClick}
      />
      <div className="image-action">
        <a href="/" onClick={(e) => onDelete(e, image)} className="d-inline-flex align-items-center">
          <img src={colorIcoDel} alt="ico" className="mr-2" />
          Smazat
        </a>
      </div>
    </div>
  );
};

interface DraggableAuctionImagesProps {
  disabled?: boolean;
  images: Array<ImageType>;

  onDelete(event: React.MouseEvent, image: ImageType): void;

  onReorder: (newImages: ImageType[]) => void;
}

const DraggableAuctionImages: React.FC<DraggableAuctionImagesProps> = ({ images, onDelete, onReorder, disabled }) => {
  const [imageSelected, setImageSelected] = useState<AdminAuctionImageType | undefined>(undefined);

  const moveImage = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragImage = images[dragIndex];
      const newImages = [...images];
      newImages.splice(dragIndex, 1);
      newImages.splice(hoverIndex, 0, dragImage);
      onReorder(newImages);
    },
    [images, onReorder]
  );

  if (images.length < 1) {
    return <div className="no-items">Nebyla nalezena žádná data.</div>;
  }

  return (
    <>
      {!!imageSelected && <MediaModal item={imageSelected} onRequestClose={() => setImageSelected(undefined)} />}
      <div className="images">
        {images.map((image, index) => (
          <AuctionImage
            key={image.id}
            index={index}
            disabled={disabled || images.length < 2}
            image={image}
            onDelete={onDelete}
            moveImage={moveImage}
            onClick={() =>
              setImageSelected({
                src: image.src,
                type: image.type,
                srcDetail: image.srcDetail,
                embedHash: image.embedHash,
              })
            }
          />
        ))}
      </div>
    </>
  );
};

interface Props {
  id: string | number;
  getTranslation: (key: string) => string;
}

interface ImageType {
  id: string | number;
  src: string;
  srcDetail?: string;
  type: string;
  embedHash?: string;
}

const AuctionImages: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const [loaded, setLoaded] = useState(false);
  const [saved, setSaved] = useState(true);
  const [images, setImages] = useState<ImageType[]>([]);
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const inputRef = createRef<HTMLInputElement>();

  const loadImages = useCallback(async () => {
    try {
      const response = await auctionsApi.getImages(props.id);
      const images: ImageType[] = [];
      for (let i = 0; i < response.data.length; i++) {
        try {
          images.push({
            id: response.data[i].id,
            type: response.data[i].media.type !== 'file' ? response.data[i].media.mimeType : 'file',
            src: `${config.apiBaseUrl}/file/${response.data[i].media.hash}?view=true&size=auction.crop`,
            srcDetail: `${config.apiBaseUrl}/file/${response.data[i].media.hash}?view=true`,
            embedHash: response.data[i].media.embedHash,
          });
        } catch (err) {
          images.push({
            src: '',
            id: response.data[i].id,
            type: response.data[i].media.type !== 'file' ? response.data[i].media.mimeType : 'file',
            embedHash: response.data[i].media.embedHash,
          });
        }
      }
      setImages(images);
      setLoaded(true);
      setSaved(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  }, [auctionsApi, props.id]);

  const saveFile = useCallback(
    async (file: File) => {
      const maxPostBodySize = applicationStore.getState().systemInfo?.post_max_size;
      if (!!maxPostBodySize && maxPostBodySize < file.size) {
        toast.error(`Soubor ${file.name} nesmí být větší než ${getFormatBytes(maxPostBodySize)}`);
        return;
      }

      try {
        const fileBase64 = await fileToBase64(file as File);
        await auctionsApi.createImage(props.id, {
          type: 'image',
          data: fileBase64,
          mime: file?.type as string,
          image_title: file.name,
          original_name: file.name,
        });
      } catch (err) {
        if (!err.response) {
          return;
        }
      }
    },
    [auctionsApi, props.id]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const accept = ['image/jpeg', 'image/gif', 'image/bmp', 'image/png', 'image/webp'];
      setSaved(false);
      for (const i in acceptedFiles) {
        if (accept.some((e) => e === acceptedFiles[i].type)) {
          await saveFile(acceptedFiles[i]);
        }
      }
      setSaved(true);
      setLoaded(false);
      loadImages();
    },
    [loadImages, saveFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  useEffect(() => {
    loadImages();
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) {
      return;
    }
    setSaved(false);
    for (let i = 0; i < e.target.files.length; i++) {
      await saveFile(e.target.files[i]);
    }
    setSaved(true);
    setLoaded(false);
    loadImages();
  };

  const handleDeleteClick = (e: React.MouseEvent, item: ImageType) => {
    e.preventDefault();
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise(async (resolve) => {
          try {
            await auctionsApi.deleteImage(props.id, item.id);
            await loadImages();
            resolve(true);
          } catch {
            resolve(true);
          }
        });
      },
    });
  };

  const handleReorder = async (newImages: ImageType[]) => {
    setImages(newImages);
    try {
      await auctionsApi.reorderAuctionImages(
        props.id,
        newImages.map((image, index) => ({
          auctionMediaId: image.id,
          position: index,
        }))
      );
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleVideoModalClose = (reload?: boolean) => {
    if (reload) {
      setLoaded(false);
      loadImages();
    }
    setShowVideoModal(false);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <VideoModal auctionId={props.id} isOpen={showVideoModal} onClose={handleVideoModalClose} />
      <div {...getRootProps()} className="auction-images">
        <div className="item-button d-flex align-items-center">
          <div>
            {isDragActive ? (
              <div className="item-drop">Vložit soubor</div>
            ) : (
              <Button type="button" className="mr-2" onClick={() => inputRef.current?.click()}>
                <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                Přidat obrázky
              </Button>
            )}
          </div>
          <Button type="button" className="mr-2" onClick={() => setShowVideoModal(true)}>
            <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
            <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
            Přidat video
          </Button>
          {!saved && <BasePreloader className="image-preloader" size={25} />}
          {saved && (
            <input
              {...getInputProps()}
              multiple
              type="file"
              ref={inputRef}
              className="d-none"
              accept="image/jpeg,image/gif,image/bmp,image/png,image/webp"
              onChange={handleSelectFile}
            />
          )}
        </div>
        <div className="item-help">{props.getTranslation('tab_attachments_images_help_text')}</div>
        {loaded ? (
          <div className="items-list">
            <DraggableAuctionImages
              images={images}
              disabled={images.length < 2}
              onDelete={handleDeleteClick}
              onReorder={handleReorder}
            />
          </div>
        ) : (
          <div className="mt-4 mb-4">
            <BasePreloader size={25} />
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default AuctionImages;
