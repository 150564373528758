import React from 'react';
import Select, { Props as ReactSelectProps, SingleValue, MultiValue } from 'react-select';
import config from '@config';

export type SelectOptionType = {
  value: string;
  label: string;
};

// Base Props for Select
interface BaseSelectProps {
  size?: 'md';
  isInvalid?: boolean;
  readOnly?: boolean;
  disableSearch?: boolean;
  options: SelectOptionType[];
  minWidth?: number;
  dataTestId?: string;
}

// Conditional Props Based on isMulti
export type SelectProps<IsMulti extends boolean = false> = BaseSelectProps &
  Omit<ReactSelectProps<SelectOptionType, IsMulti>, 'value' | 'onChange'> & {
    isMulti?: IsMulti;
    value?: IsMulti extends true ? MultiValue<SelectOptionType> : SingleValue<SelectOptionType>;
    onChange: (value: IsMulti extends true ? MultiValue<SelectOptionType> : SingleValue<SelectOptionType>) => void;
  };

const SelectComponent = <IsMulti extends boolean = false>({
  size,
  isInvalid,
  readOnly,
  disableSearch,
  options,
  minWidth,
  dataTestId,
  ...props
}: SelectProps<IsMulti>) => {
  const getPadding = () => {
    if (size === 'md') {
      return '3px 15px';
    }
    return '0px 15px';
  };

  const isSearchableInitial = options && options.length >= config.enableSelectSearchMinOptions;

  const getTestId = (value: string) => {
    if (dataTestId) {
      return `${dataTestId}-${value}`;
    }
    return undefined;
  };

  return (
    <Select
      placeholder={props.placeholder || 'Vyberte ze seznamu'}
      noOptionsMessage={props.noOptionsMessage || (() => 'Nenalezeno')}
      isDisabled={readOnly || props.isDisabled}
      isSearchable={isSearchableInitial}
      menuPortalTarget={document.querySelector('body')}
      formatOptionLabel={(option) => <span data-test-id={getTestId(option.value)}>{option.label}</span>}
      options={options}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          minWidth: minWidth,
          outline: '0',
          boxShadow: 'none',
          borderRadius: 30,
          border: !isInvalid ? '1px solid #d2d2d2 !important' : '1px solid #caa2a2 !important',
          background: isInvalid ? '#fdfafa' : props.isDisabled ? '#e9ecef' : undefined,
          padding: getPadding(),
          fontSize: 12,
          marginTop: -1,
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#000',
        }),
        menu: (provided) => ({ ...provided, zIndex: 10 }),
        menuPortal: (provided) => ({ ...provided, zIndex: 1000 }),
        indicatorSeparator: () => ({}),
        option: (provided) => ({
          ...provided,
          fontWeight: 'normal',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#BFBFBF',
        }),
        noOptionsMessage: (provided) => ({
          ...provided,
          fontWeight: 'normal',
        }),
      }}
      {...props}
    />
  );
};

export default SelectComponent;
