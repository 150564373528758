import { ControlFeedback, DatePickerInput, FormGroup, Select } from '@components';
import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { OnChangeValue } from 'react-select';

import { arrKeyExists } from '@helpers/validator';
import { getRequestDateFormat } from '@helpers/datetime';
import { AuctionParameterForm } from './AuctionParameterForm';
import { useAuctionParametersApi } from '@api/auctionParameters';
import { AuctionParametersResponse, AuctionParametersShortCutResponse, BaseAuctionParam } from '@types';

import { BooleanParameter } from './BooleanParameter';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

interface SelectOptionType {
  value: string;
  label: string;
}

export const VehicleCarForm: FC<AuctionParameterForm> = (props) => {
  const auctionParameterApi = useAuctionParametersApi();
  const [brandTypes, setBrandTypes] = useState<BaseAuctionParam[]>([]);
  const [modelTypes, setModelTypes] = useState<AuctionParametersShortCutResponse[]>([]);
  const [bodyTypes, setBodyTypes] = useState<AuctionParametersResponse[]>([]);
  const [colorTypes, setColorTypes] = useState<AuctionParametersResponse[]>([]);
  const [categoryTypes, setCategoryTypes] = useState<AuctionParametersResponse[]>([]);
  const [stateTypes, setStateTypes] = useState<AuctionParametersResponse[]>([]);
  const [fuelTypes, setFuelTypes] = useState<AuctionParametersResponse[]>([]);
  const [originCountryTypes, setOriginCountryTypes] = useState<AuctionParametersResponse[]>([]);

  const brandTypeOptions = brandTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const modelTypeOptions = modelTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const bodyTypeOptions = bodyTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const colorTypeOptions = colorTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const categoryTypeOptions = categoryTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const stateTypeOptions = stateTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const fuelTypeOptions = fuelTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const originCountryTypeOptions = originCountryTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  useEffect(() => {
    loadData();
    return () => {
      auctionParameterApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.values.auctionParameterVehicleCar.parameterVehicleCategory) {
      loadStates(props.values.auctionParameterVehicleCar.parameterVehicleCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.auctionParameterVehicleCar.parameterVehicleCategory]);

  const loadStates = async (category: string) => {
    try {
      const stateTypesResponse = await auctionParameterApi.getVehicleState(category);
      setStateTypes(stateTypesResponse.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadData = async () => {
    try {
      const carAllRes = await auctionParameterApi.getVehicleCarAll();
      const vehicleAllRes = await auctionParameterApi.getVehicleAll();

      setBrandTypes(carAllRes.data.data.parameterVehicleCarBrand);
      if (props.values.auctionParameterVehicleCar.parameterVehicleCarBrand) {
        loadModelTypes(
          props.values.auctionParameterVehicleCar.parameterVehicleCarBrand.id ||
            props.values.auctionParameterVehicleCar.parameterVehicleCarBrand
        );
      }

      setBodyTypes(carAllRes.data.data.parameterVehicleCarBody);
      setFuelTypes(vehicleAllRes.data.data.parameterVehicleFuel);
      setColorTypes(vehicleAllRes.data.data.parameterVehicleColor);
      setCategoryTypes(vehicleAllRes.data.data.parameterVehicleCategory);
      setOriginCountryTypes(vehicleAllRes.data.data.parameterVehicleOriginCountry);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const loadModelTypes = async (carBrand: string | number) => {
    try {
      const modelTypesResponse = await auctionParameterApi.getVehicleCarModel(carBrand);
      setModelTypes(modelTypesResponse.data.data);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const handleCarBrandChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleCar.parameterVehicleCarBrand', itemValue?.value);
    props.setFieldValue('auctionParameterVehicleCar.parameterVehicleCarModel', undefined);
    if (!!itemValue && itemValue.value) {
      loadModelTypes(itemValue.value);
    } else {
      setModelTypes([]);
    }
  };

  const handleCarModelChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleCar.parameterVehicleCarModel', itemValue?.value);
  };

  const handleCarBodyChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleCar.parameterVehicleCarBody', itemValue?.value);
  };

  const handleCarColorChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleCar.parameterVehicleColor', itemValue?.value);
  };

  const handleCarCategoryChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleCar.parameterVehicleCategory', itemValue?.value);
  };

  const handleCarStateChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleCar.parameterVehicleState', itemValue?.value);
  };

  const handleDateOfTechnicalInspectionChange = (value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      props.setFieldValue(
        'auctionParameterVehicleCar.dateOfTechnicalInspection',
        !!inputValue ? getRequestDateFormat(inputValue) : ''
      );
    }
  };

  const handleCarFuelChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleCar.parameterVehicleFuel', itemValue?.value);
  };

  const handleCarOriginCountryChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleCar.parameterVehicleOriginCountry', itemValue?.value);
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  return (
    <>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Značka{arrKeyExists(props.requiredParametersFields, 'parameterVehicleCarBrand') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleCarBrand">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleCarBrand"
              isInvalid={!!props.errors.auctionParameterVehicleCar_parameterVehicleCarBrand}
              options={brandTypeOptions}
              onChange={handleCarBrandChange}
              value={
                brandTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleCar.parameterVehicleCarBrand)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleCar_parameterVehicleCarBrand && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleCar_parameterVehicleCarBrand as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      {!!modelTypeOptions && modelTypeOptions.length > 0 && (
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-left">
            Model{arrKeyExists(props.requiredParametersFields, 'parameterVehicleCarBrand') ? ' *' : ''}
          </Form.Label>
          <div className="f-inline-control">
            <div className="w-max-500" data-test-id="parameterVehicleCarModel">
              <Select
                isClearable
                isDisabled={!props.values.auctionParameterVehicleCar.parameterVehicleCarBrand || props.readOnly}
                size="md"
                name="parameterVehicleCarModel"
                isInvalid={!!props.errors.auctionParameterVehicleCar_parameterVehicleCarModel}
                options={modelTypeOptions}
                onChange={handleCarModelChange}
                value={
                  modelTypeOptions.find(
                    (i) => i.value === getSelectValue(props.values.auctionParameterVehicleCar.parameterVehicleCarModel)
                  ) || null
                }
              />
              {!!props.errors.auctionParameterVehicleCar_parameterVehicleCarModel && (
                <ControlFeedback type="invalid">
                  {props.errors.auctionParameterVehicleCar_parameterVehicleCarModel as string}
                </ControlFeedback>
              )}
            </div>
          </div>
        </Form.Group>
      )}

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Kategorie{arrKeyExists(props.requiredParametersFields, 'parameterVehicleCategory') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleCategory">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleCategory"
              options={categoryTypeOptions}
              isInvalid={!!props.errors.auctionParameterVehicleCar_parameterVehicleCategory}
              onChange={handleCarCategoryChange}
              value={
                categoryTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleCar.parameterVehicleCategory)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleCar_parameterVehicleCategory && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleCar_parameterVehicleCategory as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <FormGroup
        readOnly={props.readOnly}
        label="Další charakteristiky"
        labelClassName="text-left"
        name="auctionParameterVehicleCar.description"
        error={props.errors.auctionParameterVehicleCar_description as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'description')}
        value={props.values.auctionParameterVehicleCar.description}
        dataTestId="auctionParameterVehicleCar.description"
      />
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Karoserie{arrKeyExists(props.requiredParametersFields, 'parameterVehicleCarBody') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleCarBody">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleCarBody"
              isInvalid={!!props.errors.auctionParameterVehicleCar_parameterVehicleCarBody}
              options={bodyTypeOptions}
              onChange={handleCarBodyChange}
              value={
                bodyTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleCar.parameterVehicleCarBody)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleCar_parameterVehicleCarBody && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleCar_parameterVehicleCarBody as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Barva{arrKeyExists(props.requiredParametersFields, 'parameterVehicleColor') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleColor">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleColor"
              isInvalid={!!props.errors.auctionParameterVehicleCar_parameterVehicleColor}
              options={colorTypeOptions}
              onChange={handleCarColorChange}
              value={
                colorTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleCar.parameterVehicleColor)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleCar_parameterVehicleColor && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleCar_parameterVehicleColor as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <FormGroup
        readOnly={props.readOnly}
        label="VIN"
        labelClassName="text-left"
        name="auctionParameterVehicleCar.vin"
        onChange={props.handleChange}
        required={
          arrKeyExists(props.requiredParametersFields, 'vin') || !!props.values.auctionParameterVehicleCar.vinPublic
        }
        value={props.values.auctionParameterVehicleCar.vin}
        error={props.errors.auctionParameterVehicleCar_vin as string}
        dataTestId="auctionParameterVehicleCar.vin"
      />
      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterVehicleCar.vinPublic">
          <Form.Check
            custom
            disabled={props.readOnly}
            type="checkbox"
            label="VIN veřejný (bude zobrazen v inzerátu)"
            className="mr-4"
            id="vinPublic"
            name="auctionParameterVehicleCar.vinPublic"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterVehicleCar.vinPublic', e.target.checked);
            }}
            checked={props.values.auctionParameterVehicleCar.vinPublic}
          />
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Stav{arrKeyExists(props.requiredParametersFields, 'parameterVehicleState') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleState">
            <Select
              isClearable
              isDisabled={!props.values.auctionParameterVehicleCar.parameterVehicleCategory || props.readOnly}
              size="md"
              name="parameterVehicleState"
              options={stateTypeOptions}
              onChange={handleCarStateChange}
              value={
                stateTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleCar.parameterVehicleState)
                ) || null
              }
            />
          </div>
        </div>
      </Form.Group>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          thousandSeparator
          type="number"
          label="Stav tachometru"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleCar.tachometer"
          onValueChange={(val) => props.setFieldValue('auctionParameterVehicleCar.tachometer', val.value)}
          required={arrKeyExists(props.requiredParametersFields, 'tachometer')}
          value={props.values.auctionParameterVehicleCar.tachometer}
          dataTestId="auctionParameterVehicleCar.tachometer"
        />
        <span className="f-size-12 f-weight-400">km</span>
      </div>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Rok výroby"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleCar.dateOfManufacture"
          required={arrKeyExists(props.requiredParametersFields, 'dateOfManufacture')}
          value={props.values.auctionParameterVehicleCar.dateOfManufacture}
          error={props.errors.auctionParameterVehicleCar_dateOfManufacture as string}
          onChange={props.handleChange}
          dataTestId="auctionParameterVehicleCar.dateOfManufacture"
        />
      </div>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label">
          STK platná do{arrKeyExists(props.requiredParametersFields, 'dateOfTechnicalInspection') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control d-flex align-items-center">
          <div>
            <DatePickerInput
              readOnly={props.readOnly}
              className={classNames([
                'form-control',
                'w-max-140',
                { 'is-invalid': !!props.errors.auctionParameterVehicleCar_dateOfTechnicalInspection },
              ])}
              calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
              clearIcon={null}
              name="dateOfTechnicalInspection"
              onChange={(val) => handleDateOfTechnicalInspectionChange(val)}
              value={
                !!props.values.auctionParameterVehicleCar.dateOfTechnicalInspection
                  ? moment(props.values.auctionParameterVehicleCar.dateOfTechnicalInspection || '').toDate()
                  : undefined
              }
              data-test-id="dateOfTechnicalInspection"
            />
            {!!props.errors.auctionParameterVehicleCar_dateOfTechnicalInspection && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleCar_dateOfTechnicalInspection as string}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Palivo{arrKeyExists(props.requiredParametersFields, 'parameterVehicleFuel') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleFuel">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleFuel"
              options={fuelTypeOptions}
              onChange={handleCarFuelChange}
              value={
                fuelTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleCar.parameterVehicleFuel)
                ) || null
              }
            />
          </div>
        </div>
      </Form.Group>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Výkon motoru"
          className="mt-0 mb-0"
          labelClassName="text-left"
          error={props.errors.auctionParameterVehicleCar_enginePower as string}
          name="auctionParameterVehicleCar.enginePower"
          onChange={props.handleChange}
          required={arrKeyExists(props.requiredParametersFields, 'enginePower')}
          value={props.values.auctionParameterVehicleCar.enginePower}
          dataTestId="auctionParameterVehicleCar.enginePower"
        />
        <span className="f-size-12 f-weight-400">kW</span>
      </div>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Objem motoru"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleCar.engineCapacity"
          onChange={props.handleChange}
          required={arrKeyExists(props.requiredParametersFields, 'engineCapacity')}
          value={props.values.auctionParameterVehicleCar.engineCapacity}
          dataTestId="auctionParameterVehicleCar.engineCapacity"
        />
        <span className="f-size-12 f-weight-400">ccm</span>
      </div>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Počet míst"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleCar.numberOfSeats"
          required={arrKeyExists(props.requiredParametersFields, 'numberOfSeats')}
          value={props.values.auctionParameterVehicleCar.numberOfSeats}
          error={props.errors.auctionParameterVehicleCar_numberOfSeats as string}
          onChange={props.handleChange}
          dataTestId="auctionParameterVehicleCar.numberOfSeats"
        />
      </div>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Stát původu{arrKeyExists(props.requiredParametersFields, 'parameterVehicleOriginCountry') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleOriginCountry">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleOriginCountry"
              options={originCountryTypeOptions}
              onChange={handleCarOriginCountryChange}
              value={
                originCountryTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterVehicleCar.parameterVehicleOriginCountry)
                ) || null
              }
            />
          </div>
        </div>
      </Form.Group>

      <BooleanParameter
        label="První majitel"
        readOnly={props.readOnly}
        name="auctionParameterVehicleCar.firstOwner"
        dataTestId="auctionParameterVehicleCar.firstOwner"
        value={props.values.auctionParameterVehicleCar.firstOwner}
        errors={props.errors.auctionParameterVehicleCar_firstOwner}
        required={arrKeyExists(props.requiredParametersFields, 'firstOwner')}
        onChange={(val) => props.setFieldValue('auctionParameterVehicleCar.firstOwner', val)}
      />

      <BooleanParameter
        label="Servisní knížka"
        readOnly={props.readOnly}
        name="auctionParameterVehicleCar.serviceBook"
        dataTestId="auctionParameterVehicleCar.serviceBook"
        value={props.values.auctionParameterVehicleCar.serviceBook}
        errors={props.errors.auctionParameterVehicleCar_serviceBook}
        required={arrKeyExists(props.requiredParametersFields, 'serviceBook')}
        onChange={(val) => props.setFieldValue('auctionParameterVehicleCar.serviceBook', val)}
      />

      <BooleanParameter
        label="Po havárii"
        readOnly={props.readOnly}
        name="auctionParameterVehicleCar.afterCrash"
        dataTestId="auctionParameterVehicleCar.afterCrash"
        value={props.values.auctionParameterVehicleCar.afterCrash}
        errors={props.errors.auctionParameterVehicleCar_afterCrash}
        required={arrKeyExists(props.requiredParametersFields, 'afterCrash')}
        onChange={(val) => props.setFieldValue('auctionParameterVehicleCar.afterCrash', val)}
      />
    </>
  );
};
