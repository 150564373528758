import React from 'react';
import { Form, Table } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

import { AuctionType, PermissionGroup, UserPermission } from '@types';
import { useAuctionsApi } from '@api/auctions';
import { useAuctioneersApi } from '@api/auctioneers';
import { BasePreloader, useVisibility, Visible } from '@components';

interface Props {
  id: string;
  onNotFound: () => void;
}

const AuctionTypes: React.FC<Props> = (props) => {
  const { shouldBeVisible } = useVisibility();
  const auctionsApi = useAuctionsApi();
  const auctioneersApi = useAuctioneersApi();
  const [inputs, setInputs] = React.useState([] as Array<string>);
  const [typesData, setTypesData] = React.useState([] as Array<AuctionType>);
  const [loadedTypes, setLoadedTypes] = React.useState(false);
  const [loadedAcutionTypes, setLoadedAuctionTypes] = React.useState(false);

  const readOnly = !shouldBeVisible({
    permissionGroupName: PermissionGroup.auctioneerTypeServices,
    permissionName: UserPermission.canEdit,
  });

  React.useEffect(() => {
    loadAuctionTypes();
    loadData();
    return () => {
      auctioneersApi.cancelAllRequests();
      auctionsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    auctioneersApi
      .auctionTypesList(props.id)
      .then((res) => {
        setInputs(res.data.data.map((item: AuctionType) => item.type));
        setLoadedAuctionTypes(true);
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        const message = err.response?.data?.message;
        if (!!message) {
          toast.error(message);
        }
        if (err?.response?.status === 404 || err?.response?.status === 403) {
          props.onNotFound();
          return;
        }
      });
  };

  const loadAuctionTypes = () => {
    auctionsApi
      .listTypes()
      .then((res) => {
        setTypesData(res.data.data);
        setLoadedTypes(true);
      })
      .catch((err) => {
        if (auctionsApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleTypesChange = (item: AuctionType, checked: boolean) => {
    let newInputs = [...inputs];
    if (checked) {
      newInputs.push(item.type);
    } else {
      newInputs = inputs.filter((i) => i !== item.type);
    }
    setInputs(newInputs);
    saveData(newInputs);
  };

  const saveData = (data: Array<string>) => {
    auctioneersApi
      .auctionTypesUpdate(props.id, data)
      .then(() => {})
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
      });
  };

  if (!loadedTypes || !loadedAcutionTypes) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <h2>Typy služeb</h2>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="table-middle border-bottom-0" striped responsive>
            <thead>
              <tr className="b-bottom-1">
                <th className="text-left">Dražba / Aukce</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {typesData.map((item, index) => (
                <tr key={`type-${index}`}>
                  <td>{item.translated}</td>
                  <td className="text-right">
                    <label className="d-inline-flex" data-test-id={`admin-auctioneer-services-checkbox-${item.type}`}>
                      <Visible
                        permissionGroupName={PermissionGroup.auctioneerTypeServices}
                        permissionName={UserPermission.canEdit}
                      >
                        <span>Vybrat:</span>
                      </Visible>
                      <Form.Check
                        custom
                        type="checkbox"
                        label=""
                        className="ml-3"
                        id={item.type}
                        checked={!!inputs.find((i) => i === item.type)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (readOnly) {
                            return;
                          }

                          handleTypesChange(item, e.target.checked);
                        }}
                        readOnly={readOnly}
                      />
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AuctionTypes;
