import { FC } from 'react';
import { OnChangeValue } from 'react-select';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import { useTextBlockApi } from '@api/textBlock';
import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { EnumType, TextBlockContentsResponse } from '@types';
import { BasePreloader, Button, Select } from '@components';

interface Props {
  textBlockContent?: TextBlockContentsResponse;
  allowedLanguages: EnumType[];
  textBlockId: number;
}

interface SelectOptionType {
  value: string;
  label: string;
}

const TextBlockContentForm: FC<Props> = (props) => {
  const textBlockApi = useTextBlockApi();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: props.textBlockContent?.content || '',
      language: props.textBlockContent?.language || '',
      textBlock: props.textBlockId,
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const languageOptions = props.allowedLanguages.map((item) => ({
    value: item.type,
    label: item.translated,
  }));

  const handleSubmit = (values: FormikValues) => {
    (!!props.textBlockContent?.id
      ? textBlockApi.updateTextBlockContent(props.textBlockContent.id as number, formik.values)
      : textBlockApi.createTextBlockContent(formik.values)
    )
      .then(() => {
        formik.setSubmitting(false);
      })
      .catch((err) => {
        if (textBlockApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  const handleLanguageChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('language', itemValue?.value || '');
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  return (
    <div>
      <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-0 mt-md-3">
        <div className="responsive-table-content">
          <div className="pt-1">
            <Form.Group className="f-inline-group">
              <Form.Label className="f-inline-label text-left">Zvolte jazyk *</Form.Label>
              <div className="f-inline-control">
                <div className="w-max-500">
                  <Select
                    className="w-max-500"
                    size="md"
                    name="language"
                    options={languageOptions}
                    onChange={handleLanguageChange}
                    value={languageOptions.find((i) => i.value === getSelectValue(formik.values.language)) || null}
                  />
                </div>
              </div>
            </Form.Group>
            <Wysiwyg
              name="content"
              label="Obsah"
              required={true}
              value={formik.values.content}
              onChange={(val) => formik.setFieldValue('content', val)}
            />
          </div>
        </div>
        <Row>
          <Col xs={12} className="mt-4 text-right">
            {!formik.isSubmitting ? (
              <Button variant="btn-outline-primary" type="submit" disabled={formik.isSubmitting}>
                Uložit
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block" />
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TextBlockContentForm;
