import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import * as routes from '@routes';
import { useAuctionsApi } from '@api/auctions';
import { useTranslatorApi } from '@api/translator';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { AuctionResponse, AuctionConceptType, BaseObjectType } from '@types';
import { urlSearchStore, useAuthStore, usePageStore, useUrlSearchStore } from '@stores';
import { BasePage, Pagination, BasePreloader, AdminBoxContent, Button, AdminProgressFormMenu } from '@components';

import ItemsTable from './components/ItemsTable';
import FiltersForm from './components/FiltersForm';
import ConceptItemsTable from './components/ConceptItemsTable';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import { TableColumnSortProps } from '@components/Table/TableColumn';
import moment from 'moment';
import { getRequestDateTimeFormat } from '@helpers/datetime';

let auctionsTimeout: number | undefined = undefined;

const AuctionsListPage: React.FC = () => {
  const defaultQuery = React.useMemo(
    () => ({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 15),
      sort: withDefault(ObjectParam, {
        property: 'auction.id',
        direction: 'DESC',
      }),
      id: withDefault(StringParam, undefined),
      title: withDefault(StringParam, undefined),
      number: withDefault(StringParam, undefined),
      startDtFrom: withDefault(StringParam, undefined),
      startDtTo: withDefault(StringParam, undefined),
      endDtFrom: withDefault(StringParam, undefined),
      endDtTo: withDefault(StringParam, undefined),
      auctionType: withDefault(StringParam, 'public'),
      state: withDefault(StringParam, undefined),
      status: withDefault(ArrayParam, undefined),
      auctioneer: withDefault(StringParam, undefined),
      published: withDefault(BooleanParam, undefined),
      mainCategory: withDefault(StringParam, undefined),
      subCategory: withDefault(StringParam, undefined),
    }),
    []
  );

  const translationsApi = useTranslatorApi();
  const auctionsApi = useAuctionsApi();
  const location = useLocation();
  const pageState = usePageStore();
  const authState = useAuthStore();
  const navigate = useNavigate();
  const urlSearchState = useUrlSearchStore();
  const [servicesLoaded, setServicesLoaded] = React.useState(false);
  const [showAuctions, setShowAuctions] = React.useState(false);
  const [showPublicAuctions, setShowPublicAuctions] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState([] as Array<AuctionResponse>);
  const [dataLen, setDataLen] = React.useState(0);
  const [isTranslationsLoaded, setIsTranslationsLoaded] = React.useState(false);
  const [auctionDomainTr, setAuctionDomainTr] = React.useState<BaseObjectType<string>>({});
  const [auctionPublicDomainTr, setAuctionPublicDomainTr] = React.useState<BaseObjectType<string>>({});
  const [dataConcept, setDataConcept] = React.useState<AuctionConceptType[]>([]);
  const [query, setQuery] = useQueryParams(defaultQuery);
  const page = pageState.getPage(routes.admin.AUCTIONS_LIST);

  React.useEffect(() => {
    if (!!authState.auctioneer) {
      loadAuctionTypes(authState.auctioneer.id);
    } else {
      setServicesLoaded(true);
      setShowAuctions(true);
      setShowPublicAuctions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (urlSearchState.adminAuctionsList) {
      navigate(`${pageState.getPagePath(routes.admin.AUCTIONS_LIST)}${urlSearchState.adminAuctionsList}`);
    }
    loadTranslations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setLoaded(false);

    if (!servicesLoaded) {
      return;
    }

    if (query.state !== 'concept') {
      loadData();
    } else {
      loadConceptData();
    }
    urlSearchStore.setState({ adminAuctionsList: location.search });
    return () => {
      auctionsApi.cancelAllRequests();
      if (!!auctionsTimeout) {
        clearTimeout(auctionsTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicesLoaded, location.search, query]);

  const loadTranslations = async () => {
    try {
      setAuctionDomainTr((await translationsApi.domainTranslations('admin_auction')).data.data);
      setAuctionPublicDomainTr((await translationsApi.domainTranslations('admin_auction_public')).data.data);
      setIsTranslationsLoaded(true);
    } catch (err) {
      if (translationsApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadData = async (isTimer?: boolean) => {
    if (!!auctionsTimeout) {
      clearTimeout(auctionsTimeout);
    }
    const currentQuery = { ...query };
    if (showAuctions && !showPublicAuctions) {
      currentQuery.auctionType = 'auction';
    }
    if (!showAuctions && showPublicAuctions) {
      currentQuery.auctionType = 'public';
    }
    if (!showAuctions && !showPublicAuctions) {
      return;
    }

    // Filter - date from
    if (query.startDtFrom) {
      currentQuery.startDtFrom = getRequestDateTimeFormat(
        moment(currentQuery.startDtFrom).set('hours', 0).set('minutes', 0).set('seconds', 0)
      );
    }
    if (query.startDtTo) {
      currentQuery.startDtTo = getRequestDateTimeFormat(
        moment(currentQuery.startDtTo).set('hours', 23).set('minutes', 59).set('seconds', 59)
      );
    }

    // Filter - date to
    if (query.endDtFrom) {
      currentQuery.endDtFrom = getRequestDateTimeFormat(
        moment(currentQuery.endDtFrom).set('hours', 0).set('minutes', 0).set('seconds', 0)
      );
    }
    if (query.endDtTo) {
      currentQuery.endDtTo = getRequestDateTimeFormat(
        moment(currentQuery.endDtTo).set('hours', 23).set('minutes', 59).set('seconds', 59)
      );
    }

    try {
      const response = await auctionsApi.getAuctions(currentQuery);
      setData(response.data.data);
      setDataLen(response.data.total as number);
      setLoaded(true);
      if (!isTimer && location && location.hash) {
        document.getElementById(location.hash.substr(1))?.scrollIntoView();
      }
      auctionsTimeout = window.setTimeout(() => loadData(true), 10000);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const loadConceptData = async () => {
    try {
      const response = await auctionsApi.getConteptList({ page: query.page, perPage: query.perPage });
      setDataConcept(response.data.data);
      setDataLen(0);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const loadAuctionTypes = async (auctioneerId: string | number) => {
    try {
      const response = await auctionsApi.getAuctionType(auctioneerId);
      response.data.data.forEach((v) => {
        if (~v.type.indexOf('public') || ~v.type.indexOf('executor')) {
          setShowPublicAuctions(true);
        }
        if (v.type === 'auction') {
          setShowAuctions(true);
        }
      });
      setServicesLoaded(true);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleConceptCopyClick = async (item: AuctionConceptType) => {
    try {
      await auctionsApi.copyConcept(item.id.toString());
      loadConceptData();
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleConceptDeleteClick = (item: AuctionConceptType) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctionsApi.deleteConcept(item.id.toString()).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        return loadConceptData();
      }
    });
  };

  const renderTranslation = (key: string) => {
    let translations = query.auctionType === 'auction' ? auctionDomainTr : auctionPublicDomainTr;
    if (!showAuctions && showPublicAuctions) {
      translations = auctionPublicDomainTr;
    }
    if (showAuctions && !showPublicAuctions) {
      translations = auctionDomainTr;
    }
    return _.get(translations, key, key);
  };

  const exportToWebToggle = async (item: AuctionResponse, server: string) => {
    if (item.exportedToWeb) {
      try {
        await auctionsApi.cancelExportAuction(item.id, server);
        loadData();
      } catch (err) {
        if (!err.response) {
          return;
        }
      }
    } else {
      try {
        await auctionsApi.exportAuction(item.id, server, new Date());
        loadData();
      } catch (err) {
        if (!err.response) {
          return;
        }
      }
    }
  };

  const exportToPDToggle = async (item: AuctionResponse, server: string) => {
    if (item.exportedToPD) {
      try {
        await auctionsApi.cancelExportAuction(item.id, server);
        loadData();
      } catch (err) {
        if (!err.response) {
          return;
        }
      }
    } else {
      try {
        await auctionsApi.exportAuction(item.id, server, new Date());
        loadData();
      } catch (err) {
        if (!err.response) {
          return;
        }
      }
    }
  };

  const stateToggle = (item: AuctionResponse) => {
    let text = '';
    let buttonText = '';
    if (item.state === 'deactive') {
      text = 'Opravdu si přejete nastavit tuto dražbu jako <strong>aktivní</strong>?';
      buttonText = 'Ano aktivovat';
    } else if (item.state === 'active') {
      text = 'Opravdu si přejete nastavit tuto dražbu jako <strong>neaktivní</strong>?';
      buttonText = 'Ano deaktivovat';
    } else {
      return;
    }
    confirmMessage({
      isHtml: true,
      title: 'Potvrzení',
      text: `${text}<br><br>Tato akce se projeví okamžitě bez nutnosti uložení formuláře.`,
      confirmButtonText: buttonText,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (item.state === 'deactive') {
          return auctionsApi.auctionActivate(item.id).catch(() => closeMessage());
        } else {
          return auctionsApi.auctionDeactive(item.id).catch(() => closeMessage());
        }
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const getAuctionType = () => {
    if (showAuctions && !showPublicAuctions) {
      return 'auction';
    }
    if (!showAuctions && showPublicAuctions) {
      return 'public';
    }
    return query.auctionType;
  };

  const renderContent = () => {
    if (!isTranslationsLoaded || !servicesLoaded) {
      return (
        <AdminBoxContent className="pb-5 pt-5">
          <BasePreloader size={25} />
        </AdminBoxContent>
      );
    }

    if (!showAuctions && !showPublicAuctions) {
      return (
        <AdminBoxContent>
          <p className="mb-0">Nemáte aktivovanou žádnou službu, prosím kontaktujte správce systému.</p>
        </AdminBoxContent>
      );
    }

    let navItems = [];

    if (showPublicAuctions) {
      navItems.push({
        label: 'Dražby',
        url: 'public',
        isActive: showAuctions
          ? query.state !== 'concept' && (!query.auctionType || query.auctionType !== 'auction')
          : query.state !== 'concept',
      });
    }

    if (showAuctions) {
      navItems.push({
        label: 'Aukce',
        url: 'auction',
        isActive: showPublicAuctions
          ? query.state !== 'concept' && query.auctionType === 'auction'
          : query.state !== 'concept',
      });
    }

    if (showPublicAuctions || showAuctions) {
      navItems.push({
        label: 'Koncepty',
        url: 'concept',
        isActive: query.state === 'concept',
      });
    }

    return (
      <>
        <AdminProgressFormMenu
          items={navItems}
          onClick={(state: any) => {
            setQuery(({ state: _state, startDtFrom, startDtTo, endDtFrom, endDtTo, ...rest }) => ({
              ...rest,
              perPage: undefined,
              page: undefined,
              auctionType: ['public', 'auction'].some((v) => v === state) ? state : undefined,
              state: state === 'concept' ? state : undefined,
              startDtFrom: state === 'active' ? undefined : startDtFrom,
              startDtTo: state === 'active' ? undefined : startDtTo,
              endDtFrom: state === 'active' ? undefined : endDtFrom,
              endDtTo: state === 'active' ? undefined : endDtTo,
            }));
          }}
        />

        {query.state !== 'concept' && (
          <FiltersForm
            values={query}
            showAuctions={showAuctions}
            showPublicAuctions={showPublicAuctions}
            renderTranslation={renderTranslation}
            onSubmit={(values) => setQuery(values, 'push')}
          />
        )}

        <AdminBoxContent>
          <Row className="align-items-center">
            {query.state !== 'concept' ? (
              <Col xs={12} lg={6}>
                <Button to={`${pageState.getPagePath(routes.admin.AUCTIONS_CREATE)}?type=${getAuctionType()}`}>
                  <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                  <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                  {renderTranslation('create_auction_btn')}
                </Button>
              </Col>
            ) : (
              <Col xs={12} lg={6} />
            )}
            <Col xs={12} lg={6} className="text-left text-lg-right mt-4 mt-lg-0">
              {loaded && (
                <>
                  {query.state !== 'concept' ? (
                    <p className="m-0">
                      Celkem <strong>{dataLen}</strong> {renderTranslation('auctions_count_suffix')}
                    </p>
                  ) : (
                    <p className="m-0">
                      Celkem <strong>{dataConcept.length}</strong> konceptů
                    </p>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Row className="mt-3 pt-3">
            <Col xs={12}>
              {loaded ? (
                <div>
                  <div>
                    {query.state !== 'concept' ? (
                      <ItemsTable
                        data={data}
                        type={query.auctionType}
                        renderTranslation={renderTranslation}
                        stateToggle={stateToggle}
                        exportToWebToggle={exportToWebToggle}
                        exportToPDToggle={exportToPDToggle}
                        sort={query.sort as TableColumnSortProps}
                        onSortChange={(sort) => setQuery({ ...query, sort: sort }, 'push')}
                      />
                    ) : (
                      <ConceptItemsTable
                        data={dataConcept}
                        onDeleteClick={handleConceptDeleteClick}
                        onCopyClick={handleConceptCopyClick}
                      />
                    )}
                  </div>
                  {dataLen > 0 && (
                    <div className="mt-2">
                      <Pagination
                        page={query.page}
                        perPage={query.perPage}
                        totalRows={dataLen}
                        perPageChoiceEnabled={true}
                        onPageChange={(page, perPage) => setQuery({ ...query, page: page, perPage: perPage }, 'push')}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                  <BasePreloader />
                </div>
              )}
            </Col>
          </Row>
        </AdminBoxContent>
      </>
    );
  };

  return (
    <BasePage page={routes.admin.AUCTIONS_LIST} title={page?.name}>
      {renderContent()}
    </BasePage>
  );
};

export default AuctionsListPage;
