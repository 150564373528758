import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client'
import Modal from 'react-modal';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { Toaster } from 'react-hot-toast';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import 'moment/locale/cs';

import App from './App';
import config from '@config';
import * as serviceWorker from './serviceWorker';

moment.tz.setDefault('Europe/Prague');

numeral.register('locale', 'cs', {
  delimiters: {
    thousands: ' ',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'tis.',
    million: 'mil.',
    billion: 'bil.',
    trillion: 'trl.',
  },
  ordinal: (number) => {
    const rest = number % 10;
    return ~~((number % 100) / 10) === 1 ? 'tý' : rest === 1 ? 'ní' : rest === 2 ? 'hý' : rest === 3 ? 'tí' : 'tý';
  },
  currency: {
    symbol: 'Kč',
  },
});

numeral.locale('cs');

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
    <App />

    <Toaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        className: 'toast',
        success: {
          duration: 5000,
          style: {
            background: '#fafdfa',
            color: '#6fbb77',
            border: '1px solid #e1f1e5',
            boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.05)',
            borderRadius: '0',
            display: 'flex',
            flexDirection: 'row',
            padding: '15px',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '21px',
            wordWrap: 'break-word',
            position: 'relative',
          },
        },
        error: {
          duration: 5000,
          style: {
            background: '#fdfafa',
            color: '#b88282',
            border: '1px solid #f1e1e1',
            boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.05)',
            borderRadius: '0',
            display: 'flex',
            flexDirection: 'row',
            padding: '15px',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '21px',
            wordWrap: 'break-word',
            position: 'relative',
          },
          iconTheme: {
            primary: '#b88282',
            secondary: 'white',
          },
        },
      }}
    />
  </GoogleReCaptchaProvider>
  // </React.StrictMode>
);

serviceWorker.unregister();
