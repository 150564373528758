import React from 'react';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import colorIcoConf from '@assets/images/color-ico-conf.svg';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useBuyerApi } from '@api/buyer';
import { useAuctionsApi } from '@api/auctions';
import { useDocumentsApi } from '@api/documents';
import { useTranslatorApi } from '@api/translator';
import { AdminBoxContent, AdminProgressFormMenu, BasePage, BasePreloader } from '@components';
import SecurityPanel from './components/SecurityPanel';
import AuctionSecurityPanel from './components/AuctionSecurityPanel';
import PreEmptionPanel from './components/PreEmptionPanel';
import { AuctionResponse, UserDocument, BuyerAuctionResponse, BaseObjectType } from '@types';

export enum Panels {
  security,
  auctionSecurity,
  preEmption,
}

const UserDocumentsPage: React.FC = () => {
  const translationsApi = useTranslatorApi();
  const { buyerId, auctionId } = useParams<{ buyerId: string; auctionId: string }>();
  const defaultQuery = React.useMemo(
    () => ({
      back: withDefault(StringParam, undefined),
      section: withDefault(NumberParam, Panels.security),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);
  const pageState = usePageStore();
  const buyerApi = useBuyerApi();
  const documentsApi = useDocumentsApi();
  const auctionsApi = useAuctionsApi();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [buyer, setBuyer] = React.useState<BuyerAuctionResponse>();
  const [auction, setAuction] = React.useState<AuctionResponse>();
  const [documents, setDocuments] = React.useState<UserDocument[]>([]);
  const [isTranslationsLoaded, setIsTranslationsLoaded] = React.useState(false);
  const [domainTranslations, setDomainTranslations] = React.useState<BaseObjectType<string>>({});
  const page = pageState.getPage(routes.admin.USER_DOCUMENTS);

  React.useEffect(() => {
    loadData();
    return () => {
      buyerApi.cancelAllRequests();
      auctionsApi.cancelAllRequests();
      documentsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerId, auctionId]);

  const loadData = async () => {
    await loadAuction();
    await loadBuyer();
    await loadDocuments();
    setIsLoaded(true);
  };

  const loadAuction = async () => {
    try {
      if (!auctionId) {
        return;
      }
      const response = await auctionsApi.getAuction(auctionId);
      setAuction(response.data.data);
      loadTranslations(
        response.data.data.auctionType === 'auction' ? 'admin_user_auction' : 'admin_user_auction_public'
      );
    } catch (err) {
      if (!err.response) {
        return;
      }
      console.error(err);
    }
  };

  const loadBuyer = async () => {
    try {
      const response = await buyerApi.buyerAuction(buyerId as string, auctionId as string);
      setBuyer(response.data.data.length > 0 ? response.data.data[0] : undefined);
    } catch (err) {
      if (buyerApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const loadDocuments = async () => {
    try {
      const response = await documentsApi.userDocumentsList(buyerId as string);
      setDocuments(response.data.data);
    } catch (err) {
      if (buyerApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const loadTranslations = async (domain: string) => {
    try {
      setDomainTranslations((await translationsApi.domainTranslations(domain)).data.data);
      setIsTranslationsLoaded(true);
    } catch (err) {
      if (translationsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const getTranslation = (key: string) => _.get(domainTranslations, key, key);

  const getBackLabel = () => {
    switch (query.back) {
      case 'admin-buyers':
        return 'Zpět na doklady a jistoty';
      case 'admin-security':
        return 'Zpět na doklady a jistoty';
      default:
        return getTranslation('back_btn');
    }
  };

  const getBackUrl = () => {
    switch (query.back) {
      case 'admin-buyers':
        return pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY);
      case 'admin-security':
        return `${pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY)}?s=2`;
      default:
        return pageState.getPagePath(routes.admin.AUCTIONS_ACTIVE_DETAIL, {
          ':id': auctionId as string,
        });
    }
  };

  const navItems = [
    {
      label: getTranslation('tab_security_name'),
      url: Panels.security.toString(),
      isActive: query.section === Panels.security,
    },
    {
      label: getTranslation('tab_auction_deposit_name'),
      url: Panels.auctionSecurity.toString(),
      isActive: query.section === Panels.auctionSecurity,
    },
    {
      label: getTranslation('tab_preemption_name'),
      url: Panels.preEmption.toString(),
      isActive: query.section === Panels.preEmption,
    },
  ];

  if (!isTranslationsLoaded) {
    return null;
  }

  return (
    <BasePage
      title={
        isLoaded && !!buyer ? (
          <>
            {page?.name}:
            <strong className="f-weight-600 ml-0 ml-md-2">
              {_.get(buyer.user, 'firstName')} {_.get(buyer.user, 'lastName')}
            </strong>
            <Link
              className="d-inline-flex align-items-center f-weight-400 f-size-14 ml-0 ml-md-3 mt-2 mt-md-0 mb-3 mb-md-0"
              to={pageState.getPagePath(routes.admin.USER_UPDATE, {
                ':id': buyerId as string,
              })}
            >
              <img src={colorIcoConf} alt="ico" className="mr-1" />
              Upravit
            </Link>
          </>
        ) : (
          page?.name
        )
      }
      page={routes.admin.AUCTIONS_LIST}
      backText={getBackLabel()}
      backUrl={getBackUrl()}
    >
      {isLoaded ? (
        <div className="mt-35">
          <AdminProgressFormMenu
            items={navItems}
            onClick={(v) => setQuery({ section: Number(v) > 0 ? parseInt(v.toString()) : undefined })}
          />
          <AdminBoxContent className="p-3 p-md-5">
            <div className="w-min-500">
              {query.section === Panels.security && !!buyer && (
                <SecurityPanel
                  setBuyer={setBuyer}
                  buyerId={buyerId as string}
                  buyerAuction={buyer}
                  auctionId={auctionId as string}
                  documents={documents}
                  getTranslation={getTranslation}
                />
              )}
              {query.section === Panels.auctionSecurity && !!auction && !!buyer && (
                <AuctionSecurityPanel
                  setBuyer={setBuyer}
                  buyerAuction={buyer}
                  auction={auction}
                  buyerId={buyerId as string}
                  auctionId={auctionId as string}
                  getTranslation={getTranslation}
                />
              )}
              {query.section === Panels.preEmption && buyer && (
                <PreEmptionPanel
                  auctionId={auctionId as string}
                  buyer={buyer}
                  getTranslation={getTranslation}
                  onSuccess={(value) => setBuyer({ ...buyer, preEmption: value })}
                />
              )}
            </div>
          </AdminBoxContent>
        </div>
      ) : (
        <div className="pt-5 pb-4">
          <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
            <BasePreloader />
          </div>
        </div>
      )}
    </BasePage>
  );
};

export default UserDocumentsPage;
