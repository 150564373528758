import useWebSocket from 'react-use-websocket';
import { useEffect, useRef, useState } from 'react';

import config from '@config';

export type WebsocketProps<R = any> = {
  topic: string;
  onMessage?: (res: R) => void;
};

export const useWebsocket = <R>(props: WebsocketProps<R>): R | undefined => {
  const onMessageRef = useRef(props.onMessage);
  const [response, setResponse] = useState<R | undefined>();
  const { lastMessage } = useWebSocket(`${config.websocketUrl}/_ws${props.topic}`);

  useEffect(() => {
    onMessageRef.current = props.onMessage;
  }, [props.onMessage]);

  useEffect(() => {
    if (lastMessage !== null) {
      const data = lastMessage.data ? JSON.parse(lastMessage.data) : undefined;

      if (onMessageRef.current) {
        onMessageRef.current(data);
      }
      setResponse(data);
    }
  }, [lastMessage]);

  return response;
};
