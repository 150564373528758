import React from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { OnChangeValue } from 'react-select';
import { FormikValues, useFormik } from 'formik';

import { useServicesApi } from '@api/services';
import { BaseSelectType, ServiceForAuctioneer } from '@types';
import { BasePreloader, FormGroup, Button, Select, ControlFeedback } from '@components';

interface Props {
  data?: ServiceForAuctioneer;
  onSubmit: () => void;
  onClose: () => void;
  onSave: () => void;
}

interface TypeSelectOptionType {
  value: string;
  label: string;
}

const ServiceForm: React.FC<Props> = (props) => {
  const servicesApi = useServicesApi();

  const [servicesOptions, setServicesOptions] = React.useState<BaseSelectType[]>([]);
  const [vatOptions, setVatOptions] = React.useState<BaseSelectType[]>([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: props.data?.price ?? undefined,
      serviceType: props.data?.serviceType ?? '',
      vat: props.data?.vat ?? '',
      serviceForAuctioneerElectronicAuction: {
        percent30000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.percent30000', ''),
        percent300000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.percent300000', ''),
        percent1000000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.percent1000000', ''),
        percent3000000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.percent3000000', ''),
        percent10000000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.percent10000000', ''),
        percent999999999: _.get(props.data, 'serviceForAuctioneerElectronicAuction.percent999999999', ''),
        plus30000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.plus30000', ''),
        plus300000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.plus300000', ''),
        plus1000000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.plus1000000', ''),
        plus3000000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.plus3000000', ''),
        plus10000000: _.get(props.data, 'serviceForAuctioneerElectronicAuction.plus10000000', ''),
        plus999999999: _.get(props.data, 'serviceForAuctioneerElectronicAuction.plus999999999', ''),
      },
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    loadServicesOptions();
    loadVatOptions();
    return () => servicesApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadServicesOptions = async () => {
    try {
      const response = await servicesApi.servicesEnum();
      setServicesOptions(
        response.data.data.map((item) => ({
          value: item.type,
          label: item.translated,
        }))
      );
    } catch (err) {
      if (servicesApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadVatOptions = async () => {
    try {
      const response = await servicesApi.vatEnum();
      setVatOptions(
        response.data.data.map((item) => ({
          value: item.type,
          label: item.translated,
        }))
      );
    } catch (err) {
      if (servicesApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const omitInputs = ['serviceForAuctioneerElectronicAuction', 'validityToDt'];
    const omitElectronicInputs = ['price'];
    const inputs =
      values.serviceType === 'electronic_auction' ? _.omit(values, omitElectronicInputs) : _.omit(values, omitInputs);
    (!!props.data
      ? servicesApi.update(props.data.id, inputs as Partial<ServiceForAuctioneer>)
      : servicesApi.create(inputs as Partial<ServiceForAuctioneer>)
    )
      .then(() => {
        if (!props.data) {
          props.onSave();
        } else {
          formik.setSubmitting(false);
        }
      })
      .catch((err) => {
        if (servicesApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  const handleTypeChange = (value: OnChangeValue<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('serviceType', itemValue?.value || '');
  };

  const handleVatChange = (value: OnChangeValue<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('vat', itemValue?.value || '');
  };

  const getSuffix = (type: string) => {
    switch (type) {
      case 'ad_reality_portal':
        return 'Kč / den';
      case 'processing_and_control_data':
        return 'Kč';
      case 'ad_car_week':
        return 'Kč / týden';
      default:
        return '';
    }
  };

  return (
    <div>
      <h2>{!!props.data ? 'Upravit službu' : 'Přidat službu'}</h2>
      <div>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
          <div className="responsive-table-content admin-services ">
            <div className=" pt-1">
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">Služba *</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      size="md"
                      options={servicesOptions}
                      isInvalid={!!formik.errors.serviceType}
                      value={servicesOptions.find((i) => i.value === formik.values.serviceType) || null}
                      onChange={handleTypeChange}
                    />
                    {!!formik.errors.serviceType && (
                      <ControlFeedback type="invalid">{formik.errors.serviceType}</ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>
              {formik.values.serviceType !== 'electronic_auction' && (
                <div className="d-flex align-items-center mb-35 ">
                  <FormGroup
                    required
                    thousandSeparator
                    name="price"
                    label="Doporučená cena"
                    className="mt-0 mb-0"
                    controlClassName="w-max-205"
                    value={formik.values.price}
                    error={formik.errors.price}
                    onValueChange={(val) => formik.setFieldValue('price', val?.value ?? '')}
                  />
                  <span className="f-size-12 f-weight-400 flex-shrink-0 mt-4 ml-2 ml-md-0 mt-md-0">
                    {getSuffix(formik.values.serviceType)}
                  </span>
                </div>
              )}
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">DPH</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      size="md"
                      options={vatOptions}
                      isInvalid={!!_.get(formik.errors, 'vat')}
                      value={vatOptions.find((i) => i.value === formik.values?.vat) || null}
                      onChange={handleVatChange}
                    />
                    {!!_.get(formik.errors, 'vat') && (
                      <ControlFeedback type="invalid">{_.get(formik.errors, 'vat')}</ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>
              {formik.values.serviceType === 'electronic_auction' && (
                <>
                  <h5>Ceník</h5>
                  <Form.Group className="f-inline-group multiline-input">
                    <Form.Label className="f-inline-label text-right main-label">0 - 30 000:</Form.Label>
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.percent30000"
                      label="Procenta:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.percent30000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_percent30000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.percent30000', v?.value ?? '')
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.plus30000"
                      label="Paušální částka:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.plus30000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_plus30000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.plus30000', v?.value ?? '')
                      }
                    />
                  </Form.Group>

                  <Form.Group className="f-inline-group multiline-input">
                    <Form.Label className="f-inline-label text-right main-label">30 001 - 300 000:</Form.Label>
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.percent300000"
                      label="Procenta:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.percent300000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_percent300000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.percent300000', v?.value ?? '')
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.plus300000"
                      label="Paušální částka:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.plus300000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_plus300000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.plus300000', v?.value ?? '')
                      }
                    />
                  </Form.Group>

                  <Form.Group className="f-inline-group multiline-input">
                    <Form.Label className="f-inline-label text-right main-label">300 001 - 1 000 000:</Form.Label>
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.percent1000000"
                      label="Procenta:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.percent1000000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_percent1000000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.percent1000000', v?.value ?? '')
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.plus1000000"
                      label="Paušální částka:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.plus1000000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_plus1000000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.plus1000000', v?.value ?? '')
                      }
                    />
                  </Form.Group>

                  <Form.Group className="f-inline-group multiline-input">
                    <Form.Label className="f-inline-label text-right main-label">1 000 001 - 3 000 000:</Form.Label>
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.percent3000000"
                      label="Procenta:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.percent3000000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_percent3000000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.percent3000000', v?.value ?? '')
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.plus3000000"
                      label="Paušální částka:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.plus3000000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_plus3000000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.plus3000000', v?.value ?? '')
                      }
                    />
                  </Form.Group>

                  <Form.Group className="f-inline-group multiline-input">
                    <Form.Label className="f-inline-label text-right main-label">3 000 001 - 10 000 000:</Form.Label>
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.percent10000000"
                      label="Procenta:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.percent10000000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_percent10000000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.percent10000000', v?.value ?? '')
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.plus10000000"
                      label="Paušální částka:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.plus10000000}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_plus10000000')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.plus10000000', v?.value ?? '')
                      }
                    />
                  </Form.Group>

                  <Form.Group className="f-inline-group multiline-input">
                    <Form.Label className="f-inline-label text-right main-label">10 000 001 - 999 999 999:</Form.Label>
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.percent999999999"
                      label="Procenta:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.percent999999999}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_percent999999999')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.percent999999999', v?.value ?? '')
                      }
                    />
                    <FormGroup
                      thousandSeparator
                      type="text"
                      name="serviceForAuctioneerElectronicAuction.plus999999999"
                      label="Paušální částka:"
                      value={formik.values.serviceForAuctioneerElectronicAuction.plus999999999}
                      error={_.get(formik.errors, 'serviceForAuctioneerElectronicAuction_plus999999999')}
                      onValueChange={(v) =>
                        formik.setFieldValue('serviceForAuctioneerElectronicAuction.plus999999999', v?.value ?? '')
                      }
                    />
                  </Form.Group>
                </>
              )}
            </div>
          </div>
          <div className="mt-5">
            <Button variant="btn-outline-default" className="float-left" type="button" onClick={() => props.onClose()}>
              Zpět
            </Button>
            {!formik.isSubmitting ? (
              <Button
                type="submit"
                variant="btn-outline-primary"
                className="float-right"
                disabled={formik.isSubmitting}
              >
                Uložit službu
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block float-right" />
            )}
            <div className="clearfix" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ServiceForm;
