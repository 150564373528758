import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as routes from '@routes';
import { WatchDogType } from '@types';
import { usePageStore } from '@stores';
import { useWatchDogApi } from '@api/watchDog';
import { BaseAccountPage, BasePreloader, FrontAccountBox } from '@components';
import AccountDogFilter from '@components/AccountWatchdogFilter/AccountWatchdogFilter';

type Params = {
  id: string;
};

const AccountWatchdogFormPage: React.FC = () => {
  const navigate = useNavigate();
  const pageState = usePageStore();
  const { id } = useParams<Params>();
  const watchdogApi = useWatchDogApi();
  const [isLoaded, setIsLoaded] = useState(false);
  const [watchdog, setWatchdog] = useState<WatchDogType | undefined>();

  useEffect(() => {
    setIsLoaded(false);
    setWatchdog(undefined);
    if (!!id) {
      loadData();
    } else {
      setIsLoaded(true);
    }
    return () => watchdogApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const res = await watchdogApi.getWatchDogDetail(id as string);
      setWatchdog(res.data.data);
      setIsLoaded(true);
    } catch (err: any) {
      if (watchdogApi.isCancel(err)) {
        return;
      }
    }
  };

  return (
    <BaseAccountPage page={routes.front.ACCOUNT_DOG}>
      {isLoaded ? (
        <AccountDogFilter item={watchdog} onSave={() => navigate(pageState.getPagePath(routes.front.ACCOUNT_DOG))} />
      ) : (
        <FrontAccountBox>
          <div className="d-flex justify-content-center pt-5 pb-5">
            <BasePreloader />
          </div>
        </FrontAccountBox>
      )}
    </BaseAccountPage>
  );
};

export default AccountWatchdogFormPage;
