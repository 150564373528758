import React from 'react';
import { useParams } from 'react-router-dom';

import * as routes from '@routes';
import { AdminBoxContent, Button } from '@components';
import { usePageStore } from '@stores';

import NewForm from './NewForm';
import NewImages from './NewImages';

const New: React.FC = () => {
  const { newsId } = useParams<{ newsId: string }>();
  const pageState = usePageStore();
  const page = pageState.getPage(!!newsId ? routes.admin.SETTINGS_NEWS_UPDATE : routes.admin.SETTINGS_NEWS_CREATE);

  return (
    <div>
      <div>
        <h2>{page?.name}</h2>
      </div>
      <AdminBoxContent className="p-0">
        <div className="w-min-500 component-admin-news">
          <div className="component-admin-box-content overlow-x-auto">
            <NewForm id={newsId as any} />
            {newsId && (
              <div className="mt-5">
                <h2>Galerie</h2>
                <NewImages id={newsId} />
              </div>
            )}
          </div>
        </div>
      </AdminBoxContent>
      <Button to={pageState.getPagePath(routes.admin.SETTINGS_NEWS)}>Zpět na aktuality</Button>
    </div>
  );
};

export default New;
