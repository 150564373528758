import * as React from 'react';
import moment from 'moment/moment';
import { Form, Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { Link, useLocation } from 'react-router-dom';
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { BaseAPIResponse } from '@api/api';
import { useAuctionsApi } from '@api/auctions';
import { getDateTimeFormat } from '@helpers/datetime';
import { AuctionSectionStep } from '../ActiveAuctionPage';
import { BasePreloader, Button, Pagination } from '@components';
import TableColumn, { TableColumnSortProps } from '@components/Table/TableColumn';
import { AuctionResponse, AuctionUsersFilters, UserAuctionResponse } from '@types';
import { Panels as UserDocumentsPanel } from '../../UserDocumentsPage/UserDocumentsPage';

import colorIcoConf from '@assets/images/color-ico-conf.svg';
import icoCheckGreen from '@assets/images/ico-check-green.svg';

interface Props {
  auction: AuctionResponse;
  isAuctionSecurity?: boolean;
  isAuctionSection?: boolean;
}

const BuyersSection: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const auctionsApi = useAuctionsApi();
  const location = useLocation();
  const [dataLen, setDataLen] = React.useState(0);
  const [buyers, setBuyers] = React.useState<Array<UserAuctionResponse>>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const defaultQuery = React.useMemo(
    () => ({
      s: withDefault(StringParam, AuctionSectionStep.auctioneers),
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 15),
      sort: withDefault(ObjectParam, {
        direction: 'DESC',
        property: 'userAuction.joinedTime',
      }),
      uniqueCode: withDefault(StringParam, undefined),
      specificSymbol: withDefault(StringParam, undefined),
      variableSymbol: withDefault(StringParam, undefined),
      preEmption: withDefault(BooleanParam, undefined),
      admitted: withDefault(BooleanParam, undefined),
      auctionSecurity: withDefault(ArrayParam, undefined),
      authorizationState: withDefault(StringParam, undefined),
      fullName: withDefault(StringParam, undefined),
      withoutWinner: withDefault(BooleanParam, undefined),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  React.useEffect(() => {
    if (props.isAuctionSecurity) {
      setQuery({ ...query, withoutWinner: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAuctionSecurity]);

  React.useEffect(() => {
    loadBuyers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const loadBuyers = async (withoutLoading?: boolean) => {
    if (!withoutLoading) {
      setIsLoading(true);
    }

    try {
      let response;
      if (props.isAuctionSecurity) {
        response = await auctionsApi.getAuctionSecurityBuyers(props.auction.id, { ...query });
      } else {
        response = await auctionsApi.getAuctionBuyers(props.auction.id, { ...query });
      }

      setBuyers(response.data.data);
      setDataLen(response.data.total as number);
      setIsLoading(false);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const downloadFile = async (
    func: (id: string | number, params?: AuctionUsersFilters) => BaseAPIResponse<Blob>,
    fileType: string
  ) => {
    try {
      const res = await func(props.auction.id, { ...query, auctionId: props.auction.id });
      fileDownload(res.data, `export-${props.auction.id}.${fileType}`);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const getAuctionSecurity = (item: UserAuctionResponse) => {
    if (parseFloat(props.auction.cautionDeposit || '0') <= 0) {
      return 'Není vyžadována';
    }
    return item.auctionSecurity === 'none' || item.auctionSecurity === 'rejected' ? 'Ne' : 'Ano';
  };

  const handleBackSecurityChange = async (value: boolean, userId: string | number) => {
    try {
      await auctionsApi.auctionBackSecurity(userId, props.auction.id, { backSecurity: value });
      await loadBuyers(true);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="pt-5 pb-4">
          <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
            <BasePreloader />
          </div>
        </div>
      );
    }

    return (
      <div className="auction-panel-content">
        {props.isAuctionSecurity && (
          <>
            <div className="btn-group d-flex justify-content-end pb-3">
              <div className="mr-2">
                <Button onClick={() => downloadFile(auctionsApi.getAuctionSecurityExcel, 'xlsx')}>
                  Export do Excelu
                </Button>
              </div>
              <div>
                <Button onClick={() => downloadFile(auctionsApi.getAuctionSecurityGpc, 'gpc')}>Export do GPC</Button>
              </div>
            </div>
          </>
        )}
        {props.isAuctionSection && (
          <>
            <div className="btn-group d-flex justify-content-end pb-3">
              <div>
                <Button onClick={() => downloadFile(auctionsApi.getAuctionUsersExcel, 'xlsx')}>Export do Excelu</Button>
              </div>
            </div>
          </>
        )}
        <div className="component-admin-auctions-table">
          <Table className="border-bottom-0">
            <thead>
              <tr>
                <TableColumn title="#" className="text-nowrap" />
                <TableColumn
                  className="text-nowrap"
                  title="Datum přihlášení"
                  sortField="userAuction.joinedTime"
                  sort={query.sort as TableColumnSortProps}
                  onSortChange={(sort) => setQuery({ ...query, sort: sort }, 'push')}
                />
                <th>Jméno a příjmení</th>
                <th>Druh dražitel</th>
                {props.isAuctionSecurity && <th className="text-nowrap">Číslo účtu</th>}
                <TableColumn
                  className="text-nowrap"
                  title="Specif. symbol"
                  sortField="userAuction.specificSymbol"
                  sort={query.sort as TableColumnSortProps}
                  onSortChange={(sort) => setQuery({ ...query, sort: sort }, 'push')}
                />
                <TableColumn
                  className="text-nowrap"
                  title="Variabilní symbol"
                  sortField="userAuction.variableSymbol"
                  sort={query.sort as TableColumnSortProps}
                  onSortChange={(sort) => setQuery({ ...query, sort: sort }, 'push')}
                />
                <th>Ověření totožnosti</th>
                <th>Jistota</th>
                <th>Předkupní právo</th>
                <th>Připuštěn</th>
                {!props.isAuctionSecurity && <th className="text-nowrap">Poslední nahraný dokument</th>}
                {props.isAuctionSecurity && <th className="text-nowrap">Vítěz</th>}
                {props.isAuctionSecurity && <th className="text-nowrap">Vráceno</th>}
              </tr>
            </thead>
            <tbody>
              {buyers.map((item, index) => (
                <tr key={`list-item-${index}`} className="border-top">
                  <td>{item.uniqueCode}</td>
                  <td>{moment(item.joinedTime).format('DD.MM.YYYY HH:mm')}</td>
                  <td>
                    <span className="d-flex">
                      {!props.isAuctionSecurity && (
                        <Link
                          className="d-flex"
                          to={pageState.getPagePath(routes.admin.USER_DETAIL, { ':id': item.user.id.toString() })}
                        >
                          <img src={colorIcoConf} alt="ico" className="mr-2" />
                        </Link>
                      )}
                      {item.user.subjectName}
                    </span>
                  </td>
                  <td>{item.user.buyerTypeTranslation}</td>
                  {props.isAuctionSecurity && <td className="text-nowrap">{item.user.accountNumber || '-'}</td>}
                  <td>{item.specificSymbol || '-'}</td>
                  <td>{item.variableSymbol || '-'}</td>
                  <td className="auction-column-title">
                    <span className="d-flex">
                      {!props.isAuctionSecurity && (
                        <Link
                          className="d-flex align-items"
                          to={`${pageState.getPagePath(routes.admin.USER_DOCUMENTS, {
                            ':buyerId': item.user.id.toString(),
                            ':auctionId': props.auction.id.toString(),
                          })}?section=${UserDocumentsPanel.security}`}
                        >
                          <img src={colorIcoConf} alt="ico" className="mr-2" width={18} height={18} />
                        </Link>
                      )}
                      {item.authorizationStateTranslation}
                    </span>
                  </td>
                  <td>
                    <span className="d-flex">
                      {!props.isAuctionSecurity && (
                        <Link
                          className="d-flex"
                          to={`${pageState.getPagePath(routes.admin.USER_DOCUMENTS, {
                            ':buyerId': item.user.id.toString(),
                            ':auctionId': props.auction.id.toString(),
                          })}?section=${UserDocumentsPanel.auctionSecurity}`}
                        >
                          <img src={colorIcoConf} alt="ico" className="mr-2" />
                        </Link>
                      )}
                      {getAuctionSecurity(item)}
                    </span>
                  </td>
                  <td>
                    <span className="d-flex">
                      {!props.isAuctionSecurity && (
                        <Link
                          className="d-flex"
                          to={`${pageState.getPagePath(routes.admin.USER_DOCUMENTS, {
                            ':buyerId': item.user.id.toString(),
                            ':auctionId': props.auction.id.toString(),
                          })}?section=${UserDocumentsPanel.preEmption}`}
                        >
                          <img src={colorIcoConf} alt="ico" className="mr-2" />
                        </Link>
                      )}
                      {item.preEmption ? 'Ano' : 'Ne'}
                    </span>
                  </td>
                  <td>{item.admitted ? 'Ano' : 'Ne'}</td>
                  {!props.isAuctionSecurity && (
                    <td>
                      {!!item.user.lastUploadedDocument
                        ? getDateTimeFormat(item.user.lastUploadedDocument.timeUpdated)
                        : '-'}
                    </td>
                  )}
                  {props.isAuctionSecurity && <td>{item.isWinner && <img src={icoCheckGreen} alt="is-winner" />}</td>}

                  {props.isAuctionSecurity && (
                    <td className="text-center">
                      <Form.Check
                        custom
                        type="checkbox"
                        className="mt-checkbox"
                        checked={item.backSecurity}
                        id={`auction-back-security-${index}`}
                        onChange={(e) => handleBackSecurityChange(e.target.checked, item.user.id)}
                      />
                    </td>
                  )}
                </tr>
              ))}
              {buyers.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nejsou dražitelé
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {buyers.length > 0 && (
          <div className="mt-2">
            <Pagination
              perPageChoiceEnabled
              page={query.page}
              perPage={query.perPage}
              totalRows={dataLen}
              onPageChange={(page, perPage) => setQuery({ ...query, page, perPage }, 'push')}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="component-admin-box-content">
      <div className="active-auction-panel">{renderContent()}</div>
    </div>
  );
};

export default BuyersSection;
