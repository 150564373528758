import React from 'react';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { useLocation } from 'react-router-dom';
import { NumberParam, ObjectParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { useUsersApi } from '@api/users';
import { User, UserEmailType } from '@types';
import { getDateTimeFormat } from '@helpers/datetime';
import { BasePreloader, FileIcon, Modal, Pagination } from '@components';
import TableColumn, { TableColumnSortProps } from '@components/Table/TableColumn';

import { UserSections } from '../UserUpdatePage';

import colorIcoEye from '@assets/images/color-ico-eye.svg';

interface Props {
  user?: User;
}

const UserEmails: React.FC<Props> = (props) => {
  const usersApi = useUsersApi();
  const location = useLocation();
  const [detailLoaded, setDetailLoaded] = React.useState(0);
  const [emails, setEmails] = React.useState<Array<UserEmailType>>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [dataLen, setDataLen] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalTile, setModalTitle] = React.useState('');
  const [modalContent, setModalContent] = React.useState('');
  const defaultQuery = React.useMemo(
    () => ({
      section: withDefault(StringParam, UserSections.emails.toString()),
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 15),
      content: withDefault(StringParam, undefined),
      subject: withDefault(StringParam, undefined),
      sort: withDefault(ObjectParam, {
        property: 'sentEmail.timeCreated',
        direction: 'DESC',
      }),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  React.useEffect(() => {
    if (props.user && props.user.id) {
      loadEmails(props.user.id);
    }
    // eslint-disable-next-line
  }, [props.user, location.search, query]);

  const loadEmails = (id: number) => {
    setLoaded(false);
    usersApi
      .getEmails(id, {
        sort: query.sort,
        page: query.page,
        perPage: query.perPage,
        subject: query.subject,
        content: query.content,
      })
      .then((res) => {
        setDataLen(res.data.total || 0);
        setEmails(res.data.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleFileClick = (e: React.MouseEvent<HTMLAnchorElement>, item: UserEmailType, index: number) => {
    e.preventDefault();
    if (!props.user?.id) {
      return;
    }
    usersApi
      .getAdminEmailAttachment(props.user.id, item.id, item.attachments[index].id)
      .then((res) => {
        fileDownload(res.data, item.attachments[index].fileName);
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleShowEmail = async (e: React.MouseEvent, email: UserEmailType) => {
    e.preventDefault();
    setDetailLoaded(email.id);
    try {
      const emailResponse = await usersApi.getEmail(props.user?.id || 0, email.id);
      setModalTitle(email.subject);
      setModalContent(emailResponse.data);
      setDetailLoaded(0);
      setIsModalOpen(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setDetailLoaded(0);
    }
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <>
      <Modal
        noPadding
        title={modalTile}
        isOpen={isModalOpen}
        className="modal-content-lg h-100vh"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div>
          {!!modalContent && (
            <iframe
              title={modalTile}
              srcDoc={modalContent}
              className="w-100p b-0"
              style={{ height: 'calc(100vh - 150px)' }}
            />
          )}
        </div>
      </Modal>
      <div>
        <div>
          <h2>Odeslané emaily</h2>
        </div>
        <div className="mt-4">
          <div className="responsive-table-content">
            <Table className="text-left table-middle border-bottom-0" striped>
              <thead>
                <tr>
                  <TableColumn
                    title="Předmět"
                    className="text-left text-nowrap"
                    sortField="sentEmail.subject"
                    sort={query.sort as TableColumnSortProps}
                    onSortChange={(sort) => setQuery({ ...query, sort: sort }, 'push')}
                  />
                  <TableColumn
                    title="Datum"
                    className="text-left text-nowrap"
                    sortField="sentEmail.timeCreated"
                    sort={query.sort as TableColumnSortProps}
                    onSortChange={(sort) => setQuery({ ...query, sort: sort }, 'push')}
                  />
                  <th className="text-left">Přílohy</th>
                  <th className="w-1" />
                </tr>
              </thead>
              <tbody>
                {emails.length < 1 && (
                  <tr>
                    <td className="text-left" colSpan={100}>
                      Nebyla nalezena žádná data.
                    </td>
                  </tr>
                )}
                {emails.map((item, index) => (
                  <tr key={`email-${index}`}>
                    <td>{item.subject}</td>
                    <td className="text-left">{getDateTimeFormat(item.timeCreated)}</td>
                    <td>
                      {item.attachments.map((attachment, index) => (
                        <>
                          <FileIcon name={attachment.fileName} className="ml-2" />
                          <a href="/" onClick={(e) => handleFileClick(e, item, index)} className="mr-2">
                            {attachment.fileName}
                          </a>
                        </>
                      ))}
                    </td>
                    <td className="text-right nowrap">
                      {detailLoaded !== item.id ? (
                        <a
                          href="/"
                          onClick={(e) => handleShowEmail(e, item)}
                          className="d-inline-flex align-items-center"
                        >
                          <img src={colorIcoEye} alt="ico" className="mr-2" />
                          Náhled
                        </a>
                      ) : (
                        <BasePreloader className="d-inline-block m-0 p-0" size={17} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        {dataLen > 0 && (
          <div className="mt-3">
            <Pagination
              page={query.page}
              perPage={query.perPage}
              totalRows={dataLen}
              perPageChoiceEnabled={true}
              onPageChange={(page, perPage) => setQuery({ ...query, page: page, perPage: perPage }, 'push')}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UserEmails;
