import React, { useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useAuctioneersApi } from '@api/auctioneers';
import { AdminFiltersBox, DatePickerInput, Select } from '@components';
import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';
import { Auctioneer, AuctioneerType, InvoicesFilters } from '@types';

import { InvoicesSections } from '../InvoicesPage';
import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';
import moment from 'moment';
import { getRequestDateFormat } from '@helpers/datetime';

interface Props {
  values: InvoicesFilters;
  auctioneers: Auctioneer[];
  onSubmit: (values: InvoicesFilters) => void;
}

interface AuctioneerSelectOptionType {
  value: string;
  label: string;
}

const paidOptions = [
  {
    value: 'true',
    label: 'Ano',
  },
  {
    value: 'false',
    label: 'Ne',
  },
];

const enforcementOptions = [
  {
    value: 'true',
    label: 'Ano',
  },
  {
    value: 'false',
    label: 'Ne',
  },
];

const InvoicesDataFilters: React.FC<Props> = (props) => {
  const location = useLocation();
  const pageState = usePageStore();
  const auctioneersApi = useAuctioneersApi();
  const [isOpen, setIsOpen] = useState(false);
  const [auctioneerTypes, setAuctioneerTypes] = useState<AuctioneerType[]>([]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });
  const auctioneersOptions = props.auctioneers.map((i) => ({ value: i.id.toString(), label: i.auctioneerName }));
  const auctioneerTypesOptions = auctioneerTypes.map((i) => ({ value: i.type, label: i.translated }));

  useEffect(() => {
    setIsOpen(false);
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  useEffect(() => {
    loadAuctioneerTypes();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAuctioneerTypes = async () => {
    try {
      const response = await auctioneersApi.types();
      setAuctioneerTypes(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    props.onSubmit(formValues as InvoicesFilters);
  };

  const hasFilters = (): boolean => {
    return !!location.search && location.search !== `?section=${InvoicesSections.invoices}`;
  };

  function handlePaid(value: OnChangeValue<AuctioneerSelectOptionType, boolean>) {
    const itemValue = value as AuctioneerSelectOptionType;
    if (itemValue?.value === 'true') {
      return formik.setFieldValue('paid', true);
    }
    if (itemValue?.value === 'false') {
      return formik.setFieldValue('paid', false);
    }
    return formik.setFieldValue('paid', '');
  }

  function handleEnforcement(value: OnChangeValue<AuctioneerSelectOptionType, boolean>) {
    const itemValue = value as AuctioneerSelectOptionType;
    if (itemValue?.value === 'true') {
      return formik.setFieldValue('enforcement', true);
    }
    if (itemValue?.value === 'false') {
      return formik.setFieldValue('enforcement', false);
    }
    return formik.setFieldValue('enforcement', '');
  }

  const handleAuctioneerChange = (value: OnChangeValue<AuctioneerSelectOptionType, boolean>) => {
    const itemValue = value as AuctioneerSelectOptionType;
    formik.setFieldValue('auctioneer', itemValue?.value);
  };

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    } else {
      formik.setFieldValue(prop, '');
    }
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Číslo faktury</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="invoiceNumber"
                  value={formik.values.invoiceNumber || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Zaplaceno</Form.Label>
                <Select
                  isClearable={true}
                  options={paidOptions}
                  value={paidOptions.find((i) => i.value === String(formik.values.paid)) || null}
                  onChange={(val) => handlePaid(val)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Vymáháno</Form.Label>
                <Select
                  isClearable={true}
                  options={enforcementOptions}
                  value={enforcementOptions.find((i) => i.value === String(formik.values.enforcement)) || null}
                  onChange={(val) => handleEnforcement(val)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Dražebník</Form.Label>
                <Select
                  isClearable={true}
                  options={auctioneersOptions}
                  value={auctioneersOptions.find((i) => i.value === String(formik.values.auctioneer)) || null}
                  onChange={(val) => handleAuctioneerChange(val as AuctioneerSelectOptionType)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>ID dražby</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="auction"
                  value={formik.values.auction || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Typ subjektu</Form.Label>
                <Select
                  isClearable={true}
                  options={auctioneerTypesOptions}
                  value={auctioneerTypesOptions.find((i) => i.value === String(formik.values.auctioneerType)) || null}
                  onChange={(val) => formik.setFieldValue('auctioneerType', val?.value)}
                />
              </Form.Group>
            </Col>
            <Col sm="auto">
              <Form.Group className="mb-0">
                <Form.Label>Datum vystavení</Form.Label>
              </Form.Group>
              <div className="d-block d-sm-flex mt-0">
                <Form.Group className="mb-3 inline-label mr-2 input-ico">
                  <Form.Label>Od</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('dateOfIssueFrom', val)}
                    value={
                      formik.values.dateOfIssueFrom ? moment(formik.values.dateOfIssueFrom || '').toDate() : undefined
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3 inline-label input-ico">
                  <Form.Label>Do</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('dateOfIssueTo', val)}
                    value={formik.values.dateOfIssueTo ? moment(formik.values.dateOfIssueTo || '').toDate() : undefined}
                  />
                </Form.Group>
              </div>
            </Col>

            <Col sm="auto">
              <Form.Group className="mb-0">
                <Form.Label>Zaplaceno</Form.Label>
              </Form.Group>
              <div className="d-block d-sm-flex mt-0">
                <Form.Group className="mb-3 inline-label mr-2 input-ico">
                  <Form.Label>Od</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('datePaidFrom', val)}
                    value={formik.values.datePaidFrom ? moment(formik.values.datePaidFrom || '').toDate() : undefined}
                  />
                </Form.Group>
                <Form.Group className="mb-3 inline-label input-ico">
                  <Form.Label>Do</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('datePaidTo', val)}
                    value={formik.values.datePaidTo ? moment(formik.values.datePaidTo || '').toDate() : undefined}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit
          hasFilters={hasFilters()}
          onOpenClick={() => setIsOpen(true)}
          clearUrl={pageState.getPagePath(routes.admin.INVOICES)}
        />
      </AdminFiltersBox>
    </Form>
  );
};

export default InvoicesDataFilters;
