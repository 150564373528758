import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { BaseFront, SEO } from '@components';
import * as routes from '@routes';
import { usePageStore } from '@stores';
import { IWantToSellDetail } from '@components/IWantToSell/IWantToSellDetail';
import { HintDetail } from '@components/Hint/HintDetail';

import HomePage from './pages/front/HomePage/HomePage';
import RegistrationPage from './pages/front/RegistrationPage/RegistrationPage';
import AccountPage from './pages/front/AccountPage/AccountPage';
import AccountWatchdogPage from './pages/front/AccountWatchdogPage/AccountWatchdogPage';
import AccountWatchdogFormPage from './pages/front/AccountWatchdogFormPage/AccountWatchdogFormPage';
import AccountDocumentsPage from './pages/front/AccountDocumentsPage/AccountDocumentsPage';
import AccountAuctionsPage from './pages/front/AccountAuctionsPage/AccountAuctionsPage';
import AccountFavouriteAuctionsPage from './pages/front/AccountFavouriteAuctionsPage/AccountFavouriteAuctionsPage';
import AccountAuctioneerRegistrationPage from './pages/front/AccountAuctioneerRegistrationPage/AccountAuctioneerRegistrationPage';
import AccountSettingsPage from './pages/front/AccountSettingsPage/AccountSettingsPage';
import Account404Page from './pages/front/Account404Page/Account404Page';
import AccountPersonalDataPage from './pages/front/AccountPersonalDataPage/AccountPersonalDataPage';
import AccountAuctionInformations from './pages/front/AccountAuctionInformations/AccountAuctionInformations';
import AccountEmailsPage from './pages/front/AccountEmailsPage/AccountEmailsPage';
import AuctionDetailPage from './pages/front/AuctionDetailPage/AuctionDetailPage';
import AuctionListPage from './pages/front/AuctionListPage/AuctionListPage';
import { AuctioneerListPage } from './pages/front/AuctioneerListPage/AuctioneerListPage';
import { ContactPage } from './pages/front/Contact/ContactPage';
import { AuctioneerDetailPage } from './pages/front/AuctioneerAuctionsPage/AuctioneerAuctionsPage';
import { IWantToSellPage } from './pages/front/IWantToSellPage/IWantToSellPage';
import { NewsPage } from './pages/front/News/NewsPage';
import { NewsPageDetail } from './pages/front/News/NewsPageDetail';
import { HintPage } from './pages/front/HintPage/HintPage';
import { AboutUsPage } from './pages/front/AboutUs/AboutUsPage';
import { DefaultPageComponent } from './pages/front/DefaultPage/DefaultPageComponent';

const FrontRoutes: React.FC = () => {
  const pageState = usePageStore();
  const location = useLocation();

  const isChildPage = (url: string, parents: (string | undefined)[]) => {
    return parents.some((parent) => parent && url.startsWith(parent));
  };

  return (
    <BaseFront>
      <SEO />
      <Routes>
        {!!pageState.getPage(routes.front.REGISTRATION) && (
          <Route path={pageState.getPagePath(routes.front.REGISTRATION)} element={<RegistrationPage />} />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT) && (
          <Route path={pageState.getPagePath(routes.front.ACCOUNT)} element={<AccountPage />} />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_DOG) && (
          <Route path={pageState.getPagePath(routes.front.ACCOUNT_DOG)} element={<AccountWatchdogPage />} />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_DOG_DETAIL) && (
          <Route path={pageState.getPagePath(routes.front.ACCOUNT_DOG_DETAIL)} element={<AccountWatchdogFormPage />} />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_DOG_CREATE) && (
          <Route path={pageState.getPagePath(routes.front.ACCOUNT_DOG_CREATE)} element={<AccountWatchdogFormPage />} />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_DOCUMENTS) && (
          <Route path={pageState.getPagePath(routes.front.ACCOUNT_DOCUMENTS)} element={<AccountDocumentsPage />} />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_AUCTIONS) && (
          <Route path={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS)} element={<AccountAuctionsPage />} />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS) && (
          <Route
            path={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS)}
            element={<AccountAuctionInformations />}
          />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_PERSONAL_DATA) && (
          <Route
            path={pageState.getPagePath(routes.front.ACCOUNT_PERSONAL_DATA)}
            element={<AccountPersonalDataPage />}
          />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_EMAILS) && (
          <Route path={pageState.getPagePath(routes.front.ACCOUNT_EMAILS)} element={<AccountEmailsPage />} />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_DOG) && (
          <Route path={pageState.getPagePath(routes.front.ACCOUNT_DOG)} element={<Account404Page />} />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_FAVOURITES) && (
          <Route
            path={pageState.getPagePath(routes.front.ACCOUNT_FAVOURITES)}
            element={<AccountFavouriteAuctionsPage />}
          />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_SETTINGS) && (
          <Route path={pageState.getPagePath(routes.front.ACCOUNT_SETTINGS)} element={<AccountSettingsPage />} />
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_AUCTIONEER_REGISTRATION) && (
          <Route
            path={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONEER_REGISTRATION)}
            element={<AccountAuctioneerRegistrationPage />}
          />
        )}
        {!!pageState.getPage(routes.front.AUCTION_LIST_PREPARED) && (
          <Route
            path={pageState.getPagePath(routes.front.AUCTION_LIST_PREPARED)}
            element={<AuctionListPage status="prepared" />}
          />
        )}
        {!!pageState.getPage(routes.front.AUCTION_LIST_ONGOING) && (
          <Route
            path={pageState.getPagePath(routes.front.AUCTION_LIST_ONGOING)}
            element={<AuctionListPage status="ongoing" />}
          />
        )}
        {!!pageState.getPage(routes.front.AUCTION_LIST_ENDED) && (
          <Route
            path={pageState.getPagePath(routes.front.AUCTION_LIST_ENDED)}
            element={<AuctionListPage status="ended" />}
          />
        )}
        {!!pageState.getPage(routes.front.AUCTION_LIST_OVERCHARGE) && (
          <Route
            path={pageState.getPagePath(routes.front.AUCTION_LIST_OVERCHARGE)}
            element={<AuctionListPage status="overcharge" />}
          />
        )}
        {!!pageState.getPage(routes.front.AUCTION_DETAIL) && (
          <Route
            path={pageState.getPagePath(routes.front.AUCTION_DETAIL)}
            element={<AuctionDetailPage type="auction" />}
          />
        )}
        {!!pageState.getPage(routes.front.AUCTION_PUBLIC_DETAIL) && (
          <Route
            path={pageState.getPagePath(routes.front.AUCTION_PUBLIC_DETAIL)}
            element={<AuctionDetailPage type="auction_public" />}
          />
        )}
        {!!pageState.getPage(routes.front.AUCTIONEER_LIST) && (
          <Route path={pageState.getPagePath(routes.front.AUCTIONEER_LIST)} element={<AuctioneerListPage />} />
        )}
        {!!pageState.getPage(routes.front.AUCTIONEER_DETAIL) && (
          <Route path={pageState.getPagePath(routes.front.AUCTIONEER_DETAIL)} element={<AuctioneerDetailPage />} />
        )}
        {!!pageState.getPage(routes.front.CONTACT) && (
          <Route path={pageState.getPagePath(routes.front.CONTACT)} element={<ContactPage />} />
        )}
        {!!pageState.getPage(routes.front.I_WANT_TO_SELL) && (
          <Route path={pageState.getPagePath(routes.front.I_WANT_TO_SELL)} element={<IWantToSellPage />} />
        )}
        {!!pageState.getPage(routes.front.I_WANT_TO_SELL) && (
          <Route path={`${pageState.getPagePath(routes.front.I_WANT_TO_SELL)}/:slug`} element={<IWantToSellDetail />} />
        )}
        {!!pageState.getPage(routes.front.NEWS) && (
          <Route path={pageState.getPagePath(routes.front.NEWS)} element={<NewsPage />} />
        )}
        {!!pageState.getPage(routes.front.NEWS_DETAIL) && (
          <Route path={pageState.getPagePath(routes.front.NEWS_DETAIL)} element={<NewsPageDetail />} />
        )}
        {!!pageState.getPage(routes.front.HINT) && (
          <Route path={pageState.getPagePath(routes.front.HINT)} element={<HintPage />} />
        )}
        {!!pageState.getPage(routes.front.HINT) && (
          <Route path={`${pageState.getPagePath(routes.front.HINT)}/:slug/*`} element={<HintDetail />} />
        )}
        {!!pageState.getPage(routes.front.ABOUT_US) && (
          <Route path={pageState.getPagePath(routes.front.ABOUT_US)} element={<AboutUsPage />} />
        )}
        <Route path={routes.front.HOME} element={<HomePage />} />
        {pageState.pages.some(
          (page) =>
            page.fullUrl === location.pathname &&
            !isChildPage(location.pathname, [
              pageState.getPage(routes.front.HINT)?.url,
              pageState.getPage(routes.front.NEWS)?.url,
              pageState.getPage(routes.front.I_WANT_TO_SELL)?.url,
            ])
        ) && <Route path={location.pathname} element={<DefaultPageComponent route={location.pathname} />} />}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BaseFront>
  );
};

export default FrontRoutes;
