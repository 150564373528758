import React from 'react';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { useLocation } from 'react-router-dom';
import { NumberParam, ObjectParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { useUsersApi } from '@api/users';
import { AuctionEmailType } from '@types';
import { useAuctionsApi } from '@api/auctions';
import { getDateTimeFormat } from '@helpers/datetime';
import { BasePreloader, FileIcon, Modal, Pagination } from '@components';

import colorIcoEye from '@assets/images/color-ico-eye.svg';

import { AuctionSectionStep } from '../ActiveAuctionPage';
import TableColumn, { TableColumnSortProps } from '@components/Table/TableColumn';

interface Props {
  auctionId: string | number;
}

export const AuctionEmailsSection: React.FC<Props> = (props) => {
  const location = useLocation();
  const usersApi = useUsersApi();
  const auctionsApi = useAuctionsApi();
  const [dataLen, setDataLen] = React.useState(0);
  const [detailLoaded, setDetailLoaded] = React.useState(0);
  const [emails, setEmails] = React.useState<Array<AuctionEmailType>>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalTile, setModalTitle] = React.useState('');
  const [modalContent, setModalContent] = React.useState('');
  const defaultQuery = React.useMemo(
    () => ({
      s: withDefault(StringParam, AuctionSectionStep.emails),
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 15),
      content: withDefault(StringParam, undefined),
      subject: withDefault(StringParam, undefined),
      email: withDefault(StringParam, undefined),
      sort: withDefault(ObjectParam, {
        property: 'sentEmail.timeCreated',
        direction: 'DESC',
      }),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  React.useEffect(() => {
    loadEmails(props.auctionId).then();
    // eslint-disable-next-line
  }, [props.auctionId, location.search, query]);

  const loadEmails = async (id: string | number) => {
    setLoaded(false);
    try {
      const res = await auctionsApi.getAuctionEmails(props.auctionId, {
        page: query.page,
        perPage: query.perPage,
        content: query.content,
        subject: query.subject,
        email: query.email,
        sort: query.sort,
      });
      setDataLen(res.data.total || 0);
      setEmails(res.data.data);
      setLoaded(true);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleFileClick = (e: React.MouseEvent<HTMLAnchorElement>, item: AuctionEmailType, index: number) => {
    e.preventDefault();
    usersApi
      .getAdminEmailAttachment(item.user.id, item.id, item.attachments[index].id)
      .then((res) => {
        fileDownload(res.data, item.attachments[index].fileName);
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleShowEmail = async (e: React.MouseEvent, email: AuctionEmailType) => {
    e.preventDefault();
    setDetailLoaded(email.id);
    try {
      const emailResponse = await usersApi.getEmail(email.user.id, email.id);
      setModalTitle(email.subject);
      setModalContent(emailResponse.data);
      setDetailLoaded(0);
      setIsModalOpen(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setDetailLoaded(0);
    }
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <>
      <Modal
        noPadding
        title={modalTile}
        isOpen={isModalOpen}
        className="modal-content-lg h-100vh"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div>
          {!!modalContent && (
            <iframe
              title={modalTile}
              srcDoc={modalContent}
              className="w-100p b-0"
              style={{ height: 'calc(100vh - 150px)' }}
            />
          )}
        </div>
      </Modal>
      <div className="auction-panel-content p-4">
        <div className="responsive-table-content">
          <div>
            <h2>Odeslané emaily</h2>
          </div>
          <div className="mt-4">
            <div className="responsive-table-content">
              <Table className="text-left table-middle border-bottom-0" striped>
                <thead>
                  <tr>
                    <th className="text-left">Uživatel</th>
                    <TableColumn
                      title="Předmět"
                      className="text-left text-nowrap"
                      sortField="sentEmail.subject"
                      sort={query.sort as TableColumnSortProps}
                      onSortChange={(sort) => setQuery({ ...query, sort: sort }, 'push')}
                    />
                    <TableColumn
                      title="Datum"
                      className="text-left text-nowrap"
                      sortField="sentEmail.timeCreated"
                      sort={query.sort as TableColumnSortProps}
                      onSortChange={(sort) => setQuery({ ...query, sort: sort }, 'push')}
                    />
                    <th className="text-left">Přílohy</th>
                    <th className="w-1" />
                  </tr>
                </thead>
                <tbody>
                  {emails.length < 1 && (
                    <tr>
                      <td className="text-left" colSpan={100}>
                        Nebyla nalezena žádná data.
                      </td>
                    </tr>
                  )}
                  {emails.map((item, index) => (
                    <tr key={`email-${index}`}>
                      <td>{item.user.subjectName}</td>
                      <td>{item.subject}</td>
                      <td className="text-left">{getDateTimeFormat(item.timeCreated)}</td>
                      <td>
                        {item.attachments.map((attachment, index) => (
                          <>
                            <FileIcon name={attachment.fileName} className="ml-2" />
                            <a href="/" onClick={(e) => handleFileClick(e, item, index)} className="mr-2">
                              {attachment.fileName}
                            </a>
                          </>
                        ))}
                      </td>
                      <td className="text-right nowrap">
                        {detailLoaded !== item.id ? (
                          <a
                            href="/"
                            onClick={(e) => handleShowEmail(e, item)}
                            className="d-inline-flex align-items-center"
                          >
                            <img src={colorIcoEye} alt="ico" className="mr-2" />
                            Náhled
                          </a>
                        ) : (
                          <BasePreloader className="d-inline-block m-0 p-0" size={17} />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {dataLen > 0 && (
          <div className="mt-3">
            <Pagination
              page={query.page}
              perPage={query.perPage}
              totalRows={dataLen}
              perPageChoiceEnabled={true}
              onPageChange={(page, perPage) => setQuery({ ...query, page: page, perPage: perPage }, 'push')}
            />
          </div>
        )}
      </div>
    </>
  );
};
