import React from 'react';
import SelectAsync from 'react-select/async';
import { Props as SelectAsyncProps } from 'react-select';

interface SelectProps extends SelectAsyncProps<any, any> {
  size?: 'md';
}

const SelectComponent: React.FC<SelectProps> = (props) => {
  const getPadding = () => {
    if (props.size === 'md') {
      return '3px 15px';
    }
    return '0px 15px';
  };

  return (
    <SelectAsync
      {...props}
      placeholder={props.placeholder || 'Vyberte ze seznamu'}
      noOptionsMessage={props.noOptionsMessage || (() => 'Nenalezeno')}
      loadingMessage={props.loadingMessage || (() => 'Načítání...')}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          outline: '0',
          boxShadow: 'none',
          borderRadius: 30,
          border: '1px solid #d2d2d2 !important',
          padding: getPadding(),
          fontSize: 12,
          marginTop: -1,
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
        }),
        menu: (provided) => ({ ...provided, zIndex: 10 }),
        indicatorSeparator: () => ({}),
        option: (provided) => ({
          ...provided,
          fontWeight: 'normal',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#BFBFBF;',
        }),
        noOptionsMessage: (provided) => ({
          ...provided,
          fontWeight: 'normal',
        }),
      }}
    />
  );
};

export default SelectComponent;
