import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useTextBlockApi } from '@api/textBlock';
import TextBlockContentForm from './TextBlockContentForm';
import { AdminBoxContent, BasePreloader, Button } from '@components';
import { EnumType, TextBlockContentsResponse } from '@types';

type ParamsType = {
  textBlockId: string;
  textBlockContentId: string;
};

const TextBlockContent: React.FC = () => {
  const textBlockApi = useTextBlockApi();
  const { textBlockId, textBlockContentId } = useParams<ParamsType>();
  const [loaded, setLoaded] = useState(!textBlockContentId);
  const [loadedLanguages, setLoadedLanguages] = useState(false);
  const [allowedLanguages, setAllowedLanguages] = useState<EnumType[]>([]);
  const [textBlockContent, setTextBlockContent] = useState<TextBlockContentsResponse | undefined>(undefined);
  const pageState = usePageStore();
  const page = pageState.getPage(
    !!textBlockContentId
      ? routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_UPDATE
      : routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_CREATE
  );

  React.useEffect(() => {
    loadAllowedLanguages();
    return () => {
      textBlockApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setLoaded(!textBlockContentId);
    if (textBlockContentId) {
      loadTextBlockContent(+textBlockContentId);
    }
    return () => {
      textBlockApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textBlockContentId]);

  const loadTextBlockContent = async (textBlockContentId: number) => {
    try {
      const response = await textBlockApi.detailTextBlockContentOfTextBlock(textBlockId as string, textBlockContentId);
      const data = response.data.data;
      setTextBlockContent(data);
      setLoaded(true);
    } catch (err) {
      if (textBlockApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadAllowedLanguages = () => {
    setLoaded(false);
    textBlockApi
      .listAllowedLanguages()
      .then((res) => {
        setAllowedLanguages(res.data.data);
        setLoadedLanguages(true);
      })
      .catch((err) => {
        if (textBlockApi.isCancel(err)) {
          return;
        }
      });
  };

  if (!loaded || !loadedLanguages) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <div>
        <h2>{page?.name}</h2>
      </div>
      <AdminBoxContent className="p-0">
        <div className="w-min-500">
          <div className="component-admin-box-content overlow-x-auto">
            <TextBlockContentForm
              textBlockId={textBlockId as any}
              textBlockContent={textBlockContent}
              allowedLanguages={allowedLanguages}
            />
          </div>
        </div>
      </AdminBoxContent>
      <Button to={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK)}>Zpět na textové bloky</Button>
    </div>
  );
};

export default TextBlockContent;
