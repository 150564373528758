import React, { createRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';

import { applicationStore } from '@stores';
import { fileToBase64 } from '@helpers/files';
import DocumentsTable from './DocumentsTable';
import { getFormatBytes } from '@helpers/formats';
import { confirmMessage } from '@helpers/messages';
import { useAuctioneersApi } from '@api/auctioneers';
import { BasePreloader, Button, Visible } from '@components';
import { Document, PermissionGroup, UserPermission } from '@types';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';

interface Props {
  id: string;
  onNotFound: () => void;
}

const Documents: React.FC<Props> = (props) => {
  const inputRef = createRef<HTMLInputElement>();
  const auctioneersApi = useAuctioneersApi();
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState([] as Array<Document>);
  const [saved, setSaved] = React.useState(true);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      await saveFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  React.useEffect(() => {
    loadData();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = (callback?: () => void) => {
    auctioneersApi
      .documentsList(props.id)
      .then((res) => {
        setData(res.data);
        setLoaded(true);
        if (!!callback) {
          callback();
        }
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        if (!!callback) {
          callback();
        }
        if (err?.response?.status === 404 || err?.response?.status === 403) {
          props.onNotFound();
          return;
        }
      });
  };

  const saveFile = async (file: File) => {
    const maxPostBodySize = applicationStore.getState().systemInfo?.post_max_size;
    if (!!file && !!maxPostBodySize && maxPostBodySize < file.size) {
      toast.error(`Soubor ${file.name} nesmí být větší než ${getFormatBytes(maxPostBodySize)}`);
      return;
    }

    setSaved(false);
    try {
      const fileBase64 = await fileToBase64(file);
      auctioneersApi
        .documentsCreate(props.id, {
          mime: file.type,
          type: 'file',
          data: fileBase64,
          original_name: file.name,
        })
        .then(() => {
          loadData(() => setSaved(true));
        })
        .catch((err) => {
          if (auctioneersApi.isCancel(err)) {
            return;
          }
          setSaved(true);
        });
    } catch (err) {
      console.error(err);
      setSaved(true);
    }
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = e.target.files !== null ? e.target.files[0] : undefined;
    if (!!file) {
      saveFile(file);
    }
  };

  const handleDeleteClick = (item: Document) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise(async (resolve) => {
          try {
            await auctioneersApi.documentsDelete(props.id, `${item.id}`);
            loadData(() => resolve(true));
          } catch {
            resolve(true);
          }
        });
      },
    });
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div {...getRootProps()}>
      <div>
        <h2>Dokumenty</h2>
        <Visible permissionGroupName={PermissionGroup.auctioneerDocuments} permissionName={UserPermission.canCreate}>
          <div className="d-flex align-items-center">
            {isDragActive ? (
              <div>
                <div className="file-item-drop mt-3">Vložit soubor</div>
              </div>
            ) : (
              <Button
                onClick={() => inputRef.current?.click()}
                variant="btn-outline-primary"
                className="f-size-12 f-weight-400 mt-3"
              >
                <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                Přidat dokument
              </Button>
            )}
            {!saved && <BasePreloader className="ml-4 mt-2" size={25} />}
            {saved && (
              <input {...getInputProps()} type="file" ref={inputRef} className="d-none" onChange={handleSelectFile} />
            )}
          </div>
        </Visible>
      </div>
      <DocumentsTable data={data} auctionId={props.id} onDeleteClick={handleDeleteClick} />
    </div>
  );
};

export default Documents;
