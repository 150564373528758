import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { BasePage, AdminBoxContent, AdminProgressFormMenu } from '@components';
import { usePageStore } from '@stores';
import Informations from './components/Informations';
import Documents from './components/Documents';
import Address from './components/Address';
import Users from './components/Users';
import AuctionTypes from './components/AuctionTypes';
import Services from './components/Services';
import BackButton from './components/BackButton';
import DataAdministration from './components/DataAdministration';
import Templates from './components/Templates';
import Licitator from './components/Licitator';

const AuctioneerCreatePage: React.FC = () => {
  const pageState = usePageStore();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [notFound, setNotFound] = React.useState(false);
  const defaultQuery = React.useMemo(
    () => ({
      step: withDefault(NumberParam, 1),
      readonly: withDefault(NumberParam, 0),
    }),
    []
  );
  const [query] = useQueryParams(defaultQuery);
  const page = pageState.getPage(!!id ? routes.admin.AUCTIONEER_UPDATE : routes.admin.AUCTIONEER_CREATE);
  const menuItems = pageState
    .getPagesByParentPageId(
      pageState.getPage(
        !!pageState.getPage(routes.admin.AUCTIONEER_UPDATE)
          ? routes.admin.AUCTIONEER_UPDATE
          : routes.admin.MY_COMPANY_UPDATE
      )?.id || 0
    )
    .map((i) => ({
      label: i.name,
      disabled: !id,
      routeName: i.routeName,
      url: !!id ? i.url.replace(':id', id) : '',
      isActive: query.step.toString() === new URLSearchParams(i.url.slice(i.url.search('\\?'))).get('step')?.toString(),
    }));

  const readOnly = !!query.readonly;

  if (notFound) {
    return (
      <BasePage
        page={
          !!pageState.getPage(routes.admin.AUCTIONEER_LIST)
            ? routes.admin.AUCTIONEER_LIST
            : routes.admin.MY_COMPANY_LIST
        }
      >
        <div className="component-auction-404">
          <div className="component-auction-title">Chyba</div>
          <div className="w-min-500 bg-white">
            <div className="component-admin-box-content">
              Pro zobrazení této stránky anebo její části nemáte oprávnění.
            </div>
          </div>
        </div>
      </BasePage>
    );
  }

  return (
    <BasePage
      page={
        !!pageState.getPage(routes.admin.AUCTIONEER_LIST) ? routes.admin.AUCTIONEER_LIST : routes.admin.MY_COMPANY_LIST
      }
      title={page?.name}
    >
      <AdminProgressFormMenu
        onClick={(url) => {
          let path = url as string;
          if (readOnly) {
            path = ~path.indexOf('?') ? `${path}&readonly=1` : `${path}?readonly=1`;
          }
          navigate(path);
        }}
        dataTestId="admin-auctioneer-form-menu"
        items={menuItems}
      />
      <AdminBoxContent className="p-0">
        <div className="w-min-500">
          <div className="component-admin-box-content overlow-x-auto">
            {query.step === 1 && <Informations id={id} readOnly={readOnly} onNotFound={() => setNotFound(true)} />}
            {!!id && (
              <>
                {query.step === 2 && <Address id={id} onNotFound={() => setNotFound(true)} />}
                {query.step === 3 && <Users id={id} onNotFound={() => setNotFound(true)} />}
                {query.step === 4 && <AuctionTypes id={id} onNotFound={() => setNotFound(true)} />}
                {query.step === 5 && <Services id={id} onNotFound={() => setNotFound(true)} />}
                {query.step === 6 && <Documents id={id} onNotFound={() => setNotFound(true)} />}
                {query.step === 7 && <DataAdministration id={id} onNotFound={() => setNotFound(true)} />}
                {query.step === 8 && <Templates onNotFound={() => setNotFound(true)} id={id} />}
                {query.step === 9 && <Licitator onNotFound={() => setNotFound(true)} id={id} />}
              </>
            )}
          </div>
        </div>
      </AdminBoxContent>
      <BackButton />
    </BasePage>
  );
};

export default AuctioneerCreatePage;
