import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import * as routes from '@routes';
import OAuthCSAS from './pages/oauth/OAuthCSAS';

const OAuthRoutes: React.FC = () => (
  <Routes>
    <Route path={routes.oauth.CSAS} element={<OAuthCSAS />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default OAuthRoutes;
